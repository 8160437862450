import {
  InsuranceCancerSpecialContract,
  InsuranceCommutingDaily,
  InsuranceCommutingOnce,
  InsuranceDeathSpecialContract,
  InsuranceHospitalizationGuarantee,
  InsuranceHospitalizationOnce,
} from "../../../../openApi/api";
import { NumberOptions } from "./DropdownItem";

import React from "react";

type TargetObjForDisplayAmount =
  | InsuranceHospitalizationGuarantee
  | InsuranceHospitalizationOnce
  | InsuranceCommutingDaily
  | InsuranceCommutingOnce
  | InsuranceCancerSpecialContract
  | InsuranceDeathSpecialContract;

export const formatForDisplaySubText = (
  obj: any | undefined
) => {
  if (obj === undefined) {
    return "なし";
  } else if (!obj.guarantee_amount_text) {
    return "あり";
  } else {
    return formatTextWithLineBreaks(obj.guarantee_amount_text);
  }
};

export const formatForDisplayAmountText = (
  obj: TargetObjForDisplayAmount | undefined,
  objForDisplayselectedValue: NumberOptions,
  selectedValue: number
) => {
  if (obj === undefined) {
    return "なし";
  } else if (!obj.guarantee_amount_text) {
    return objForDisplayselectedValue[selectedValue];
  } else {
    return formatTextWithLineBreaks(obj.guarantee_amount_text);
  }
};

export const formatTextWithLineBreaks = (
  str: string | undefined
): React.ReactNode[] => {
  if (!str) {
    return [];
  }
  const lines = str.split(/\n|\\n/);
  return lines.map((line, index) => (
    <span key={index}>
      {line}
      {index < lines.length - 1 && <br />}
    </span>
  ));
};

type TargetObjForDisplayDays =
  | InsuranceHospitalizationGuarantee
  | InsuranceCommutingDaily;

export const formatForDisplayDaysText = (
  obj: TargetObjForDisplayDays | undefined,
  objForDisplayselectedValue: NumberOptions,
  selectedValue: number
): string => {
  let patternNumber = /^[0-9]+$/;
  if (obj === undefined) {
    return "なし";
  } else if (obj.days === undefined) {
    return objForDisplayselectedValue[selectedValue];
  } else if (typeof obj.days === "string") {
    if (obj.days === "unlimited") {
      return "無制限";
    } else if (patternNumber.test(obj.days)) {
      return `${obj.days}日`;
    } else {
      return obj.days;
    }
  } else if (obj.days === null) {
    return "なし";
  } else {
    return `${obj.days}日`;
  }
};

export const setHeightToTargetElement = (selectorName: string): void => {
  const headElement = document.getElementById(selectorName);
  const targetTableRows = document.getElementsByClassName(selectorName);
  if (headElement && targetTableRows) {
  	  headElement.style.height = "auto";
    Array.from(targetTableRows).map((targetElement) => {
      (targetElement as HTMLElement).style.height = "auto";
      return null;
    });
  	  
    const heights = Array.from(targetTableRows).map(
      (targetElement) => (targetElement as HTMLElement).offsetHeight
    );
    const maxHeight = Math.max(...heights);
    headElement.style.height = maxHeight + "px";
    Array.from(targetTableRows).map((targetElement) => {
      (targetElement as HTMLElement).style.height = maxHeight + "px";
      return null;
    });
  }
};

export const MedicalTableRows = [
  "hospitalizationGuarantee",
  "hospitalizationGuaranteeDays",
  "hospitalizationOnce",
  "surgeryGuarantee",
  "commutingDailyAmount",
  "commutingDailyAmountDays",
  "commutingOnce",
  "advancedMedical",
  "cancerSpecialContractAmount",
  "specifiedDisease",
  "deathSpecialContractAmount",
  "congratulatoryMoney",
  "paymentExemption",
  "attention",
  "condition",
];
export const CancerTableRows = [
  "malignantDiagnosticOnce",
  "intraepithelialDiagnosticOnce",
  "malignantDiagnosticTreatment",
  "intraepithelialDiagnosticTreatment",
  "hospitalizationDailyAmount",
  "hospitalizationDailyAmountDays",
  "surgery",
  "leavingHospitalOnce",
  "cancerHospitalDailyFee",
  "advancedMedical",
  "radiotherapy",
  "anticancerDrugTreatment",
  "femaleDiseases",
  "paymentExemption",
  "attention",
  "condition",
];
export const WholeDeathTableRows = [
  "deathBenefit",
  "cancelRepayment",
  "paymentExemption",
  "livingNeeds",
  "attention",
  "condition",
];
export const TermDeathTableRows = [
  "insurancePeriod",
  "paymentPeriod",
  ...WholeDeathTableRows,
];
export const IncomeProtectionTableRows = [
  "monthlyBenefit",
  "paymentMinGuaranteeTerm",
  "insurancePeriod",
  "cancelRepayment",
  "paymentExemption",
  "livingNeeds",
  "attention",
  "condition",
];

import { ChangeEvent, useState } from "react";
import { ArrowButton, ScrollBackBtn } from "../../../components/atoms/Button";
interface Props {
  current: number;
  whoAssured: number;
  age: string;
  setAge: (value: string) => void;
  onNext: () => void;
  onPrev: (value: number) => void;
}

const Question3 = ({
  current,
  whoAssured,
  age,
  setAge,
  onNext,
  onPrev,
}: Props) => {
  const [errors, setErrors] = useState<string[]>([]);

  const validate = (value: string) => {
    const err: string[] = [];
    if (value === "") {
      err.push("年齢を入力してください");
    } else if (parseInt(value) > 85) {
      err.push("85歳以下で入力してください");
    }
    setErrors(err);
    return err;
  };

  const onValidateAndNext = () => {
    const err = validate(age);
    if (err?.length === 0) {
      onNext();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.value === "" || /^-?\d+$/.test(e.target.value)) {
        validate(e.target.value);
        setAge(e.target.value);
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 4 ? "currentCard" : ""
      }`}
      id="question-3"
    >
      <ScrollBackBtn onClick={() => onPrev(3)} />
      <div className="cardWrapper questionTxt">
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            3&nbsp;/&nbsp;10
          </span>
          <h2 className="typographyPrimaryColor typographyTextSubTitle">
            {whoAssured === 1
              ? "あなたは"
              : whoAssured === 2
              ? "旦那さまは"
              : whoAssured === 3
              ? "奥さまは"
              : "診断したい方は"}
            おいくつですか
          </h2>
          <span>診断したい方の年齢を教えてください</span>
        </div>
        <fieldset className={errors.length > 0 ? "error" : ""}>
          <div data-prefix="" data-suffix="歳">
            <input
              type="text"
              inputMode="numeric"
              aria-invalid="true"
              name="age"
              value={age}
              onChange={onChange}
            />
          </div>
          <div className="validation">
            {errors.map((error) => {
              return error;
            })}
          </div>
        </fieldset>
      </div>
      {current === 4 && (
        <div
          style={{
            marginTop: -14,
            marginRight: 28,
            textAlign: "right",
          }}
        >
          <ArrowButton onClick={onValidateAndNext} />
        </div>
      )}
    </section>
  );
};

export default Question3;

import { useQuery } from "@tanstack/react-query";
import { Api } from "../openApi";
import { simpleDiagnoseStorage } from "../utils/storage";
import { useMemo } from "react";

export type CancerInsuranceRankProps = {
  order: number;
  age: number;
  sex: number;
  paymentExemption: boolean;
  underwritingEasing: boolean;
  maxBudget?: number;
  intentionRecommendInsurance: boolean;
  malignantDiagnosticOnce?: number;
  intraepithelialDiagnosticOnce?: number;
  malignantDiagnosticTreatment?: number;
  intraepithelialDiagnosticTreatment?: number;
  hospitalizationDailyAmount?: number;
  hospitalizationDailyAmountDays?: number;
  surgery?: number;
  leavingHospitalOnce?: number;
  cancerHospitalDailyFee?: number;
  cancerHospitalDailyFeeDays?: number;
  commutingOnce?: number;
  advancedMedical?: boolean;
  radiotherapy?: number;
  anticancerDrugTreatment?: boolean;
  femaleDiseases?: boolean;
  onSuccess?: () => void;
};

export const useCancerInsuranceRank = (params: CancerInsuranceRankProps) => {

  const { result: simpleDiagnose } = useMemo(
    () => simpleDiagnoseStorage.get(),
    []
  );

  return useQuery(
    ["getCancerInsuranceRank", params],
    async () => {
      const { data } = await Api.getApiCancerInsurancesRank(
        params.order,
        params.age,
        params.sex,
        params.paymentExemption,
        params.underwritingEasing,
        params.intentionRecommendInsurance,
        params.maxBudget,
        params.malignantDiagnosticOnce,
        params.intraepithelialDiagnosticOnce,
        params.malignantDiagnosticTreatment,
        params.intraepithelialDiagnosticTreatment,
        params.hospitalizationDailyAmount,
        params.hospitalizationDailyAmountDays,
        params.surgery,
        params.leavingHospitalOnce,
        params.cancerHospitalDailyFee,
        params.cancerHospitalDailyFeeDays,
        params.commutingOnce,
        params.advancedMedical,
        params.radiotherapy,
        params.anticancerDrugTreatment,
        params.femaleDiseases,
        simpleDiagnose.diagnos_code
      );
      params.onSuccess && params.onSuccess();
      return data;
    },
    { enabled: false }
  );
};

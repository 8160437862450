// import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import AfterSelect from "../../components/molecules/AfterSelect";
import AfterSelectcheck from "../../components/molecules/AfterSelectcheck";
import "../../styles/pages/contactComplete.scss";
import { useContactFPAfter } from "../../query/useContactFPAfter";
import { Button } from "react-bootstrap";
import {
  // detailDiagnoseStorage,
  simpleDiagnoseStorage,
  // cartStorage,
  // partnerDataStorage,
} from "../../utils/storage";
import { useEffect, useMemo ,useState} from "react";

import SalesPolicy from "../../components/molecules/SalesPolicy";

const ContactafterComplete = () => {
  useDocumentTitle("保デジWEB-問い合わせ完了");
  const queryParameters = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  // const [searchParams] = useSearchParams();

  // const type = useMemo(() => searchParams.get("type"), [searchParams]);
  useEffect(() => {
    // simpleDiagnoseStorage.remove();
    // detailDiagnoseStorage.remove();
    // cartStorage.get().insuranceIdList.map( id => cartStorage.remove(id));
    // partnerDataStorage.remove();
    window.history.pushState(null, '', null);
    window.addEventListener('popstate', () => window.history.pushState(null, '', null), false);
  }, []);

  const simpleDiagnose = useMemo(() => simpleDiagnoseStorage.get(), []);
  

  const [canchecksdata, setchecksdata] = useState<string[]>();
  const [canradiodatas, setradiodatas] = useState<string[]>();
  const { mutate: postContactFPAfter } = useContactFPAfter();

  const clearUndefine = () => {
    if (canchecksdata === undefined) setchecksdata([]);
    if (canradiodatas === undefined) setradiodatas([]);
  };
  
  const [is_close, setIsClose] = useState(false);
  
  const onClosePopup = () => {
  	  setIsClose(true);
  }

  const  diagnosCode= useMemo(
    () =>
      simpleDiagnose?.result?.diagnos_code ??
      queryParameters.get("diagnosCode") ??
      undefined,
    [simpleDiagnose, queryParameters]
  );

  const onSubmit = () => {
  
    if (
      canchecksdata === undefined ||
      canchecksdata.length === 0 ||
      canradiodatas === undefined ||
      canradiodatas.length === 0
    ) {
      clearUndefine();
      return;
    }
    postContactFPAfter({
      diagnos_code: diagnosCode,
      canchecksdata: canchecksdata,
      canradiodatas: canradiodatas,
    });
  };
  
  console.log('diagnosCode:', diagnosCode);

  return (
    <>
      <section className="contact-complete-section">
        <div className="contact-complete-form ">
          <span>診断結果の送信完了</span>
          <label>
            {/* {type === "email" ? (
              "ご入力いただいたメールアドレスへ診断結果のURLを送信しました。"
            ) : type === "sms" ? (
              "ご入力いただいた電話番号へ診断結果のURLを送信しました。"
            ) : ( */}
              <>
                かんたん診断のご利用いただきありがとうございます
                <br></br>
                診断結果の内容をお送りいたしましたのでご確認ください。
              </>
            {/* )} */}
          </label>
          <AfterSelect
            
            label="現在のご意向に近いものを教えてください"
            options={[
              "保険料の節約をしたい",
              "現在の加入している保険の診断をしたい",
              "今の保険に追加したい（保障を手厚くしたい）",
            ]}
            value={canchecksdata}
            onChange={(data) => setchecksdata(Array.isArray(data) ? data : [data])}
            selectKey="checksdata"
          />
          <AfterSelectcheck
            label="気になる項目を教えてください※複数選択可"
            options={[
              "入院や手術の際の備え",
              "死亡時の備え",
              "がんに対する備え",
              "介護や働けなくなった際の備え",
              "老後の年金生活の備え",
              "こどもの教育資金への備え",
              "その他",
            ]}
            value={canradiodatas}
            onChange={(data) => setradiodatas(data)}
            selectKey="radiodatas"
          />
          <Button
            color="primary"
            className="downloadDocumentSubmit"
            onClick={onSubmit}
          >
            登録
          </Button>

          <Button
            color="primary"
            className="backtopbtn"
            onClick={() => {
              window.location.href = "/result-simple";
              }}
          >
            結果ページへ戻る
          </Button>
        </div>
      </section>
      <div className={is_close ? "popUpClose" : "popUpOpen"}>
        <div className="wrap">
          <div className="mainCont">
            <SalesPolicy hasLinkButton={false} />
          </div>
          <Button
            className="closePopup"
            onClick={onClosePopup}
          >
            閉じる
          </Button>
        </div>
      </div>
    </>
  );
};

export default ContactafterComplete;

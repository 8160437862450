/** @jsxImportSource @emotion/react */
import { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SaveDiagnoseResultPopup } from "../../../components/molecules/saveDiagnoseResultPopup";
import { Insurance } from "../../../openApi/api";
import "../../../styles/components/estimateResult.scss";
// import { cartStorage } from "../../../utils/storage";
import {
  cancerPDStorage,
  cartStorage
} from "../../../utils/storage";
import { InsuranceRankResponseInner } from "../comparison";
import { jpNumFormatter } from "../../../utils/formatter";

export interface CartStorage {
  insuranceIdList: string[];
  insuranceList: InsuranceRankResponseInner[];
}

export type CancerInsuranceDetailProps = {
  age: number;
  sex: number;
};

interface Props {
  setIsCartOpen: (isCartOpen: boolean) => void;
  insuranceListInCart: InsuranceRankResponseInner[];
  setInsuranceListInCart: (prevCart: InsuranceRankResponseInner[]) => void;
  insuranceIdListInCart: string[];
  setInsuranceIdListInCart: (insuranceIdListInCart: string[]) => void;
}

export const EstimateResult = ({
  setIsCartOpen,
  insuranceListInCart,
  setInsuranceListInCart,
  // insuranceIdListInCart,
  setInsuranceIdListInCart,
}: Props) => {
  const navigate = useNavigate();
  const [isSavePopupOpen, setIsSavePopupOpen] = useState<boolean>(false);
  const [totalPrice, setTotalPlice] = useState<number>(0);
  useEffect(()=>{
    setTotalPlice(0);
    insuranceListInCart.map((insurance: InsuranceRankResponseInner) =>
    setTotalPlice((p) => {
       return insurance.total_insurance_fee ? p + insurance.total_insurance_fee : p + 0
      })
  );
  },[insuranceListInCart]);

  const handleClickDeleteButton = (insurance: Insurance, index: number) => {
    cartStorage.remove(insurance.insurance_id ?? "");
    const updatedListInCart = cartStorage.get().insuranceList;
    setInsuranceListInCart(updatedListInCart);
    const updatedIdListInCart = cartStorage.get().insuranceIdList;
    setInsuranceIdListInCart(updatedIdListInCart);
  };
  
    const queryParameters = new URLSearchParams(window.location.search);
    const fromComparison = queryParameters.get("fromComparison");
    const queryAge = queryParameters.get("age");
    const querySex = queryParameters.get("sex");
    const cancerDetailSerachCondition = useMemo(() => cancerPDStorage.get(), []);
  
    const userAge = useMemo(() => {
      if (fromComparison) {
        return cancerDetailSerachCondition?.age;
      } else {
        return queryAge ? parseInt(queryAge) : 0;
      }
    }, [cancerDetailSerachCondition, queryAge, fromComparison]);
  
    const userSex = useMemo(() => {
      if (fromComparison) {
        return cancerDetailSerachCondition?.sex;
      } else {
        return querySex ? parseInt(querySex) : 0;
      }
    }, [cancerDetailSerachCondition, querySex, fromComparison])
  
  return (
    <div className="resultBox myPlanResultPopup">
      {isSavePopupOpen ? (
        <>
          <div
            className="filter"
            onClick={() => setIsSavePopupOpen(false)}
          ></div>
          <SaveDiagnoseResultPopup />
        </>
      ) : (
        <></>
      )}
      <div
        className="backButton"
        onClick={() => {
          setIsCartOpen(false);
        }}
      >
        ×
      </div>
      <div className="title">
        <h4>Myプラン</h4>
        <p>見直した場合の、月々の保険料見込み</p>
        <div className="mysubtitle">
        ＜算出条件＞
        </div>
        <div className="mysubtitle">
        契約年齢：{userAge}歳&nbsp;&nbsp;
                        {userSex === 2 ? "女" : "男"}
                        性&nbsp;&nbsp;
        払込回数：月払　払込経路：口座振替扱
        </div>
      </div>

      <ul className="cartList">
        {
          // TODO: 以下のmapの型定義はopenApiの不具合修正後に削除が必要
          insuranceListInCart.map((insurance: InsuranceRankResponseInner, index: number) => (
            <li key={index} className="item">
              <div className="thumbnailCon">
                <img
                  src={insurance.thumbnail_url}
                  alt={insurance.insurance_name}
                  width={80}
                  height={"auto"}
                />
                <span>{insurance.company_name}</span>
              </div>
              <div className="itemInfo">
                <span>
                  { insurance.insurance_id?.includes('medical') ? '医療保険'
                  : insurance.insurance_id?.includes('cancer') ? 'がん保険'
                  : insurance.insurance_id?.includes('lifetime') ? '死亡保険（終身）'
                  : insurance.insurance_id?.includes('regular') ? '死亡保険（定期）'
                  : insurance.insurance_id?.includes('income') ? '収入保障保険'
                  : 'その他保険'
                  }
                </span>
                <div className="subitemInfo">
                  {insurance.fee_memos?.map((memo:string, index:number) => (
                    <span>{memo}</span>
                  ))}
                </div>
              </div>
              <div className="priceAndButton">
                <div className="price">
                  {insurance.total_insurance_fee &&
                    jpNumFormatter(insurance.total_insurance_fee)}
                  円
                </div>
                <div className="buttonWrap">
                  {(insurance.is_online_entry && insurance.entry_url) ?
                  <Button
                    className="netBtn buttonRadius"
                    href={insurance.entry_url}
                    target="_blank"
                    variant="orange"
                  >
                    {insurance.is_online_entry
                      ? "ネット申し込み"
                      : "資料請求リンク"}
                  </Button>
                  : ''}
                  <Button
                    className="deleteButton"
                    onClick={() => {
                      handleClickDeleteButton(insurance, index);
                    }}
                    variant="white"
                  >
                    見積もりから削除
                  </Button>
                </div>
              </div>
            </li>
          ))
        }
      </ul>
      <div className="total">
        <div className="totalPrice">
          <div>月々合計</div>
          <div>{jpNumFormatter(totalPrice, true)}円</div>
        </div>
      </div>
      <div className="buttonCon">
        <Button
          className="saveButton"
          variant="orange"
          onClick={() => {
            setIsSavePopupOpen(!isSavePopupOpen);
          }}
        >
          診断結果を保存する
        </Button>
        <Button
          variant="outline-dark"
          className="contactButton"
          onClick={() => navigate("/download-fp?fromComparison=true")}
        >
          無料相談・問い合わせ
        </Button>
      </div>
      <div className="note">
        <p>
          ここで表示されているMyプランの内容は、ご入力いただいた各情報を元に算出された必要保障額に対し、弊社が定めた基準でおすすめしている商品内容となっております。 なお、必ずしも表示されている保険商品で必要保障額の全てが満たされるとは限りません。また、ここで表示されている情報は、保障内容の全てが記載されているわけではなくあくまで情報の一部となる為、これ以外の保険商品と単純に比較したり優劣をつけたりすることはできません。 実際にご検討いただく際には各保険会社のパンフレット及び、ご契約のしおり、重要事項書類をご確認ください。 加えて、その他の条件等によってご提案できる商品や保険料が異なる場合がございますので、詳しいことにつきましてはお問い合わせください。 また、各保険会社基準の審査の結果お引き受け出来ない可能性もございますので何卒ご了承ください。
        </p>
        <p>
          本資料でご案内している保険商品に関する内容は、2024年3月1日現在で適用されているものです。
        </p>
      </div>
    </div>
  );
};
export default EstimateResult;

/**  @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  ApiCancerInsurancesRankResponse,
  ApiCancerInsurancesRankResponseInsurancesInner,
} from "../../../../openApi/api";
import { ContentsColumnCancer } from "./ContentsColumnCancer";
import { HeadColumnCancer } from "./HeadColumnCancer";
import { HeadColumnCommon } from "./HeadColumnCommon";

interface Props {
  typeOfInsurance: number;
  response: ApiCancerInsurancesRankResponse | undefined;
  age: number;
  sex: number;
  paymentExemption: boolean;
  setPaymentExemption: (paymentExemption: boolean) => void;
  setIsPopupOpen: (isPopupOpen: boolean) => void;
  setFocusedIndex: (focusedIndex: number) => void;
  insuranceListInCart: ApiCancerInsurancesRankResponseInsurancesInner[];
  setInsuranceListInCart: (
    prevCart: ApiCancerInsurancesRankResponseInsurancesInner[]
  ) => void;
  insuranceIdListInCart: string[];
  setInsuranceIdListInCart: (insuranceIdListInCart: string[]) => void;

  //がん保険特有のprops
  malignantDiagnosticOnce: number;
  setMalignantDiagnosticOnce: (malignantDiagnosticOnce: number) => void;
  intraepithelialDiagnosticOnce: number;
  setIntraepithelialDiagnosticOnce: (
    intraepithelialDiagnosticOnce: number
  ) => void;
  malignantDiagnosticTreatment: number;
  setMalignantDiagnosticTreatment: (
    malignantDiagnosticTreatment: number
  ) => void;
  intraepithelialDiagnosticTreatment: number;
  setIntraepithelialDiagnosticTreatment: (
    intraepithelialDiagnosticTreatment: number
  ) => void;
  hospitalizationDailyAmount: number;
  setHospitalizationDailyAmount: (hospitalizationDailyAmount: number) => void;
  hospitalizationDailyAmountDays: number;
  setHospitalizationDailyAmountDays: (
    hospitalizationDailyAmountDays: number
  ) => void;
  surgery: number;
  setSurgery: (surgery: number) => void;
  leavingHospitalOnce: number;
  setLeavingHospitalOnce: (leavingHospitalOnce: number) => void;
  cancerHospitalDailyFee: number;
  setCancerHospitalDailyFee: (cancerHospitalDailyFee: number) => void;
  cancerHospitalDailyFeeDays: number;
  setCancerHospitalDailyFeeDays: (cancerHospitalDailyFeeDays: number) => void;
  commutingOnce: number;
  setCommutingOnce: (commutingOnce: number) => void;
  advancedMedical: boolean;
  setAdvancedMedical: (advancedMedical: boolean) => void;
  radiotherapy: number;
  setRadiotherapy: (radiotherapy: number) => void;
  anticancerDrugTreatment: boolean;
  setAnticancerDrugTreatment: (anticancerDrugTreatment: boolean) => void;
  femaleDiseases: boolean;
  setFemaleDiseases: (femaleDiseases: boolean) => void;
  triggerCancerInsuranceRankAPI: boolean;
  setTriggerCancerInsuranceRankAPI: (_: boolean) => void;
  
  checkedRankList: string[];
  setCheckedRankList: (checkedRankList: string[]) => void;
  
  isContentsColumnRendered: boolean;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
}

export const TableCancer = ({
  typeOfInsurance,
  response,
  age,
  sex,
  paymentExemption,
  setPaymentExemption,
  setIsPopupOpen,
  setFocusedIndex,
  insuranceListInCart,
  setInsuranceListInCart,
  insuranceIdListInCart,
  setInsuranceIdListInCart,
  malignantDiagnosticOnce,
  setMalignantDiagnosticOnce,
  intraepithelialDiagnosticOnce,
  setIntraepithelialDiagnosticOnce,
  malignantDiagnosticTreatment,
  setMalignantDiagnosticTreatment,
  intraepithelialDiagnosticTreatment,
  setIntraepithelialDiagnosticTreatment,
  hospitalizationDailyAmount,
  setHospitalizationDailyAmount,
  hospitalizationDailyAmountDays,
  setHospitalizationDailyAmountDays,
  surgery,
  setSurgery,
  leavingHospitalOnce,
  setLeavingHospitalOnce,
  cancerHospitalDailyFee,
  setCancerHospitalDailyFee,
  cancerHospitalDailyFeeDays,
  setCancerHospitalDailyFeeDays,
  commutingOnce,
  setCommutingOnce,
  advancedMedical,
  setAdvancedMedical,
  radiotherapy,
  setRadiotherapy,
  anticancerDrugTreatment,
  setAnticancerDrugTreatment,
  femaleDiseases,
  setFemaleDiseases,
  triggerCancerInsuranceRankAPI,
  setTriggerCancerInsuranceRankAPI,
  
  checkedRankList,
  setCheckedRankList,
  
  isContentsColumnRendered,
  setIsContentsColumnRendered,
}: Props) => {
  
  const [isDisplayOnlyChecked, setIsDisplayOnlyChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsContentsColumnRendered(true);
  }, [
    isDisplayOnlyChecked,
    setIsDisplayOnlyChecked,
    setIsContentsColumnRendered
  ]);
  
  return (
    <section className="comparisonTableSection">
      <Container>
        <Row>
          <Col className="speechBoxContainer" md={1}>
            <div className="speechBox">
              保障内容でも
              <br />
              絞り込み可能
            </div>
          </Col>
          <Col md={1} className="insuranceTableTitle">
            {response?.table_header_text}
          </Col>
        </Row>
      </Container>
      <div className="insuranceTable">
        <div className="headRow">
          <HeadColumnCommon
            isDisplayOnlyChecked={isDisplayOnlyChecked}
            setIsDisplayOnlyChecked={setIsDisplayOnlyChecked}
            isContentsColumnRendered={isContentsColumnRendered}
            setIsContentsColumnRendered={setIsContentsColumnRendered}
          />
          <div className="headRowSpecific">
            <HeadColumnCancer
              paymentExemption={paymentExemption}
              setPaymentExemption={setPaymentExemption}
              malignantDiagnosticOnce={malignantDiagnosticOnce}
              setMalignantDiagnosticOnce={setMalignantDiagnosticOnce}
              intraepithelialDiagnosticOnce={intraepithelialDiagnosticOnce}
              setIntraepithelialDiagnosticOnce={
                setIntraepithelialDiagnosticOnce
              }
              malignantDiagnosticTreatment={malignantDiagnosticTreatment}
              setMalignantDiagnosticTreatment={setMalignantDiagnosticTreatment}
              intraepithelialDiagnosticTreatment={
                intraepithelialDiagnosticTreatment
              }
              setIntraepithelialDiagnosticTreatment={
                setIntraepithelialDiagnosticTreatment
              }
              hospitalizationDailyAmount={hospitalizationDailyAmount}
              setHospitalizationDailyAmount={setHospitalizationDailyAmount}
              hospitalizationDailyAmountDays={hospitalizationDailyAmountDays}
              setHospitalizationDailyAmountDays={
                setHospitalizationDailyAmountDays
              }
              surgery={surgery}
              setSurgery={setSurgery}
              leavingHospitalOnce={leavingHospitalOnce}
              setLeavingHospitalOnce={setLeavingHospitalOnce}
              cancerHospitalDailyFee={cancerHospitalDailyFee}
              setCancerHospitalDailyFee={setCancerHospitalDailyFee}
              cancerHospitalDailyFeeDays={cancerHospitalDailyFeeDays}
              setCancerHospitalDailyFeeDays={setCancerHospitalDailyFeeDays}
              commutingOnce={commutingOnce}
              setCommutingOnce={setCommutingOnce}
              advancedMedical={advancedMedical}
              setAdvancedMedical={setAdvancedMedical}
              radiotherapy={radiotherapy}
              setRadiotherapy={setRadiotherapy}
              anticancerDrugTreatment={anticancerDrugTreatment}
              setAnticancerDrugTreatment={setAnticancerDrugTreatment}
              femaleDiseases={femaleDiseases}
              setFemaleDiseases={setFemaleDiseases}
              triggerCancerInsuranceRankAPI={triggerCancerInsuranceRankAPI}
              setTriggerCancerInsuranceRankAPI={
                setTriggerCancerInsuranceRankAPI
              }
              isContentsColumnRendered={isContentsColumnRendered}
              setIsContentsColumnRendered={setIsContentsColumnRendered}
            />
          </div>
        </div>
        <div className="contentRow">
          {
            // TODO: 以下のmapの型定義はopenApiの不具合修正後に削除が必要
            response?.insurances &&
              response?.insurances.map(
                (
                  insurance: ApiCancerInsurancesRankResponseInsurancesInner,
                  index: number
                ) =>
                  !isDisplayOnlyChecked ||
                  (isDisplayOnlyChecked &&
                    checkedRankList.includes(insurance.insurance_id ?? '')) ? (
                    <ContentsColumnCancer
                      key={`${typeOfInsurance}_${index + 1}`}
                      typeOfInsurance={typeOfInsurance}
                      insurance={insurance}
                      age={age}
                      sex={sex}
                      malignantDiagnosticOnce={malignantDiagnosticOnce}
                      lowPriceRank={index + 1}
                      setIsPopupOpen={setIsPopupOpen}
                      setFocusedIndex={setFocusedIndex}
                      insuranceListInCart={insuranceListInCart}
                      setInsuranceListInCart={setInsuranceListInCart}
                      insuranceIdListInCart={insuranceIdListInCart}
                      setInsuranceIdListInCart={setInsuranceIdListInCart}
                      intraepithelialDiagnosticOnce={
                        intraepithelialDiagnosticOnce
                      }
                      malignantDiagnosticTreatment={
                        malignantDiagnosticTreatment
                      }
                      intraepithelialDiagnosticTreatment={
                        intraepithelialDiagnosticTreatment
                      }
                      hospitalizationDailyAmount={hospitalizationDailyAmount}
                      hospitalizationDailyAmountDays={
                        hospitalizationDailyAmountDays
                      }
                      surgery={surgery}
                      leavingHospitalOnce={leavingHospitalOnce}
                      cancerHospitalDailyFee={cancerHospitalDailyFee}
                      cancerHospitalDailyFeeDays={cancerHospitalDailyFeeDays}
                      commutingOnce={commutingOnce}
                      advancedMedical={advancedMedical}
                      radiotherapy={radiotherapy}
                      anticancerDrugTreatment={anticancerDrugTreatment}
                      femaleDiseases={femaleDiseases}
                      paymentExemption={paymentExemption}
                      setIsContentsColumnRendered={setIsContentsColumnRendered}
                      numberOfInsurances={response?.insurances?.length}
                      checkedRankList={checkedRankList}
                      setCheckedRankList={setCheckedRankList}
                    />
                  ) : (
                    <div key={index + 1}></div>
                  )
              )
          }
        </div>
      </div>
    </section>
  );
};

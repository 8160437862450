/** @jsxImportSource @emotion/react */
//import { Link } from 'react-router-dom';
import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";

//components
import PageHeader from "../../components/atoms/PageHeader";
import "../../styles/pages/download.scss";
import FpCardList from "./components/FpCardList";
import LabelInput from "../../components/molecules/LabelInput";
import MultiSelect from "../../components/molecules/MultiSelect";
import MultiSelectTime from "../../components/molecules/MultiSelecttime";
import PolicyModal from "../../components/molecules/PolicyModal";
import { useContactFP } from "../../query/useContactFP";
import {
  cartStorage,
  detailDiagnoseStorage,
  simpleDiagnoseStorage,
} from "../../utils/storage";
import { EstimateInsurance1 } from "../../openApi/api";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { hankaku2Zenkaku } from "../../utils/formatter";

const Download_document = () => {
  useDocumentTitle("保デジWEB-問い合わせ");
  const queryParameters = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const fromComparison = queryParameters.get("fromComparison");

  const simpleDiagnose = useMemo(() => simpleDiagnoseStorage.get(), []);
  const detailDiagnose = useMemo(() => detailDiagnoseStorage.get(), []);

  const diagnosCode = useMemo(
    () =>
      simpleDiagnose?.result?.diagnos_code ??
      queryParameters.get("diagnosCode") ??
      undefined,
    [simpleDiagnose, queryParameters]
  );

  const cartList = useMemo(() => cartStorage.get()?.insuranceList ?? [], []);

  const estimateInsurances = useMemo(() => {
    if (fromComparison === "true") {
      return cartList.map((cart) => ({
        insurance_code: cart.insurance_id,
        insurance_fee_codes: cart.insurance_fee_codes,
      })) as EstimateInsurance1[];
    } else if (simpleDiagnose.result.diagnos_code === diagnosCode) {
      return simpleDiagnose.result.recommended_insurance_items?.map((value) => {
        return {
          insurance_code: value.insurance_code,
          insurance_fee_codes: value.insurance_fee_codes,
        };
      }) as EstimateInsurance1[];
    } else {
      return detailDiagnose.result.recommended_insurance_items?.map((value) => {
        return {
          insurance_code: value.insurance_code,
          insurance_fee_codes: value.insurance_fee_codes,
        };
      }) as EstimateInsurance1[];
    }
  }, [simpleDiagnose, detailDiagnose, diagnosCode, fromComparison, cartList]);

  const [modal, setModal] = useState(false);

  const [name, setName] = useState<string>();
  const [nameKana, setNameKana] = useState<string>();
  const [tel, setTel] = useState<string>();
  const [candidateDayOfWeek, setCandidateDayOfWeek] = useState<string[]>();
  const [candidateTimeZone, setCandidateTimeZone] = useState<string[]>();
  const [email, setEmail] = useState<string>("");
  const { mutate: postContactFP } = useContactFP();
  const [telErrors, setTelErrors] = useState<string[]>([]);
  const mobileNumberPattern = /^(090|080|070|050)\d{8}$/;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setTel(inputValue);  

    if (mobileNumberPattern.test(inputValue)) {
      // 携帯電話番号の場合、エラーをクリア
      setTelErrors([]);
    } else {
      // 携帯電話番号でない場合、エラーを設定
      setTelErrors(["携帯電話番号を入力してください。"]);
    }
  };

  const clearUndefine = () => {
    if (name === undefined) setName("");
    if (nameKana === undefined) setNameKana("");
    if (tel === undefined) setTel("");
    if (candidateDayOfWeek === undefined) setCandidateDayOfWeek([]);
    if (candidateTimeZone === undefined) setCandidateTimeZone([]);
    if (email === undefined) setEmail("");
  };
  const onSubmit = () => {
    const submittedEmail = email || "dummy12345@example.com";
    if (
      name === undefined ||
      name === "" ||
      nameKana === undefined ||
      nameKana === "" ||
      tel === undefined ||
      tel === "" ||
      candidateDayOfWeek === undefined ||
      candidateDayOfWeek.length === 0 ||
      candidateTimeZone === undefined ||
      candidateTimeZone.length === 0 ||
      !mobileNumberPattern.test(tel)
    ) {
      clearUndefine();
      return false;
    }
    
    postContactFP({
      diagnos_code: diagnosCode,
      name,
      name_kana: nameKana,
      tel: hankaku2Zenkaku(tel),
      candidate_day_of_week: candidateDayOfWeek,
      candidate_time_zone: candidateTimeZone,
      email: submittedEmail,
      from_comparison: fromComparison === "true",
      estimate_insurances: estimateInsurances,
    });
  };

  return (
    <>
      <section className="download-fp-section">
        <PageHeader>無料相談・問い合わせ</PageHeader>
        <FpCardList />
        <div className="downloadDocumentForm">
          <LabelInput
            label="名前"
            placeholder="苗字　名前"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <LabelInput
            label="フリガナ"
            placeholder="フリ　ガナ"
            value={nameKana}
            onChange={(e) => setNameKana(e.target.value)}
            required
          />
          <LabelInput
            label="電話番号（ハイフンなし）"
            placeholder=""
            value={tel}
            onChange={handleChange}
            onBlur={(e) => setTel(hankaku2Zenkaku(e.target.value))}
            errors={telErrors}
            required
          />
          <MultiSelect
            label="電話がつながりやすい曜日"
            options={[
              "月",
              "火",
              "水",
              "木",
              "金",
              "土",
              "日",
            ]}
            value={candidateDayOfWeek}
            onChange={(data) => setCandidateDayOfWeek(data)}
            selectKey="dayOfWeek"
            required
          />
          
          <MultiSelectTime
            label="電話がつながりやすい時間帯"
            options={[
              "午前中（10〜12時）",
              "12〜14時",
              "14〜16時",
              "16〜18時",
              "18〜20時半",
            ]}
            value={candidateTimeZone}
            onChange={(data) => setCandidateTimeZone(data)}
            selectKey="timeZone"
            required
          />
          <LabelInput
            label="メールアドレス"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required={false}
          />

          <span className="typographyAnswerBtnDescription typographyAlignCenter">
            <span
              style={{
                color: "#0d6efd",
                cursor: "pointer",
              }}
              onClick={() => setModal(true)}
            >
              利用規約
            </span>
            へ同意したものとみなされます
          </span>
          <Button
            color="primary"
            className="downloadDocumentSubmit"
            onClick={onSubmit}
          >
            送信する
          </Button>
        </div>
      </section>
      <PolicyModal visible={modal} onClose={() => setModal(false)} />
    </>
  );
};
export default Download_document;

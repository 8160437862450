import { ChangeEvent, useState } from "react";
import { ArrowButton, ScrollBackBtn } from "../../../components/atoms/Button";
import { jpNumFormatter, numDecryptor } from "../../../utils/formatter";
interface Props {
  current: number;
  monthlyExpense?: string;
  setMonthlyExpense: (value: string | undefined) => void;
  onNext: () => void;
  onPrev: (value: number) => void;
}

const Question8 = ({
  current,
  monthlyExpense,
  setMonthlyExpense,
  onNext,
  onPrev,
}: Props) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState(false);

  const validate = (value: string) => {

    if (parseInt(value) > 100000) {
      setErrors(["100,000万円以下を入力してください"]);
      return;
    }
    setErrors([]);
  };

  const onValidateAndNext = () => {
    if (!monthlyExpense){
      setErrors(["支出を入力してください"]);
      return;
    }
    if (parseInt(monthlyExpense) > 100000) {
      setErrors(["100,000万円以下を入力してください"]);
      return;
    }
    setErrors([]);
    onNext();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.value === "" || /^-?\d+$/.test(e.target.value)) {
        validate(numDecryptor(e.target.value));
        setMonthlyExpense(numDecryptor(e.target.value));
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 9 ? "currentCard" : ""
      }`}
      id="question-8"
    >
      <ScrollBackBtn onClick={() => onPrev(8)} />
      <div className="cardWrapper questionTxt">
        <div className="titleImage">
          <img src="/img/expense.svg" alt="" />
        </div>
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            8&nbsp;/&nbsp;10
          </span>
          <h2 className="typographyPrimaryColor typographyTextSubTitle">
            月々の支出は
          </h2>
          <span>世帯の全支出を教えてください</span>
        </div>
        <fieldset className={errors.length > 0 ? "error" : ""}>
          <div data-prefix="およそ" data-suffix="万円">
            <input
              type="text"
              inputMode="numeric"
              aria-invalid="true"
              name="yearlyIncome"
              value={
                !isFocused && monthlyExpense
                  ? jpNumFormatter(parseInt(monthlyExpense), true)
                  : monthlyExpense
              }
              onChange={onChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
          <div className="validation">
            {errors.map((error) => {
              return error;
            })}
          </div>
        </fieldset>
        <div
          className="typographyAlignRight"
          style={{
            marginTop: 16,
          }}
        >
          覚えていない場合は
          <span
            className="hasSmokedLink"
            onClick={() => {
              setMonthlyExpense(undefined);
              setErrors([]);
              onNext();
            }}
          >
            回答をスキップ
          </span>
        </div>
      </div>
      {current === 9 && (
        <div
          style={{
            marginTop: -14,
            marginRight: 28,
            textAlign: "right",
          }}
        >
          <ArrowButton onClick={() => onValidateAndNext()} />
        </div>
      )}
    </section>
  );
};

export default Question8;

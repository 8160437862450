import { ChangeEvent, useState } from "react";
import { ArrowButton, ScrollBackBtn } from "../../../components/atoms/Button";
import { jpNumFormatter, numDecryptor } from "../../../utils/formatter";
interface Props {
  current: number;
  monthlyInsurance?: string;
  whoAssured: number;
  setMonthlyInsurance: (value: string | undefined) => void;
  onNext: () => void;
  onPrev: (value: number) => void;
}

const Question9 = ({
  current,
  monthlyInsurance,
  whoAssured,
  setMonthlyInsurance,
  onNext,
  onPrev,
}: Props) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState(false);

  const validate = (value: string) => {

    if (parseInt(value) > 1000000) {
      setErrors(["1,000,000円以下を入力してください"]);
      return;
    }
    setErrors([]);
  };

  
  const onValidateAndNext = () => {
    if (!monthlyInsurance){
      setErrors(["合計保険料を入力してください"]);
      return;
    }
    if (parseInt(monthlyInsurance) > 1000000) {
      setErrors(["1,000,000円以下を入力してください"]);
      return;
    }
    setErrors([]);
    onNext();
  };


  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.value === "" || /^-?\d+$/.test(e.target.value)) {
        validate(numDecryptor(e.target.value));
        setMonthlyInsurance(numDecryptor(e.target.value));
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 10 ? "currentCard" : ""
      }`}
      id="question-9"
    >
      <ScrollBackBtn onClick={() => onPrev(9)} />
      <div className="cardWrapper questionTxt">
        <div className="titleImage">
          <img src="/img/moneyPlant.svg" alt="" />
        </div>
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            9&nbsp;/&nbsp;10
          </span>
          <h2 className="typographyTextSubTitle">
            現在、
            月々に支払っている合計保険料は
          </h2>
          <span>
            {whoAssured === 1
              ? "あなた"
              : whoAssured === 2
              ? "旦那さま"
              : whoAssured === 3
              ? "奥さま"
              : "診断したい方"}
            にかかっている生命保険、医療保険、がん保険等の合計保険料を教えてください
          </span>
        </div>
        <fieldset className={errors.length > 0 ? "error" : ""}>
          <div data-prefix="およそ" data-suffix="円">
            <input
              type="text"
              inputMode="numeric"
              aria-invalid="true"
              name="monthlyInsurance"
              value={
                !isFocused && monthlyInsurance
                  ? jpNumFormatter(parseInt(monthlyInsurance), true)
                  : monthlyInsurance
              }
              onChange={onChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
          <div className="validation">
            {errors.map((error) => {
              return error;
            })}
          </div>
        </fieldset>
        <span>保険に加入していない場合は０円とご入力ください</span>
        <div
          className="typographyAlignRight"
          style={{
            marginTop: 16,
          }}
        >
          覚えていない場合は
          <span
            className="hasSmokedLink"
            onClick={() => {
              setMonthlyInsurance(undefined);
              setErrors([]);
              onNext();
            }}
          >
            回答をスキップ
          </span>
        </div>
      </div>
      {current === 10 && (
        <div
          style={{
            marginTop: -14,
            marginRight: 28,
            textAlign: "right",
          }}
        >
          <ArrowButton onClick={() => onValidateAndNext()} />
        </div>
      )}
    </section>
  );
};

export default Question9;

import { useMemo, useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { jpNumFormatter } from "../../../utils/formatter";
import {
  cancerPDStorage,
  cartStorage,
  deathPDStorage,
  incomeProtectionPDStorage,
  medicalPDStorage,
  searchConditionStorage,
  simpleDiagnoseStorage,
} from "../../../utils/storage";
import { CartStorage } from "../../comparison/components/EstimateResult";
//import { InsuranceRankResponseInner } from "../../comparison/comparison";
import { EstimateInsurance } from "../../../openApi/api";
// import { MAIN_BENEFIT_TEXT_SET } from "../../../utils/textSet";

interface Props {
  isDispComparisonButton: boolean | undefined;
}

type ViewListInCartInner = {
  thumbnail_url?: string;
  company_name?: string;
  insurance_category?: string;
  memo1?: string;
  memo2?: string;
  memo3?: string;
  fee_memos?: Array<string>;
  insurance_fee?: number | undefined;
  insurance_detail_link?: string;
  total_insurance_fee?: number;
  approval_number_diagnosis?: string;
};

export const EstimatePremium = ( {isDispComparisonButton = true}: Props) => {
  const navigate = useNavigate();
  const { result: simpleDiagnose, request: simpleDiagnoseFilter } = useMemo(
    () => simpleDiagnoseStorage.get(),
    []
  );
  
  const cart = useMemo(() => cartStorage.get(), []);
  
  const onCompare = () => {
    navigate(`/comparison?age=${simpleDiagnoseFilter.age}&sex=${simpleDiagnoseFilter.sex}`);
  };

  const onViewProduct = (item: EstimateInsurance) => {
    if (!item.insurance_detail_link) return;
    if (item.insurance_detail_link.includes("medical_insurances/")) {
      medicalPDStorage.set({
        totalInsuranceFee: item.insurance_fee,
        product_name: item.insurance_name,
        memo1: item.memo1,
        memo2: item.memo2,
        memo3: item.memo3,
        fee_memos: item.fee_memos
      });
    } else if (item.insurance_detail_link.includes("cancer_insurances/")) {
      cancerPDStorage.set({
        totalInsuranceFee: item.insurance_fee,
        product_name: item.insurance_name,
        memo1: item.memo1,
        memo2: item.memo2,
        memo3: item.memo3,
        fee_memos: item.fee_memos
      });
    } else if (item.insurance_detail_link.includes("whole_death_insurances/")) {
      deathPDStorage.set({
        totalInsuranceFee: item.insurance_fee,
        product_name: item.insurance_name,
        memo1: item.memo1,
        memo2: item.memo2,
        memo3: item.memo3,
        fee_memos: item.fee_memos
      });
    } else if (item.insurance_detail_link.includes("term_death_insurances/")) {
      deathPDStorage.set({
        totalInsuranceFee: item.insurance_fee,
        product_name: item.insurance_name,
        memo1: item.memo1,
        memo2: item.memo2,
        memo3: item.memo3,
        fee_memos: item.fee_memos
      });
    } else if (item.insurance_detail_link.includes("income_protection_insurances/")) {
      incomeProtectionPDStorage.set({
        totalInsuranceFee: item.insurance_fee,
        product_name: item.insurance_name,
        memo1: item.memo1,
        memo2: item.memo2,
        memo3: item.memo3,
        fee_memos: item.fee_memos
      });
    }
    window.open(item.insurance_detail_link, "_blank");
  };

/*
  const totalInsuranceFee = useMemo(() => {
    let totalInsurance = 0;
    simpleDiagnose.recommended_insurance_items?.forEach((item) => {
      totalInsurance += item.insurance_fee ?? 0;
    });
    return totalInsurance;
  }, [simpleDiagnose]);
*/

  const setInsuranceItemSearchCondition = (item: EstimateInsurance) =>{
    switch(item.insurance_type){
      case 1:
        if(item.condition1){
          searchConditionStorage.set({
            ...searchConditionStorage.get(),
            hospitalizationGuarantee: item.condition1,
          });
        }
        break;
      case 2 :
        if(item.condition1){
          searchConditionStorage.set({
            ...searchConditionStorage.get(),
            malignantDiagnosticOnce: item.condition1,
          });
        }
        break;
      case 3 :
        if(item.condition1){
          searchConditionStorage.set({
            ...searchConditionStorage.get(),
            wholeDeathBenefit: item.condition1,
          });
        }
        break;
      case 4 :
        if(item.condition1){
          searchConditionStorage.set({
            ...searchConditionStorage.get(),
            termDeathBenefit: item.condition1,
          });
        }
        break;
      case 5 :
        if(item.condition1){
          searchConditionStorage.set({
            ...searchConditionStorage.get(),
            monthlyBenefit: item.condition1,
          });
        }
        break;
    }
  }

  const [totalInsuranceFee, setTotalInsuranceFee] = useState<number>(0);
  const addTotalInsuranceFee = (plus: number) => setTotalInsuranceFee((prevCount) => prevCount + plus);
  
  const [approvalNumbers, setApprovalNumbers] = useState<string[]>([]);

  const [viewListInCart, setViewListInCart] = useState<ViewListInCartInner[]>([]);
  const addViewListInCart = (item: ViewListInCartInner) => {
    setViewListInCart((prevCart) => {
      return [...prevCart, {
        thumbnail_url: item?.thumbnail_url ?? '',
        company_name: item?.company_name ?? '',
        insurance_category: item?.insurance_category ?? '',
        memo1: item?.memo1 ?? '',
        memo2: item?.memo2 ?? '',
        memo3: item?.memo3 ?? '',
        fee_memos: item?.fee_memos ?? [],
        insurance_fee: item?.insurance_fee ?? 0,
        insurance_detail_link: item?.insurance_detail_link ?? ''
      }];
    });
  };
  
  useEffect(() => {
    setApprovalNumbers(simpleDiagnose?.approval_numbers ?? []);
    
    simpleDiagnose.recommended_insurance_items?.forEach((item) => {
      setInsuranceItemSearchCondition(item);
    });
    
    searchConditionStorage.set({
      ...searchConditionStorage.get(),
      typeOfInsurance: 1,
      age: simpleDiagnoseFilter.age,
      sex: simpleDiagnoseFilter.sex,
    });
    
    const cartData: CartStorage = {
      insuranceIdList: [],
      insuranceList: [],
    };
    
    setViewListInCart([]);
    setTotalInsuranceFee(0);
    
    if (cart !== null && cart?.insuranceList !== null){
      cart?.insuranceList.forEach((item: ViewListInCartInner) => {
        addViewListInCart(item);
        addTotalInsuranceFee(item?.insurance_fee ?? 0);
      });
    } else {
      simpleDiagnose.recommended_insurance_items?.forEach((item) => {
        if (!item.insurance_code) return;
          cartData.insuranceIdList.push(item.insurance_code);
          cartData.insuranceList.push({
            ...item,
	        insurance_id: item.insurance_code,
	        insurance_period: item.payment_term,
	        total_insurance_fee: item.insurance_fee,
	      });
	      
	      addViewListInCart(item);
	      addTotalInsuranceFee(item?.insurance_fee ?? 0);
	    });
	    
	    cartStorage.set(cartData);
	}
  }, [cart, simpleDiagnose.recommended_insurance_items, simpleDiagnose.approval_numbers, simpleDiagnoseFilter.age, simpleDiagnoseFilter.sex]);
  
  useEffect(() => {
    console.log('useEffect');
    if (cart !== null && cart?.insuranceList !== null){
      console.log('clear');
      let tmpApprovalNumbers: string[] = [];
      
      cart?.insuranceList.forEach((item: ViewListInCartInner) => {
        if (item?.approval_number_diagnosis){
          tmpApprovalNumbers.push(item?.approval_number_diagnosis);
        }
      });
      
      setApprovalNumbers(tmpApprovalNumbers);
    }
  }, [cart, setApprovalNumbers]);
  
  return (
    <section>
      <div className="cardWrapper">
        <div className="cardContainer">
          <h2 className="recommendedInsuranceItems_title">Myプラン(見積もり)</h2>
          <p className="recommendedInsuranceItems_subTitle">
            見直した場合の、月々の保険料見込み
          </p>
          <p className="recommendedInsuranceItems_subsubTitle">
          &#60;算出条件&#62;
          </p>

          <p className="recommendedInsuranceItems_subsubTitle">
          契約年齢：{simpleDiagnoseFilter.age}歳　性別：{simpleDiagnoseFilter.sex === 1 ? "男性" : simpleDiagnoseFilter.sex === 2 ? "女性" : ""}　払込回数：月払　払込経路：口座振替扱
          </p>
          <ul
            className="recommendedInsuranceItems_table"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              rowGap: "15px",
              margin: "33px 0 24px",
            }}
          >
            {viewListInCart?.map((item, index) => (
              <li className="grid-repeat" key={`insuranceItem_${index}`}>
                <div className="recommendedInsuranceItems_child">
                  <div>
                    <img
                      style={{
                        width: "100px",
                        height: "auto",
                        boxShadow: "0 0 4px #ccc",
                        borderRadius: "5px",
                      }}
                      src={item.thumbnail_url}
                      alt=""
                    />
                    <p
                      className="typographyFontsizeXXs typographySecondaryColor typographyFontFeaturePalt"
                      style={{
                        marginTop: 4.75,
                        textAlign: "center",
                      }}
                    >
                      {item.company_name}
                    </p>
                  </div>
                  <div>
                    <div
                      className="typographyPrimaryColor"
                      style={{ lineHeight: 1.3 }}
                    >
                      <div>
                        <div style={{ fontSize: "13px" }}>
                          {item.insurance_category}
                        </div>
                      </div>
                      <div className="memoListBox typographyFontsizeXs font5e">
                        {item.memo1 ? 
                          <span>
                            {item.memo1}
                          </span>
                        : ''}
                        {item.memo2 ? 
                          <span>
                            {item.memo2}
                          </span>
                        : ''}
                        {item.memo3 ? 
                          <span>
                            {item.memo3}
                          </span>
                        : ''}
                        {item.fee_memos?.map((memo, index) => (
                          <span>
                            {memo}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recommendedInsuranceItems_child content-float typographyPrimaryColor">
                  <div className="text-center">
                    <span className="recommendedInsuranceItems_number ">
                      {jpNumFormatter(item.insurance_fee!)}
                    </span>
                    円
                  </div>
                  <div className="text-center">
                    <Button
                      variant="outline-primary"
                      className="rounded-pill m-auto ps-4 pe-4 gap-1 align-items-center"
                      style={{ fontSize: "11px" }}
                      onClick={() => onViewProduct(item)}
                    >
                      商品を見る
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="recommendedInsuranceItems_border typographyPrimaryColor total-insurance-label">
            <div>
              <div className="recommendedInsuranceItems_totalTitle typographyTextSubTitle">
                月々合計
              </div>
            </div>
            <div>
              <div>
                <span className="recommendedInsuranceItems_number recommendedInsuranceItems_totalNumber">
                  {jpNumFormatter(totalInsuranceFee, true)}
                </span>
                円
              </div>
            </div>
          </div>
          {isDispComparisonButton ?
            <Stack style={{ marginTop: "40px" }}>
              <Button
                variant="blue"
                className="p-3"
                style={{
                  borderRadius: 12,
                }}
                onClick={onCompare}
              >
                保険商品を比較してみる
              </Button>
            </Stack>
            : ''
          }
          <p className="typographyTextSupport typographySecondaryColor recommendedInsuranceItems_guardText">
             ここで表示されているMyプランの内容は、ご入力いただいた各情報を元に算出された必要保障額に対し、弊社が定めた基準でおすすめしている商品内容となっております。 なお、必ずしも表示されている保険商品で必要保障額の全てが満たされるとは限りません。また、ここで表示されている情報は、保障内容の全てが記載されているわけではなくあくまで情報の一部となる為、これ以外の保険商品と単純に比較したり優劣をつけたりすることはできません。 実際にご検討いただく際には各保険会社のパンフレット及び、ご契約のしおり、重要事項説明書をご確認ください。 加えて、その他の条件等によってご提案できる商品や保険料が異なる場合がございますので、詳しいことにつきましてはお問い合わせください。 また、各保険会社の審査の結果お引き受け出来ない可能性もございますので何卒ご了承ください。
本資料でご案内している保険商品に関する内容は、2024年3月1日現在で適用されているものです。
          </p>
          {approvalNumbers.length > 0 && (
            <div className="approvalNumbers">
              <p>承認番号：</p>
              <ul>
                {approvalNumbers?.map((approval_number, index) => (
                  <li>{approval_number}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

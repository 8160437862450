export const DEFAULT_SEARCH_CONDITION = {
  typeOfInsurance: 1,
  maxBudget: 1,
  underwritingEasing: false,
  order: 1,
  age: 30,
  sex: 1,
  hospitalizationGuarantee: 0,
  malignantDiagnosticOnce: 0,
  wholeDeathBenefit: 0,
  termDeathBenefit: 0,
  monthlyBenefit: 0,
  isOpenNotes: 0,
};

export const DEFAULT_COMMON_PD_PARAM = {
  paymentExemption: false,
};

export const DEFAULT_MEDICAL_PD_PARAM = {
  age: 30,
  sex: 1,
  maxBudget: 0,

  hospitalizationGuarantee: 0,
  hospitalizationGuaranteeDays: 60,
  hospitalizationOnce: 0,
  surgeryGuarantee: false,
  commutingDailyAmount: 0,
  commutingDailyAmountDays: 0,
  commutingOnce: 0,
  advancedMedical: false,
  cancerSpecialContractAmount: 0,
  specifiedDisease: false,
  deathSpecialContractAmount: 0,
  congratulatoryMoney: false,
};

export const DEFAULT_CANCER_PD_PARAM = {
  age: 30,
  sex: 1,
  maxBudget: 0,

  malignantDiagnosticOnce: 0,
  intraepithelialDiagnosticOnce: 0,
  malignantDiagnosticTreatment: 0,
  intraepithelialDiagnosticTreatment: 0,
  hospitalizationDailyAmount: 0,
  hospitalizationDailyAmountDays: 0,
  surgery: 0,
  leavingHospitalOnce: 0,
  cancerHospitalDailyFee: 0,
  cancerHospitalDailyFeeDays: 0,
  commutingOnce: 0,
  advancedMedical: 0,
  radiotherapy: 0,
  anticancerDrugTreatment: false,
  femaleDiseases: false,
};

export const DEFAULT_DEATH_PD_PARAM = {
  age: 30,
  sex: 1,
  maxBudget: 0,

  wholeDeathBenefit: 0,
  termDeathBenefit: 0,
  cancelRepayment: false,
  livingNeeds: false,
};

export const DEFAULT_INCOME_PROTECTION_PD_PARAM = {
  age: 30,
  sex: 1,
  maxBudget: 0,

  monthlyBenefit: 0,
  cancelRepayment: false,
  livingNeeds: false,
  paymentMinGuaranteeTerm: 0,
};

/** @jsxImportSource @emotion/react */
// import { Link } from "react-router-dom";
import "../../styles/pages/resultSimple.scss";
import { RequiredAmount } from "./components/RequiredAmount";
import { AnnualPremium } from "./components/AnnualPremium";
import { EstimatePremium } from "./components/EstimatePremium";
import { MoreDetails } from "./components/MoreDetails";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { partnerDataStorage } from "../../utils/storage";

const ResultSimple = () => {
  useDocumentTitle("保デジWEB-かんたん診断");
  const partnerData = partnerDataStorage.get();
  return (
    <div id="result-simple">
      <main className="simpleAssessmentResult_layout">
        <section className="simpleAssessmentResult_title">
          <h1 className="assessmentTitle_header typographyTextTitle typographyPrimaryColor textsizeadd">
            かんたん診断結果ページ
          </h1>
        </section>
        <RequiredAmount />
        <AnnualPremium />
        <EstimatePremium isDispComparisonButton={partnerData ? partnerData.comparisonButtonDisp : true} />
        <MoreDetails 
          isDispFpButton = {partnerData ? partnerData.fpButtonDisp : true}
          isDispSaveButton = {partnerData ? partnerData.saveButtonDisp : true}
        />
        {/* <section className="fixedBottomButton">
          <Link to="/detail/">もっと詳しく診断する</Link>
        </section>
        <section className="fixedBottomButton2">
          <Link to="/detail/">もっと詳しく診断する</Link>
        </section> */}
      </main>
    </div>
  );
};
export default ResultSimple;

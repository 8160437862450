import { useQuery } from "@tanstack/react-query";
import { Api } from "../openApi";
import { simpleDiagnoseStorage } from "../utils/storage";
import { useMemo } from "react";

export type WholeDeathInsuranceRankProps = {
  maxBudget?: number;
  order: number;
  age: number;
  sex: number;
  deathBenefit: number;
  cancelRepayment?: boolean;
  paymentExemption: boolean;
  underwritingEasing: boolean;
  intentionRecommendInsurance: boolean;
  livingNeeds?: boolean;
  onSuccess?: () => void;
};

export const useWholeDeathInsuranceRank = (
  params: WholeDeathInsuranceRankProps
) => {

  const { result: simpleDiagnose } = useMemo(
    () => simpleDiagnoseStorage.get(),
    []
  );

  return useQuery(
    ["getWholeDeathInsuranceRank", params],
    async () => {
      const { data } = await Api.getApiWholeDeathInsurancesRank(
        params.order,
        params.age,
        params.sex,
        params.deathBenefit,
        params.paymentExemption,
        params.underwritingEasing,
        params.intentionRecommendInsurance,
        params.maxBudget,
        params.cancelRepayment,
        params.livingNeeds,
        simpleDiagnose.diagnos_code
      );
      params.onSuccess && params.onSuccess();
      return data;
    },
    { enabled: false }
  );
};

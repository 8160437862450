/** @jsxImportSource @emotion/react */
import "../../styles/pages/simple.scss";
import ProgressBar from "../../components/atoms/ProgressBar";
import CalcContainer, { ButtonType } from "./components/CalcContainer";
import Question1 from "./components/Question1";
import Question2 from "./components/Question2";
import Question3 from "./components/Question3";
import Question4 from "./components/Question4";
import Question5 from "./components/Question5";
import Question6 from "./components/Question6";
import Question7 from "./components/Question7";
import Question8 from "./components/Question8";
import Question9 from "./components/Question9";
import Question10 from "./components/Question10";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useSimpleDiagnose } from "../../query/useSimpleDiagnose";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import Loading from "../../components/molecules/Loading";
import { usePreventMultipleCalls } from "../../hooks/usePreventMultipleCalls";
import { simpleDiagnoseStorage, cartStorage } from "../../utils/storage";

const Simple = () => {
  useDocumentTitle("保デジWEB-かんたん診断");
  const totalStep = 12;
  const [currentStep, setCurrentStep] = useState(0);
  // Simple Research Data
  const [whoAssured, setWhoAssured] = useState(0);
  const [sex, setSex] = useState(0);
  const [age, setAge] = useState<string>("");
  const [isMarried, setMarried] = useState<boolean>();
  const [childrenCount, setChildrenCount] = useState<number>(-1);
  const [childrenAges, setChildrenAges] = useState<number[]>([]);
  const [yearlyIncome, setYearlyIncome] = useState<string | undefined>("");
  const [monthlyExpense, setMonthlyExpense] = useState<string | undefined>("");
  const [monthlyInsurance, setMonthlyInsurance] = useState<string | undefined>("");
  const [hasThreeMajorDiseases, setHasThreeMajorDiseases] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { request: prevDiagnoseData } = useMemo(
    () => simpleDiagnoseStorage.get(),
    []
  );

  const scrollInto = (elementName: string) => {
    const element = document.getElementById(elementName);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    if (prevDiagnoseData) {
      setWhoAssured(prevDiagnoseData.who_assured);
      setSex(prevDiagnoseData.sex);
      setAge(prevDiagnoseData.age.toString());
      setMarried(prevDiagnoseData.is_married);
      setChildrenCount(prevDiagnoseData.children_age?.length ?? -1);
      setChildrenAges(prevDiagnoseData.children_age ?? []);
      setYearlyIncome(prevDiagnoseData.yearly_income?.toString() ?? "");
      setMonthlyExpense(prevDiagnoseData.monthly_expense?.toString() ?? "");
      setMonthlyInsurance(
        prevDiagnoseData.current_monthly_insurance_fee?.toString() ?? ""
      );
      // setHasThreeMajorDiseases(prevDiagnoseData.has_three_major_diseases);
      setCurrentStep(totalStep - 1);
      setTimeout(() => {
        const element = document.getElementById("section-end");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }, 1000);
    }
  }, [prevDiagnoseData]);

  const onSetWhoAssured = (assured: number) => {
    setWhoAssured(assured);
    if (assured === 2 || assured === 3) {
      setSex(assured - 1);
      setMarried(true);
    }
    if (currentStep === 1) {
      onNextStep();
    }
  };

  const onSetSex = (value: number) => {
    setSex(value);
    if (currentStep === 3) {
      onNextStep();
    }
  };

  const onSetMarried = (value: boolean) => {
    setMarried(value);
    if (currentStep === 5) {
      onNextStep();
    }
  };

  const onSetChildrenCount = (value: number) => {
    setChildrenCount(value);
    if (value === 0) {
      if (currentStep === 6){
        setCurrentStep(6);
        onNextStep(0);
      }
      return;
    }
    
    if (currentStep === 6) {
      onNextStep();
    }
  };

  const onSetChildrenAges = (value: number[]) => {
    setChildrenAges(value);
    
    if (currentStep === 7) {
      onNextStep();
    }
  };

  const onNextStep = useCallback(
    (tmpChildrenCnt = -1) => {
      if (currentStep === 2 && sex !== 0) {
        setCurrentStep(currentStep + 2);
      } else if (currentStep === 4 && isMarried === true) {
        setCurrentStep(currentStep + 2);
      } else if (currentStep === 6 && tmpChildrenCnt === 0) {
        setCurrentStep(8);
      } else {
        setCurrentStep(currentStep + 1);
      }

      setTimeout(() => {
        const element = document.getElementById("section-end");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }, 1000);
    },
    [currentStep, sex, isMarried]
  );

  const onPrevStep = (currentQuestion: number) => {
    const element = document.getElementById(`question-${currentQuestion - 1}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const { mutate: _postSimpleDiagnose, isLoading: waitingCall } =
    useSimpleDiagnose();

  const postSimpleDiagnose = usePreventMultipleCalls(
    async (...params: Parameters<typeof _postSimpleDiagnose>) => {
      await _postSimpleDiagnose(...params);
    }
  );

  const onComplete = () => {
    if (hasThreeMajorDiseases !== undefined) {
      const nmAge = parseInt(age);
      // const nmYearlyIncome = !yearlyIncome || Number.isNaN(parseInt(yearlyIncome)) ? 0 : parseInt(yearlyIncome);
      const nmYearlyIncome = parseInt(yearlyIncome ?? "0");;
      const nmMonthlyExpense = parseInt(monthlyExpense ?? "0");
      const nmMonthlyInsurance = parseInt(monthlyInsurance ?? "0");

      if (!nmAge || nmAge <= 0 || nmAge > 85) {
        scrollInto("question-3");
        return;
      }

      if (nmYearlyIncome > 100000) {
        scrollInto("question-7");
        return;
      }

      if (nmMonthlyExpense > 100000) {
        scrollInto("question-8");
        return;
      }

      if (nmMonthlyInsurance > 1000000) {
        scrollInto("question-9");
        return;
      }

      if (currentStep < totalStep) setCurrentStep(currentStep + 1);
      setIsLoading(true);
      setTimeout(() => {
        if (waitingCall) return;

        postSimpleDiagnose({
          who_assured: whoAssured,
          sex: sex,
          age: nmAge,
          is_married: isMarried!,
          children_age: childrenAges,
          yearly_income: yearlyIncome
            ? parseInt(yearlyIncome)
            : undefined,
          monthly_expense: monthlyExpense
            ? parseInt(monthlyExpense)
            : undefined,
          current_monthly_insurance_fee: monthlyInsurance
            ? parseInt(monthlyInsurance)
            : undefined,
          has_three_major_diseases: hasThreeMajorDiseases,
        });
      }, 1000);
      
      cartStorage.removeAll();
    }
  };

  return (
    <>
      {isLoading && <Loading title={"かんたん診断の結果を算出しています..."} />}
      <ProgressBar percentage={(currentStep * 100) / totalStep} />
      <div className="simpleAssessmentForm">
        <CalcContainer
          btnType={currentStep === 0 ? ButtonType.TEXT : undefined}
          btnText="次へすすむ"
          onClick={onNextStep}
        >
          <h1>ようこそ</h1>
          <h2>必要保障額シミュレーションへ</h2>
          {/* <p>
            まずは10個ほどのかんたんな質問で、診断したい方について教えてください。
          </p>
          <p>必要保障額と、最適な月々の保険料を算出します。</p> */}
        </CalcContainer>
        {currentStep >= 1 && (
          <Question1
            current={currentStep}
            whoAssured={whoAssured}
            setWhoAssured={onSetWhoAssured}
          />
        )}
        {currentStep >= 2 && (
          <CalcContainer
            btnType={currentStep === 2 ? ButtonType.ARROW : undefined}
            current={currentStep}
            onClick={onNextStep}
          >
            {whoAssured === 1
              ? "これからあなたについてお聞きします"
              : whoAssured === 2
              ? "これから旦那さまについてお聞きします"
              : whoAssured === 3
              ? "これから奥さまについてお聞きします"
              : "これから診断したい方についてお聞きします"}
          </CalcContainer>
        )}
        {currentStep >= 3 && (
          <Question2
            current={currentStep}
            sex={sex}
            setSex={onSetSex}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 4 && (
          <Question3
            current={currentStep}
            whoAssured={whoAssured}
            age={age}
            setAge={setAge}
            onNext={onNextStep}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 5 && (
          <Question4
            current={currentStep}
            married={isMarried}
            setIsMarried={onSetMarried}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 6 && (
          <Question5
            current={currentStep}
            childrenCount={childrenCount}
            setChildrenCount={onSetChildrenCount}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 7 && childrenCount > 0 && (
          <Question6
            current={currentStep}
            childrenCount={childrenCount}
            setChildrenAges={onSetChildrenAges}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 8 && (
          <Question7
            current={currentStep}
            income={yearlyIncome}
            childrenCount={childrenCount}
            setIncome={setYearlyIncome}
            onNext={onNextStep}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 9 && (
          <Question8
            current={currentStep}
            monthlyExpense={monthlyExpense}
            setMonthlyExpense={setMonthlyExpense}
            onNext={onNextStep}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 10 && (
          <Question9
            current={currentStep}
            monthlyInsurance={monthlyInsurance}
            whoAssured={whoAssured}
            setMonthlyInsurance={setMonthlyInsurance}
            onNext={onNextStep}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 11 && (
          <Question10
            current={currentStep}
            hasThreeMajorDiseases={hasThreeMajorDiseases}
            setHasThreeMajorDiseases={(value: boolean | undefined) => {
              setHasThreeMajorDiseases(value);
              onNextStep();
            }}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 12 && (
          <div className="textBtn complete-ctrl mt-2 mb-5">
            <button onClick={onComplete}>診断結果へ</button>
          </div>
        )}
        <div id="section-end" />
      </div>
    </>
  );
};
export default Simple;

// import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import "../../styles/pages/contactComplete.scss";
import {
  detailDiagnoseStorage,
  simpleDiagnoseStorage,
  cartStorage,
  partnerDataStorage,
} from "../../utils/storage";
// import { useEffect, useMemo } from "react";
import { useEffect} from "react";
const ContactComplete = () => {
  useDocumentTitle("保デジWEB-問い合わせ完了");

  // const [searchParams] = useSearchParams();

  // const type = useMemo(() => searchParams.get("type"), [searchParams]);
  useEffect(() => {
    simpleDiagnoseStorage.remove();
    detailDiagnoseStorage.remove();
    cartStorage.get().insuranceIdList.map( id => cartStorage.remove(id));
    partnerDataStorage.remove();
    window.history.pushState(null, '', null);
    window.addEventListener('popstate', () => window.history.pushState(null, '', null), false);
  }, []);

  return (
    <>
      <section className="contact-complete-section">
        <div className="contact-complete-form">
          <span>送信完了</span>
          <label>
              <>
                担当者よりご希望時間内でご連絡をさせていただきます。
              </>
            
          </label>
          {/* <Button
            color="primary"
            className="contactCompleteSubmit"
            onClick={() => navigate("/")}
          >
            TOPに戻る
          </Button> */}
        </div>
      </section>
    </>
  );
};

export default ContactComplete;

/** @jsxImportSource @emotion/react */
//import { Link } from 'react-router-dom';
import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";

//components
import PageHeader from "../../components/atoms/PageHeader";
import "../../styles/pages/download.scss";
import LabelInput from "../../components/molecules/LabelInput";
import PolicyModal from "../../components/molecules/PolicyModal";
import {
  cartStorage,
  detailDiagnoseStorage,
  simpleDiagnoseStorage,
} from "../../utils/storage";
import { EstimateInsurance2 } from "../../openApi/api";
import { useContactEmail } from "../../query/useContactEmail";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { hankaku2Zenkaku } from "../../utils/formatter";

const Download_document = () => {
  useDocumentTitle("保デジWEB-問い合わせ");
  const queryParameters = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const fromComparison = queryParameters.get("fromComparison");

  const simpleDiagnose = useMemo(() => simpleDiagnoseStorage.get(), []);
  const detailDiagnose = useMemo(() => detailDiagnoseStorage.get(), []);

  const diagnosCode = useMemo(
    () =>
      simpleDiagnose?.result?.diagnos_code ??
      queryParameters.get("diagnosCode") ??
      undefined,
    [simpleDiagnose, queryParameters]
  );

  const cartList = useMemo(() => cartStorage.get()?.insuranceList ?? [], []);

  const estimateInsurances = useMemo(() => {
    if (fromComparison === "true") {
      return cartList.map((cart) => ({
        insurance_code: cart.insurance_id,
        insurance_fee_codes: cart.insurance_fee_codes,
      })) as EstimateInsurance2[];
    } else if (simpleDiagnose.result.diagnos_code === diagnosCode) {
      return simpleDiagnose.result.recommended_insurance_items?.map((value) => {
        return {
          insurance_code: value.insurance_code,
          insurance_fee_codes: value.insurance_fee_codes,
        };
      }) as EstimateInsurance2[];
    } else {
      return detailDiagnose.result.recommended_insurance_items?.map((value) => {
        return {
          insurance_code: value.insurance_code,
          insurance_fee_codes: value.insurance_fee_codes,
        };
      }) as EstimateInsurance2[];
    }
  }, [simpleDiagnose, detailDiagnose, diagnosCode, fromComparison, cartList]);

  const [modal, setModal] = useState(false);

  const [name, setName] = useState<string>();
  const [nameKana, setNameKana] = useState<string>();
  const [tel, setTel] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [telErrors, setTelErrors] = useState<string[]>([]);

  const { mutate: postContactEmail } = useContactEmail();
  const [emailError, setEmailError] = useState<string>();
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    if (emailPattern.test(inputValue)) {
      setEmailError(undefined); // Clear email error if valid
    } else {
      setEmailError("有効なメールアドレスを入力してください。");
    }
  };


  const clearUndefine = () => {
    if (name === undefined) setName("");
    if (nameKana === undefined) setNameKana("");
    if (tel === undefined) setTel("");
    if (email === undefined) setEmail("");
  };

  const mobileNumberPattern = /^(090|080|070|050)\d{8}$/;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setTel(inputValue);  

    if (mobileNumberPattern.test(inputValue)) {
      // 携帯電話番号の場合、エラーをクリア
      setTelErrors([]);
    } else {
      // 携帯電話番号でない場合、エラーを設定
      setTelErrors(["携帯電話番号を入力してください。"]);
    }
  };

  const onSubmit = () => {
    if (
      nameKana === undefined ||
      nameKana === "" ||
      tel === undefined ||
      tel === "" ||
      email === undefined ||
      email === "" ||
      !mobileNumberPattern.test(tel)
    ) {
      clearUndefine();
      return;
    }

    postContactEmail({
      diagnos_code: diagnosCode,
      name,
      name_kana: nameKana,
      tel: hankaku2Zenkaku(tel),
      email,
      from_comparison: fromComparison === "true",
      estimate_insurances: estimateInsurances,
    });
  };

  return (
    <>
      <section className="download-fp-section">
        <PageHeader>メールで保存</PageHeader>
        <div className="downloadDocumentForm">
          <LabelInput
            label="カナ苗字"
            placeholder="ミョウジ"
            value={nameKana}
            onChange={(e) => setNameKana(e.target.value)}
            required
          />
          <LabelInput
            label="電話番号（ハイフンなし）"
            placeholder=""
            value={tel}
            onChange={handleChange}
            onBlur={(e) => setTel(hankaku2Zenkaku(e.target.value))}
            errors={telErrors}
            required
            type="tel"
          />
          <LabelInput
            label="メールアドレス"
            value={email}
            onChange={handleChangeEmail}
            required
            errors={emailError ? [emailError] : undefined}

          />

          <span className="typographyAnswerBtnDescription typographyAlignCenter">
            <span
              style={{
                color: "#0d6efd",
                cursor: "pointer",
              }}
              onClick={() => setModal(true)}
            >
              利用規約
            </span>
            へ同意したものとみなされます
          </span>
          <Button
            color="primary"
            className="downloadDocumentSubmit"
            onClick={onSubmit}
          >
            メールで診断結果を受け取る
          </Button>
        </div>
      </section>
      <PolicyModal visible={modal} onClose={() => setModal(false)} />
    </>
  );
};
export default Download_document;

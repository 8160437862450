import Policy from "./policy";

interface Props {
  visible: boolean;
  onClose: () => void;
}

const PolicyModal = ({ visible, onClose }: Props) => {
  return visible ? (
    <div className="policyModal">
      <div
        style={{
          position: "relative"
        }}
        className="policyModalWrap"
      >
        <span onClick={onClose} className="policyClosedBtn">
          <span></span>
          <span></span>
        </span>
        <Policy />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PolicyModal;

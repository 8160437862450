import { useEffect, useState } from "react";
import { ArrowButton, ScrollBackBtn } from "../../../components/atoms/Button";

interface Props {
  current: number;
  childrenCount?: number;
  setChildrenAges: (value: number[]) => void;
  onPrev: (value: number) => void;
}

const Question6 = ({
  current,
  childrenCount,
  setChildrenAges,
  onPrev,
}: Props) => {
  const [ages, setAges] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const validate = () => {
    const err = [];
    for (let i = 0; childrenCount && i < childrenCount; i++) {
      if (ages[i] === "") {
        err.push("お子さんの年齢を入力してください");
        break;
      }
    }
    setErrors(err);
    return err;
  };

  const onAgesChange = (value: string, index: number) => {
    const tmpAges = [...ages];
    if (value === "" || /^-?\d+$/.test(value)){
      tmpAges[index] = value ?? '';
      setAges(tmpAges);
    }
  };
  
  const onSetAges = (tmpAges: string[]) => {
    const ageNumber: number[] = [];
    
    if (!validate().length){
      tmpAges.forEach(age => {
        ageNumber.push(parseInt(age));
      });
    }
    setChildrenAges(ageNumber);
  };

  useEffect(() => {
    if (current !== 7){
      onSetAges(ages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ages]);
  
  useEffect(() => {
    if (!childrenCount) return;

    const data = [];
    for (let i = 0; i < childrenCount; i++) {
      data.push("");
    }

    setAges(data);
  }, [childrenCount]);

  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 7 ? "currentCard" : ""
      }`}
      id="question-6"
    >
      <ScrollBackBtn onClick={() => onPrev(6)} />
      <div className="cardWrapper questionTxt">
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            6&nbsp;/&nbsp;10
          </span>
          <h2 className="typographyPrimaryColor typographyTextSubTitle">
            お子さんはおいくつですか
          </h2>
        </div>
        <fieldset className={errors.length > 0 ? "error" : ""}>
          {ages.map((value, index) => {
            return (
              <div
                data-prefix={`${index + 1}人目`}
                data-suffix="歳"
                key={`childrenAge_${index}`}
              >
                <input
                  type="text"
                  inputMode="numeric"
                  aria-invalid="true"
                  name="age"
                  value={ages[index]}
                  onChange={(e) => {
                    onAgesChange(e.target.value, index);
                  }}
                />
              </div>
            );
          })}
          <div className="validation">
            {errors.map((error) => {
              return error;
            })}
          </div>
        </fieldset>
      </div>
      {current === 7 && (
        <div
          style={{
            marginTop: -14,
            marginRight: 28,
            textAlign: "right",
          }}
        >
          <ArrowButton onClick={() => {
            validate();
            onSetAges(ages);
          }} />
        </div>
      )}
    </section>
  );
};

export default Question6;

/** @jsxImportSource @emotion/react */
//import { Link } from 'react-router-dom';
import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";

//components
import PageHeader from "../../components/atoms/PageHeader";
import "../../styles/pages/download.scss";
import LabelInput from "../../components/molecules/LabelInput";
import MultiSelect from "../../components/molecules/MultiSelect";
import PolicyModal from "../../components/molecules/PolicyModal";
import {
  detailDiagnoseStorage,
  simpleDiagnoseStorage,
} from "../../utils/storage";
import { EstimateInsurance2 } from "../../openApi/api";
import DocumentDescription from "./components/DocumentDescription";
import { useContactDocument } from "../../query/useContactDocument";

const Download_document = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const diagnosCode = queryParameters.get("diagnosCode") ?? undefined;
  const fromComparison = queryParameters.get("fromComparison");

  const simpleDiagnose = useMemo(() => simpleDiagnoseStorage.get(), []);
  const detailDiagnose = useMemo(() => detailDiagnoseStorage.get(), []);

  const estimateInsurances = useMemo(() => {
    if (simpleDiagnose.result.diagnos_code === diagnosCode) {
      return simpleDiagnose.result.recommended_insurance_items?.map((value) => {
        return {
          insurance_code: value.insurance_code,
          insurance_fee_codes: value.insurance_fee_codes,
        };
      }) as EstimateInsurance2[];
    } else {
      return detailDiagnose.result.recommended_insurance_items?.map((value) => {
        return {
          insurance_code: value.insurance_code,
          insurance_fee_codes: value.insurance_fee_codes,
        };
      }) as EstimateInsurance2[];
    }
  }, [simpleDiagnose, detailDiagnose, diagnosCode]);

  const [modal, setModal] = useState(false);

  const [name, setName] = useState<string>();
  const [nameKana, setNameKana] = useState<string>();
  const [tel, setTel] = useState<string>();
  const [candidateDayOfWeek, setCandidateDayOfWeek] = useState<string[]>();
  const [candidateTimeZone, setCandidateTimeZone] = useState<string[]>();
  const [email, setEmail] = useState<string>();

  const { mutate: postContactDocument } = useContactDocument();

  const clearUndefine = () => {
    if (name === undefined) setName("");
    if (nameKana === undefined) setNameKana("");
    if (tel === undefined) setTel("");
    if (candidateDayOfWeek === undefined) setCandidateDayOfWeek([]);
    if (candidateTimeZone === undefined) setCandidateTimeZone([]);
    if (email === undefined) setEmail("");
  };
  const onSubmit = () => {
    if (
      name === undefined ||
      name === "" ||
      nameKana === undefined ||
      nameKana === "" ||
      tel === undefined ||
      tel === "" ||
      candidateDayOfWeek === undefined ||
      candidateDayOfWeek.length === 0 ||
      candidateTimeZone === undefined ||
      candidateTimeZone.length === 0 ||
      email === undefined ||
      email === ""
    ) {
      clearUndefine();
      return;
    }

    postContactDocument({
      diagnos_code: diagnosCode,
      name,
      name_kana: nameKana,
      tel,
      candidate_day_of_week: candidateDayOfWeek,
      candidate_time_zone: candidateTimeZone,
      email,
      from_comparison: fromComparison === "true",
      estimate_insurances: estimateInsurances,
    });
  };

  return (
    <>
      <section className="download-fp-section">
        <PageHeader>資料請求</PageHeader>
        <DocumentDescription />
        <div className="downloadDocumentForm">
          <LabelInput
            label="名前"
            placeholder="苗字　名前"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <LabelInput
            label="フリガナ"
            placeholder="フリ　ガナ"
            value={nameKana}
            onChange={(e) => setNameKana(e.target.value)}
            required
          />
          <LabelInput
            label="電話番号"
            placeholder="0000-00-0000"
            value={tel}
            onChange={(e) => setTel(e.target.value)}
            required
          />
          <MultiSelect
            label="電話がつながりやすい曜日"
            options={[
              "月曜日",
              "火曜日",
              "水曜日",
              "木曜日",
              "金曜日",
              "土曜日",
              "日曜日",
            ]}
            value={candidateDayOfWeek}
            onChange={(data) => setCandidateDayOfWeek(data)}
            selectKey="dayOfWeek"
            required
          />
          <MultiSelect
            label="電話がつながりやすい時間帯"
            options={[
              "午前中（10〜12時）",
              "12〜14時",
              "14〜16時",
              "16〜18時",
              "18〜20時半",
            ]}
            value={candidateTimeZone}
            onChange={(data) => setCandidateTimeZone(data)}
            selectKey="timeZone"
            required
          />
          <LabelInput
            label="メールアドレス"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <span className="typographyAnswerBtnDescription typographyAlignCenter">
            <span
              style={{
                color: "#0d6efd",
                cursor: "pointer",
              }}
              onClick={() => setModal(true)}
            >
              利用規約
            </span>
            へ同意したものとみなされます
          </span>
          <Button
            color="primary"
            className="downloadDocumentSubmit"
            onClick={onSubmit}
          >
            送信する
          </Button>
        </div>
      </section>
      <PolicyModal visible={modal} onClose={() => setModal(false)} />
    </>
  );
};
export default Download_document;

/* tslint:disable */
/* eslint-disable */
/**
 * hodegiWebAPI
 * 保デジ　ユーザーアプリ側API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 先進医療データ群
 * @export
 * @interface AdvancedMedical
 */
export interface AdvancedMedical {
    /**
     * 
     * @type {boolean}
     * @memberof AdvancedMedical
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdvancedMedical
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdvancedMedical
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * がん保険検索レスポンスモデル
 * @export
 * @interface ApiCancerInsurancesRankResponse
 */
export interface ApiCancerInsurancesRankResponse {
    /**
     * ランキング表ヘッダー文字列
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponse
     */
    'table_header_text'?: string;
    /**
     * 対象合計商品数
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {Array<ApiCancerInsurancesRankResponseInsurancesInner>}
     * @memberof ApiCancerInsurancesRankResponse
     */
    'insurances'?: Array<ApiCancerInsurancesRankResponseInsurancesInner>;
}
/**
 * 
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInner
 */
export interface ApiCancerInsurancesRankResponseInsurancesInner {
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'memo3'?: string;
    /**
     * 備考
     * @type {Array<string>}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'fee_memos'?: Array<string>;
    /**
     * 保険会社名
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'company_name'?: string;
    /**
     * 保険商品名
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'insurance_name'?: string;
    /**
     * 保険商品ID
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'insurance_id'?: string;
    /**
     * ネット申し込み
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'is_online_entry'?: boolean;
    /**
     * ネット申し込みor資料請求URL
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'entry_url'?: string;
    /**
     * 商品サムネイルURL
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'thumbnail_url'?: string;
    /**
     * 合計保険料（表上に表示する）
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'total_insurance_fee'?: number;
    /**
     * 保証金額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'guarantee_amount'?: string;
    /**
     * 保証期間
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'insurance_period'?: string;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticOnce}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'malignant_diagnostic_once'?: ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticOnce;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticOnce}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'intraepithelial_diagnostic_once'?: ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticOnce;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticTreatment}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'malignant_diagnostic_treatment'?: ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticTreatment;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticTreatment}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'intraepithelial_diagnostic_treatment'?: ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticTreatment;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmount}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'hospitalization_daily_amount'?: ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmount;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmountDays}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'hospitalization_daily_amount_days'?: ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmountDays;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerSurgery}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'surgery'?: ApiCancerInsurancesRankResponseInsurancesInnerSurgery;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerLeavingHospitalOnce}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'leaving_hospital_once'?: ApiCancerInsurancesRankResponseInsurancesInnerLeavingHospitalOnce;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerCancerHospitalDailyFee}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'cancer_hospital_daily_fee'?: ApiCancerInsurancesRankResponseInsurancesInnerCancerHospitalDailyFee;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerCommutingOnce}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'commuting_once'?: ApiCancerInsurancesRankResponseInsurancesInnerCommutingOnce;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerAdvancedMedical}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'advanced_medical'?: ApiCancerInsurancesRankResponseInsurancesInnerAdvancedMedical;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerRadiotherapy}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'radiotherapy'?: ApiCancerInsurancesRankResponseInsurancesInnerRadiotherapy;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerAnticancerDrugTreatment}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'anticancer_drug_treatment'?: ApiCancerInsurancesRankResponseInsurancesInnerAnticancerDrugTreatment;
    /**
     * 
     * @type {ApiCancerInsurancesRankResponseInsurancesInnerFemaleDiseases}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'female_diseases'?: ApiCancerInsurancesRankResponseInsurancesInnerFemaleDiseases;
    /**
     * 
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'is_insurance_payment_exemption'?: boolean;
    /**
     * 算出条件
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'condition_wording'?: string;
    /**
     * 承認番号
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'approval_number'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'attention_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'file_url'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'insurance_fee_codes'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'insurance_sub_fee_codes'?: Array<string>;
}
/**
 * 先進医療
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerAdvancedMedical
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerAdvancedMedical {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerAdvancedMedical
     */
    'is_main_contract'?: boolean;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerAdvancedMedical
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerAdvancedMedical
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 抗がん剤治療
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerAnticancerDrugTreatment
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerAnticancerDrugTreatment {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerAnticancerDrugTreatment
     */
    'is_main_contract'?: boolean;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerAnticancerDrugTreatment
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerAnticancerDrugTreatment
     */
    'insurance_fee_unique_code'?: string;
    /**
     * 詳細表示
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerAnticancerDrugTreatment
     */
    'guarantee_amount_text'?: string;
}
/**
 * がん通院日額
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerCancerHospitalDailyFee
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerCancerHospitalDailyFee {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerCancerHospitalDailyFee
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerCancerHospitalDailyFee
     */
    'guarantee_amount_text'?: string;
    /**
     * 日数
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerCancerHospitalDailyFee
     */
    'days'?: number;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerCancerHospitalDailyFee
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerCancerHospitalDailyFee
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * がん通院一時金
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerCommutingOnce
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerCommutingOnce {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerCommutingOnce
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerCommutingOnce
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerCommutingOnce
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerCommutingOnce
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 女性特約
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerFemaleDiseases
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerFemaleDiseases {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerFemaleDiseases
     */
    'is_main_contract'?: boolean;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerFemaleDiseases
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerFemaleDiseases
     */
    'insurance_fee_unique_code'?: string;
    /**
     * 詳細
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerFemaleDiseases
     */
    'guarantee_amount_text'?: string;
}
/**
 * がん入院
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmount
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmount {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmount
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmount
     */
    'guarantee_amount_text'?: string;
    /**
     * 日数
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmount
     */
    'days'?: number;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmount
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmount
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * がん入院日数
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmountDays
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmountDays {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmountDays
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmountDays
     */
    'guarantee_amount_text'?: string;
    /**
     * 日数
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmountDays
     */
    'days'?: number;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmountDays
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerHospitalizationDailyAmountDays
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 診断一時金（上皮内新生物）
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticOnce
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticOnce {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticOnce
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticOnce
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticOnce
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticOnce
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 治療給付金（上皮内新生物）
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticTreatment
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticTreatment {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticTreatment
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticTreatment
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticTreatment
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerIntraepithelialDiagnosticTreatment
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * がん退院
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerLeavingHospitalOnce
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerLeavingHospitalOnce {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerLeavingHospitalOnce
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerLeavingHospitalOnce
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerLeavingHospitalOnce
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerLeavingHospitalOnce
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 診断一時金（悪性新生物）
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticOnce
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticOnce {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticOnce
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticOnce
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticOnce
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticOnce
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 治療給付金（悪性新生物）
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticTreatment
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticTreatment {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticTreatment
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticTreatment
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticTreatment
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerMalignantDiagnosticTreatment
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 放射線治療
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerRadiotherapy
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerRadiotherapy {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerRadiotherapy
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerRadiotherapy
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerRadiotherapy
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerRadiotherapy
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * がん手術
 * @export
 * @interface ApiCancerInsurancesRankResponseInsurancesInnerSurgery
 */
export interface ApiCancerInsurancesRankResponseInsurancesInnerSurgery {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerSurgery
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerSurgery
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerSurgery
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInnerSurgery
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface ApiCancerInsurancesResponse
 */
export interface ApiCancerInsurancesResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'application_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiCancerInsurancesResponse
     */
    'is_online_entry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'point_title_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'point_detail_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'point_title_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'point_detail2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'number_of_payment'?: string;
    /**
     * 申込方法
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'entry_method'?: string;
    /**
     * 
     * @type {BasicInfo}
     * @memberof ApiCancerInsurancesResponse
     */
    'basic_info'?: BasicInfo;
    /**
     * 
     * @type {ProductInfo1}
     * @memberof ApiCancerInsurancesResponse
     */
    'product_info'?: ProductInfo1;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'guard_text1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'guard_text2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'guard_text3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'approval_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'thumbnail_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCancerInsurancesResponse
     */
    'memo3'?: string;
}
/**
 * 
 * @export
 * @interface ApiContactDocumentRequestRequest
 */
export interface ApiContactDocumentRequestRequest {
    /**
     * 診断時の問い合わせのみ設定
     * @type {string}
     * @memberof ApiContactDocumentRequestRequest
     */
    'diagnos_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactDocumentRequestRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactDocumentRequestRequest
     */
    'name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactDocumentRequestRequest
     */
    'tel': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiContactDocumentRequestRequest
     */
    'candidate_day_of_week': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiContactDocumentRequestRequest
     */
    'candidate_time_zone': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiContactDocumentRequestRequest
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiContactDocumentRequestRequest
     */
    'from_comparison': boolean;
    /**
     * 
     * @type {Array<EstimateInsurance2>}
     * @memberof ApiContactDocumentRequestRequest
     */
    'estimate_insurances'?: Array<EstimateInsurance2>;
}
/**
 * 
 * @export
 * @interface ApiContactDocumentRequestResponse
 */
export interface ApiContactDocumentRequestResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApiContactDocumentRequestResponse
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiContactDocumentRequestResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiContactEmailRequest
 */
export interface ApiContactEmailRequest {
    /**
     * 診断時の問い合わせのみ設定
     * @type {string}
     * @memberof ApiContactEmailRequest
     */
    'diagnos_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactEmailRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactEmailRequest
     */
    'name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactEmailRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactEmailRequest
     */
    'tel': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiContactEmailRequest
     */
    'from_comparison': boolean;
    /**
     * 
     * @type {Array<EstimateInsurance2>}
     * @memberof ApiContactEmailRequest
     */
    'estimate_insurances'?: Array<EstimateInsurance2>;
}
/**
 * 
 * @export
 * @interface ApiContactEmailResponse
 */
export interface ApiContactEmailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApiContactEmailResponse
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiContactEmailResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiAfterContactResponse
 */
export interface ApiAfterContactResponse {
    /**
     * 診断時または、診断経由の比較から問い合わせ時のみ設定
     * @type {string}
     * @memberof ApiAfterContactResponse
     */
    'diagnos_code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiAfterContactResponse
     */
    'canchecksdata': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiAfterContactResponse
     */
    'canradiodatas': Array<string>;
}
/**
 * 
 * @export
 * @interface ApiContactFpRequest
 */
export interface ApiContactFpRequest {
    /**
     * 診断時または、診断経由の比較から問い合わせ時のみ設定
     * @type {string}
     * @memberof ApiContactFpRequest
     */
    'diagnos_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactFpRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactFpRequest
     */
    'name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactFpRequest
     */
    'tel': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiContactFpRequest
     */
    'candidate_day_of_week': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiContactFpRequest
     */
    'candidate_time_zone': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiContactFpRequest
     */
    'email': string;
    /**
     * 比較からの呼び出しか？
     * @type {boolean}
     * @memberof ApiContactFpRequest
     */
    'from_comparison': boolean;
    /**
     * 比較からの呼び出し時のみ必須
     * @type {Array<EstimateInsurance1>}
     * @memberof ApiContactFpRequest
     */
    'estimate_insurances'?: Array<EstimateInsurance1>;
    /**
     * 備考
     * @type {string}
     * @memberof ApiContactFpRequest
     */
    'remarks'?: string;
    /**
     * 勤務先
     * @type {string}
     * @memberof ApiContactFpRequest
     */
    'company'?: string;
}
/**
 * 
 * @export
 * @interface ApiContactFpResponse
 */
export interface ApiContactFpResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApiContactFpResponse
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiContactFpResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiContactSmsRequest
 */
export interface ApiContactSmsRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiContactSmsRequest
     */
    'diagnos_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactSmsRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactSmsRequest
     */
    'name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactSmsRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContactSmsRequest
     */
    'tel': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiContactSmsRequest
     */
    'from_comparison': boolean;
    /**
     * 
     * @type {Array<EstimateInsurance1>}
     * @memberof ApiContactSmsRequest
     */
    'estimate_insurances'?: Array<EstimateInsurance1>;
}
/**
 * 
 * @export
 * @interface ApiContactSmsResponse
 */
export interface ApiContactSmsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApiContactSmsResponse
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiContactSmsResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDetailDiagnoseRequest
 */
export interface ApiDetailDiagnoseRequest {
    /**
     * かんたん診断で取得した診断ID
     * @type {string}
     * @memberof ApiDetailDiagnoseRequest
     */
    'diagnos_code': string;
    /**
     * 分からない場合はfalse
     * @type {boolean}
     * @memberof ApiDetailDiagnoseRequest
     */
    'has_smoked': boolean;
    /**
     * 分からない場合はfalse
     * @type {boolean}
     * @memberof ApiDetailDiagnoseRequest
     */
    'is_asked_health_check': boolean;
    /**
     * 分からない場合はfalse
     * @type {boolean}
     * @memberof ApiDetailDiagnoseRequest
     */
    'has_house': boolean;
    /**
     * ない場合は0
     * @type {number}
     * @memberof ApiDetailDiagnoseRequest
     */
    'rent': number;
    /**
     * 分からない場合は0
     * @type {number}
     * @memberof ApiDetailDiagnoseRequest
     */
    'partner_yearly_income'?: number;
    /**
     * ない場合は0
     * @type {number}
     * @memberof ApiDetailDiagnoseRequest
     */
    'current_insurance_company_id': number;
    /**
     * 月単位、ない場合は0
     * @type {number}
     * @memberof ApiDetailDiagnoseRequest
     */
    'current_insurance_month_term'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiDetailDiagnoseRequest
     */
    'current_insurance_category': Array<string>;
}
/**
 * 収入保障保険検索レスポンスモデル
 * @export
 * @interface ApiIncomeProtectionInsurancesRankResponse
 */
export interface ApiIncomeProtectionInsurancesRankResponse {
    /**
     * ランキング表ヘッダー文字列
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponse
     */
    'table_header_text'?: string;
    /**
     * 対象合計商品数
     * @type {number}
     * @memberof ApiIncomeProtectionInsurancesRankResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {Array<ApiIncomeProtectionInsurancesRankResponseInsurancesInner>}
     * @memberof ApiIncomeProtectionInsurancesRankResponse
     */
    'insurances'?: Array<ApiIncomeProtectionInsurancesRankResponseInsurancesInner>;
}
/**
 * 
 * @export
 * @interface ApiIncomeProtectionInsurancesRankResponseInsurancesInner
 */
export interface ApiIncomeProtectionInsurancesRankResponseInsurancesInner {
    /**
     * 保険会社名
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'memo1'?: string;
    /**
     * 保険会社名
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'memo2'?: string;
    /**
     * 保険会社名
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'memo3'?: string;
    /**
     * 備考
     * @type {Array<string>}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'fee_memos'?: Array<string>;
    /**
     * 保険会社名
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'company_name'?: string;
    /**
     * 保険商品名
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'insurance_name'?: string;
    /**
     * 保険商品ID
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'insurance_id'?: string;
    /**
     * ネット申し込み
     * @type {boolean}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'is_online_entry'?: boolean;
    /**
     * ネット申し込みor資料請求URL
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'entry_url'?: string;
    /**
     * 商品サムネイルURL
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'thumbnail_url'?: string;
    /**
     * 合計保険料（表上に表示する）
     * @type {number}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'total_insurance_fee'?: number;
    /**
     * 保証金額
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'guarantee_amount'?: string;
    /**
     * 保険期間
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'insurance_period'?: string;
    /**
     * 支払い最低保証期間
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'payment_mini_guarantee_term'?: string;
    /**
     * 
     * @type {ApiIncomeProtectionInsurancesRankResponseInsurancesInnerMonthlyBenefit}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'monthly_benefit'?: ApiIncomeProtectionInsurancesRankResponseInsurancesInnerMonthlyBenefit;
    /**
     * 保険料払込免除有無
     * @type {boolean}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'is_insurance_payment_exemption'?: boolean;
    /**
     * リビングニーズ有無
     * @type {boolean}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'is_living_needs'?: boolean;
    /**
     * 解約返戻金の有無
     * @type {boolean}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'is_cancellation_refund'?: boolean;
    /**
     * 算出条件
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'condition_wording'?: string;
    /**
     * 承認番号
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'approval_number'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'attention_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'file_url'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'insurance_fee_codes'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInner
     */
    'insurance_sub_fee_codes'?: Array<string>;
}
/**
 * 月額給付金
 * @export
 * @interface ApiIncomeProtectionInsurancesRankResponseInsurancesInnerMonthlyBenefit
 */
export interface ApiIncomeProtectionInsurancesRankResponseInsurancesInnerMonthlyBenefit {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInnerMonthlyBenefit
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInnerMonthlyBenefit
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInnerMonthlyBenefit
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesRankResponseInsurancesInnerMonthlyBenefit
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface ApiIncomeProtectionInsurancesResponse
 */
export interface ApiIncomeProtectionInsurancesResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'application_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'is_online_entry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'point_title_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'point_detail_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'point_title_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'point_detail2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'number_of_payment'?: string;
    /**
     * 申込方法
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'entry_method'?: string;
    /**
     * 
     * @type {BasicInfo}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'basic_info'?: BasicInfo;
    /**
     * 
     * @type {ProductInfo4}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'product_info'?: ProductInfo4;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'guard_text1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'guard_text2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'guard_text3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'guard_wording_3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'approval_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'thumbnail_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIncomeProtectionInsurancesResponse
     */
    'memo3'?: string;
}
/**
 * 
 * @export
 * @interface ApiMedicalInsurancesRankResponse
 */
export interface ApiMedicalInsurancesRankResponse {
    /**
     * ランキング表ヘッダー文字列
     * @type {string}
     * @memberof ApiMedicalInsurancesRankResponse
     */
    'table_header_text'?: string;
    /**
     * 対象合計商品数
     * @type {number}
     * @memberof ApiMedicalInsurancesRankResponse
     */
    'total_count'?: number;
    /**
     * 配列の並び順はリクエストで指定した順番 設定されていないデータ群は画面上では、なしとして表示
     * @type {Array<Insurance>}
     * @memberof ApiMedicalInsurancesRankResponse
     */
    'insurances'?: Array<Insurance>;
}
/**
 * 
 * @export
 * @interface ApiMedicalInsurancesResponse
 */
export interface ApiMedicalInsurancesResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'application_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiMedicalInsurancesResponse
     */
    'is_online_entry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'point_title_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'point_detail_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'point_title_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'point_detail2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'number_of_payment'?: string;
    /**
     * 申込方法
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'entry_method'?: string;
    /**
     * 
     * @type {BasicInfo}
     * @memberof ApiMedicalInsurancesResponse
     */
    'basic_info'?: BasicInfo;
    /**
     * 
     * @type {ProductInfo}
     * @memberof ApiMedicalInsurancesResponse
     */
    'product_info'?: ProductInfo;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'guard_text1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'guard_text2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'guard_text3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'approval_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'thumbnail_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMedicalInsurancesResponse
     */
    'memo3'?: string;
}
/**
 * 
 * @export
 * @interface ApiPartnersResponse
 */
export interface ApiPartnersResponse {
    /**
     * 提携先名称
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'main_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'sub_color_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'sub_color_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'sub_color_3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'accent_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'font_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'background_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'main_button_color'?: string;
    /**
     * 結果保存ボタン
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'sub_button_color_1'?: string;
    /**
     * 資料請求ボタン
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'sub_button_color_2'?: string;
    /**
     * FP相談ボタンカラー
     * @type {string}
     * @memberof ApiPartnersResponse
     */
    'sub_button_color_3'?: string;
    /**
     * 資料請求ボタン
     * @type {boolean}
     * @memberof ApiPartnersResponse
     */
    'document_button_disp'?: boolean;
    /**
     * 保存ボタン表示
     * @type {boolean}
     * @memberof ApiPartnersResponse
     */
    'save_button_disp'?: boolean;
    /**
     * FP相談ボタン表示
     * @type {boolean}
     * @memberof ApiPartnersResponse
     */
    'fp_button_disp'?: boolean;
    /**
     * 比較ボタン表示
     * @type {boolean}
     * @memberof ApiPartnersResponse
     */
    'comparison_button_disp'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiSimpleDiagnoseRequest
 */
export interface ApiSimpleDiagnoseRequest {
    /**
     * 提携先コード
     * @type {string}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'partner_code'?: string;
    /**
     * 流入元コード
     * @type {string}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'Inflow_source_code'?: string;
    /**
     * 1：本人、2：夫、3：妻、9：その他
     * @type {number}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'who_assured': number;
    /**
     * 1：男性、2：女性
     * @type {number}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'sex': number;
    /**
     * 
     * @type {number}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'age': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'is_married': boolean;
    /**
     * 子供年齢、子供の数だけ配列で設定
     * @type {Array<ApiSimpleDiagnoseRequestChildrenAgeInner>}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'children_age'?: Array<ApiSimpleDiagnoseRequestChildrenAgeInner>;
    /**
     * 年収
     * @type {number}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'yearly_income'?: number;
    /**
     * 月の出費
     * @type {number}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'monthly_expense'?: number;
    /**
     * 回答スキップ時は-1
     * @type {number}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'current_monthly_insurance_fee'?: number;
    /**
     * 分からない場合はfalse
     * @type {boolean}
     * @memberof ApiSimpleDiagnoseRequest
     */
    'has_three_major_diseases'?: boolean;
}
/**
 * @type ApiSimpleDiagnoseRequestChildrenAgeInner
 * @export
 */
export type ApiSimpleDiagnoseRequestChildrenAgeInner = any;

/**
 * 死亡保険（定期）検索レスポンスモデル
 * @export
 * @interface ApiTermDeathInsurancesRankResponse
 */
export interface ApiTermDeathInsurancesRankResponse {
    /**
     * ランキング表ヘッダー文字列
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponse
     */
    'table_header_text'?: string;
    /**
     * 対象合計商品数
     * @type {number}
     * @memberof ApiTermDeathInsurancesRankResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {Array<ApiTermDeathInsurancesRankResponseInsurancesInner>}
     * @memberof ApiTermDeathInsurancesRankResponse
     */
    'insurances'?: Array<ApiTermDeathInsurancesRankResponseInsurancesInner>;
}
/**
 * 
 * @export
 * @interface ApiTermDeathInsurancesRankResponseInsurancesInner
 */
export interface ApiTermDeathInsurancesRankResponseInsurancesInner {
    /**
     * 保険会社名
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'company_name'?: string;
    /**
     * 保険商品名
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'insurance_name'?: string;
    /**
     * 保険商品ID
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'insurance_id'?: string;
    /**
     * ネット申し込み
     * @type {boolean}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'is_online_entry'?: boolean;
    /**
     * ネット申し込みor資料請求URL
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'entry_url'?: string;
    /**
     * 商品サムネイルURL
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'thumbnail_url'?: string;
    /**
     * 合計保険料（表上に表示する）
     * @type {number}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'total_insurance_fee'?: number;
    /**
     * 保証金額
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'guarantee_amount'?: string;
    /**
     * 保険期間
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'insurance_period'?: string;
    /**
     * 払込期間
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'payment_period'?: string;
    /**
     * 
     * @type {ApiTermDeathInsurancesRankResponseInsurancesInnerDeathBenefit}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'death_benefit'?: ApiTermDeathInsurancesRankResponseInsurancesInnerDeathBenefit;
    /**
     * 保険料払込免除有無
     * @type {boolean}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'is_insurance_payment_exemption'?: boolean;
    /**
     * リビングニーズ有無
     * @type {boolean}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'is_living_needs'?: boolean;
    /**
     * 解約返戻金の有無
     * @type {boolean}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'is_cancellation_refund'?: boolean;
    /**
     * 算出条件
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'condition_wording'?: string;
    /**
     * 承認番号
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'approval_number'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'attention_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'file_url'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'insurance_fee_codes'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'insurance_sub_fee_codes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'memo3'?: string;
    /**
     * 備考
     * @type {Array<string>}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInner
     */
    'fee_memos'?: Array<string>;
}
/**
 * 死亡保証
 * @export
 * @interface ApiTermDeathInsurancesRankResponseInsurancesInnerDeathBenefit
 */
export interface ApiTermDeathInsurancesRankResponseInsurancesInnerDeathBenefit {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInnerDeathBenefit
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInnerDeathBenefit
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInnerDeathBenefit
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiTermDeathInsurancesRankResponseInsurancesInnerDeathBenefit
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface ApiTermDeathInsurancesResponse
 */
export interface ApiTermDeathInsurancesResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'application_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'is_online_entry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'point_title_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'point_detail_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'point_title_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'point_detail2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'number_of_payment'?: string;
    /**
     * 申込方法
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'entry_method'?: string;
    /**
     * 
     * @type {BasicInfo}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'basic_info'?: BasicInfo;
    /**
     * 
     * @type {ProductInfo3}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'product_info'?: ProductInfo3;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'guard_text1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'guard_text2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'guard_text3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'guard_wording_3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'approval_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTermDeathInsurancesResponse
     */
    'thumbnail_url'?: string;
}
/**
 * 死亡保険（終身）検索レスポンスモデル
 * @export
 * @interface ApiWholeDeathInsurancesRankResponse
 */
export interface ApiWholeDeathInsurancesRankResponse {
    /**
     * ランキング表ヘッダー文字列
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponse
     */
    'table_header_text'?: string;
    /**
     * 対象合計商品数
     * @type {number}
     * @memberof ApiWholeDeathInsurancesRankResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {Array<ApiWholeDeathInsurancesRankResponseInsurancesInner>}
     * @memberof ApiWholeDeathInsurancesRankResponse
     */
    'insurances'?: Array<ApiWholeDeathInsurancesRankResponseInsurancesInner>;
}
/**
 * 
 * @export
 * @interface ApiWholeDeathInsurancesRankResponseInsurancesInner
 */
export interface ApiWholeDeathInsurancesRankResponseInsurancesInner {
    /**
     * 保険会社名
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'memo1'?: string;
    /**
     * 保険会社名
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'memo2'?: string;
    /**
     * 保険会社名
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'memo3'?: string;
    /**
     * 備考
     * @type {Array<string>}
     * @memberof ApiCancerInsurancesRankResponseInsurancesInner
     */
    'fee_memos'?: Array<string>;
    /**
     * 保険会社名
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'company_name'?: string;
    /**
     * 保険商品名
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'insurance_name'?: string;
    /**
     * 保険商品ID
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'insurance_id'?: string;
    /**
     * ネット申し込み
     * @type {boolean}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'is_online_entry'?: boolean;
    /**
     * ネット申し込みor資料請求URL
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'entry_url'?: string;
    /**
     * 商品サムネイルURL
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'thumbnail_url'?: string;
    /**
     * 合計保険料（表上に表示する）
     * @type {number}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'total_insurance_fee'?: number;
    /**
     * 保証金額
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'guarantee_amount'?: string;
    /**
     * 保証期間
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'insurance_period'?: string;
    /**
     * 
     * @type {ApiWholeDeathInsurancesRankResponseInsurancesInnerDeathBenefit}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'death_benefit'?: ApiWholeDeathInsurancesRankResponseInsurancesInnerDeathBenefit;
    /**
     * 保険料払込免除有無
     * @type {boolean}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'is_insurance_payment_exemption'?: boolean;
    /**
     * リビングニーズ有無
     * @type {boolean}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'is_living_needs'?: boolean;
    /**
     * 解約返戻金の有無
     * @type {boolean}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'is_cancellation_refund'?: boolean;
    /**
     * 算出条件
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'condition_wording'?: string;
    /**
     * 承認番号
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'approval_number'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'attention_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'file_url'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'insurance_fee_codes'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInner
     */
    'insurance_sub_fee_codes'?: Array<string>;
}
/**
 * 死亡保障
 * @export
 * @interface ApiWholeDeathInsurancesRankResponseInsurancesInnerDeathBenefit
 */
export interface ApiWholeDeathInsurancesRankResponseInsurancesInnerDeathBenefit {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInnerDeathBenefit
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInnerDeathBenefit
     */
    'guarantee_amount_text'?: string;
    /**
     * 保険料
     * @type {number}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInnerDeathBenefit
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof ApiWholeDeathInsurancesRankResponseInsurancesInnerDeathBenefit
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface ApiWholeDeathInsurancesResponse
 */
export interface ApiWholeDeathInsurancesResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'application_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'is_online_entry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'point_title_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'point_detail_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'point_title_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'point_detail2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'number_of_payment'?: string;
    /**
     * 申込方法
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'entry_method'?: string;
    /**
     * 
     * @type {BasicInfo}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'basic_info'?: BasicInfo;
    /**
     * 
     * @type {ProductInfo2}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'product_info'?: ProductInfo2;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'guard_text1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'guard_text2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'guard_text3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'guard_wording_3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'approval_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWholeDeathInsurancesResponse
     */
    'thumbnail_url'?: string;
}
/**
 * 
 * @export
 * @interface BasicInfo
 */
export interface BasicInfo {
    /**
     * 
     * @type {string}
     * @memberof BasicInfo
     */
    'official_company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicInfo
     */
    'formal_insurance_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicInfo
     */
    'joining_age'?: string;
    /**
     * 保険期間
     * @type {string}
     * @memberof BasicInfo
     */
    'insurance_period'?: string;
    /**
     * 払込期間
     * @type {string}
     * @memberof BasicInfo
     */
    'payment_period'?: string;
}
/**
 * 
 * @export
 * @interface BigEvent
 */
export interface BigEvent {
    /**
     * 
     * @type {string}
     * @memberof BigEvent
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof BigEvent
     */
    'value'?: string;
}
/**
 * がん特約データ群
 * @export
 * @interface CancerSpecialContract
 */
export interface CancerSpecialContract {
    /**
     * 
     * @type {boolean}
     * @memberof CancerSpecialContract
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CancerSpecialContract
     */
    'guarantee_amount_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof CancerSpecialContract
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof CancerSpecialContract
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 通院日額データ群
 * @export
 * @interface CommutingDaily
 */
export interface CommutingDaily {
    /**
     * 
     * @type {boolean}
     * @memberof CommutingDaily
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommutingDaily
     */
    'guarantee_amount_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommutingDaily
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommutingDaily
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommutingDaily
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 通院一時金データ群
 * @export
 * @interface CommutingOnce
 */
export interface CommutingOnce {
    /**
     * 
     * @type {boolean}
     * @memberof CommutingOnce
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommutingOnce
     */
    'guarantee_amount_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommutingOnce
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommutingOnce
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface CompensationByAge
 */
export interface CompensationByAge {
    /**
     * 
     * @type {string}
     * @memberof CompensationByAge
     */
    'age'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationByAge
     */
    'value'?: string;
}
/**
 * 健康ボーナス（祝い金）データ群
 * @export
 * @interface CongratulatoryMoney
 */
export interface CongratulatoryMoney {
    /**
     * 
     * @type {boolean}
     * @memberof CongratulatoryMoney
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CongratulatoryMoney
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof CongratulatoryMoney
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 死亡特約データ群
 * @export
 * @interface DeathSpecialContract
 */
export interface DeathSpecialContract {
    /**
     * 
     * @type {boolean}
     * @memberof DeathSpecialContract
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeathSpecialContract
     */
    'guarantee_amount_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeathSpecialContract
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeathSpecialContract
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface Diagnos
 */
export interface Diagnos {
    /**
     * 診断コード
     * @type {string}
     * @memberof Diagnos
     */
    'diagnos_code'?: string;
    /**
     * 表示パターン（1-4）
     * @type {number}
     * @memberof Diagnos
     */
    'disp_type'?: number;
    /**
     * 
     * @type {number}
     * @memberof Diagnos
     */
    'age'?: number;
    /**
     * 年齢ごとの必要保証額
     * @type {Array<CompensationByAge>}
     * @memberof Diagnos
     */
    'compensation_by_age'?: Array<CompensationByAge>;
    /**
     * イベントテキスト
     * @type {Array<BigEvent>}
     * @memberof Diagnos
     */
    'big_events'?: Array<BigEvent>;
    /**
     * 現在の年間保険料
     * @type {number}
     * @memberof Diagnos
     */
    'current_yearly_insurance_fee'?: number;
    /**
     * 見直し後の年間保険料
     * @type {number}
     * @memberof Diagnos
     */
    'review_yearly_insurance_fee'?: number;
    /**
     * 節約金額
     * @type {number}
     * @memberof Diagnos
     */
    'saved_money'?: number;
    /**
     * 年代における平均保険料
     * @type {number}
     * @memberof Diagnos
     */
    'average_insurance_fee_generation'?: number;
    /**
     * 
     * @type {Array<EstimateInsurance>}
     * @memberof Diagnos
     */
    'recommended_insurance_items'?: Array<EstimateInsurance>;
    /**
     * 対象者　1：本人、2：旦那、3：妻、以外：そのほか
     * @type {number}
     * @memberof Diagnos
     */
    'who_assured'?: number;
}
/**
 * 見積保険情報
 * @export
 * @interface EstimateInsurance
 */
export interface EstimateInsurance {
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance
     */
    'memo3'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EstimateInsurance
     */
    'fee_memos'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance
     */
    'insurance_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance
     */
    'insurance_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof EstimateInsurance
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance
     */
    'insurance_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance
     */
    'insurance_detail_link'?: string;
    /**
     * 保証期間
     * @type {string}
     * @memberof EstimateInsurance
     */
    'warranty_term'?: string;
    /**
     * 支払期間
     * @type {string}
     * @memberof EstimateInsurance
     */
    'payment_term'?: string;
    /**
     * 保険料金レコードのID
     * @type {Array<string>}
     * @memberof EstimateInsurance
     */
    'insurance_fee_codes'?: Array<string>;
    /**
     * 保険料金レコードのID
     * @type {Array<string>}
     * @memberof EstimateInsurance
     */
    'insurance_sub_fee_codes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance
     */
    'thumbnail_url'?: string;
    /**
     * 保証金額
     * @type {string}
     * @memberof EstimateInsurance
     */
    'guarantee_amount'?: string;
    /**
     * 保険種類k番号
     * @type {number}
     * @memberof EstimateInsurance
     */
    'insurance_type'?: number;
    /**
     * 保障額加工なし
     * @type {number}
     * @memberof EstimateInsurance
     */
    'guarantee_amount_plane'?: number;
    /**
     * ネット申し込み可能か
     * @type {boolean}
     * @memberof EstimateInsurance
     */
    'is_online_entry'?: boolean;
    /**
     * 申し込みURL
     * @type {string}
     * @memberof EstimateInsurance
     */
    'entry_url'?: string;
    /**
     * 検索条件
     * @type {number}
     * @memberof EstimateInsurance
     */
    'condition1'?: number;
    /**
     * ネット申し込み可能か
     * @type {boolean}
     * @memberof EstimateInsurance
     */
    'is_disp'?: boolean;
    /**
     * 承認番号(診断結果)
     * @type {string}
     * @memberof EstimateInsurance
     */
    'approval_number'?: string;
    /**
     * 承認番号(詳細画面)
     * @type {string}
     * @memberof EstimateInsurance
     */
    'approval_number_detail'?: string;
}
/**
 * 
 * @export
 * @interface EstimateInsurance1
 */
export interface EstimateInsurance1 {
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance1
     */
    'insurance_code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EstimateInsurance1
     */
    'insurance_fee_codes'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EstimateInsurance1
     */
    'insurance_sub_fee_codes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EstimateInsurance2
 */
export interface EstimateInsurance2 {
    /**
     * 
     * @type {string}
     * @memberof EstimateInsurance2
     */
    'insurance_code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EstimateInsurance2
     */
    'insurance_fee_codes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetApiCancerInsurancesInsuranceCode200Response
 */
export interface GetApiCancerInsurancesInsuranceCode200Response {
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'application_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'is_online_entry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'point_title_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'point_detail_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'point_title_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'point_detail2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'number_of_payment'?: string;
    /**
     * 申込方法
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'entry_method'?: string;
    /**
     * 
     * @type {BasicInfo}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'basic_info'?: BasicInfo;
    /**
     * 
     * @type {ProductInfo1}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'product_info'?: ProductInfo1;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'guard_text1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'guard_text2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'guard_text3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'approval_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'thumbnail_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'file_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'memo3'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetApiCancerInsurancesInsuranceCode200Response
     */
    'fee_memos'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetApiIncomeProtectionInsurancesInsuranceCode200Response
 */
export interface GetApiIncomeProtectionInsurancesInsuranceCode200Response {
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'application_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'is_online_entry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'point_title_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'point_detail_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'point_title_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'point_detail2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'number_of_payment'?: string;
    /**
     * 申込方法
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'entry_method'?: string;
    /**
     * 
     * @type {BasicInfo}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'basic_info'?: BasicInfo;
    /**
     * 
     * @type {ProductInfo4}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'product_info'?: ProductInfo4;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'guard_text1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'guard_text2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'guard_text3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'guard_wording_3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'approval_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'thumbnail_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'file_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'memo3'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetApiIncomeProtectionInsurancesInsuranceCode200Response
     */
    'fee_memos'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetApiInsuranceCompanies200ResponseInner
 */
export interface GetApiInsuranceCompanies200ResponseInner {
    /**
     * 保険会社ID
     * @type {number}
     * @memberof GetApiInsuranceCompanies200ResponseInner
     */
    'company_id'?: number;
    /**
     * 保険会社名
     * @type {string}
     * @memberof GetApiInsuranceCompanies200ResponseInner
     */
    'company_name'?: string;
}
/**
 * 
 * @export
 * @interface GetApiInsurancesInsuranceCode200Response
 */
export interface GetApiInsurancesInsuranceCode200Response {
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'application_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'is_online_entry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'point_title_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'point_detail_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'point_title_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'point_detail2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'number_of_payment'?: string;
    /**
     * 申込方法
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'entry_method'?: string;
    /**
     * 
     * @type {BasicInfo}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'basic_info'?: BasicInfo;
    /**
     * 
     * @type {ProductInfo}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'product_info'?: ProductInfo;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'guard_text1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'guard_text2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'guard_text3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'approval_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'thumbnail_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'file_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'memo3'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetApiInsurancesInsuranceCode200Response
     */
    'fee_memos'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetApiMedicalInsuranceRank200Response
 */
export interface GetApiMedicalInsuranceRank200Response {
    /**
     * ランキング表ヘッダー文字列
     * @type {string}
     * @memberof GetApiMedicalInsuranceRank200Response
     */
    'table_header_text'?: string;
    /**
     * 対象合計商品数
     * @type {number}
     * @memberof GetApiMedicalInsuranceRank200Response
     */
    'total_count'?: number;
    /**
     * 配列の並び順はリクエストで指定した順番 設定されていないデータ群は画面上では、なしとして表示
     * @type {Array<Insurance>}
     * @memberof GetApiMedicalInsuranceRank200Response
     */
    'insurances'?: Array<Insurance>;
}
/**
 * 
 * @export
 * @interface GetApiPartnersPartnerCode200Response
 */
export interface GetApiPartnersPartnerCode200Response {
    /**
     * 提携先名称
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'main_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'sub_color_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'sub_color_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'sub_color_3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'accent_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'font_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'background_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'main_button_color'?: string;
    /**
     * 結果保存ボタン
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'sub_button_color_1'?: string;
    /**
     * 資料請求ボタン
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'sub_button_color_2'?: string;
    /**
     * FP相談ボタンカラー
     * @type {string}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'sub_button_color_3'?: string;
    /**
     * 資料請求ボタン
     * @type {boolean}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'document_button_disp'?: boolean;
    /**
     * 保存ボタン表示
     * @type {boolean}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'save_button_disp'?: boolean;
    /**
     * FP相談ボタン表示
     * @type {boolean}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'fp_button_disp'?: boolean;
    /**
     * 比較ボタン表示
     * @type {boolean}
     * @memberof GetApiPartnersPartnerCode200Response
     */
    'comparison_button_disp'?: boolean;
}
/**
 * 
 * @export
 * @interface GetApiTermDeathInsurancesInsuranceCode200Response
 */
export interface GetApiTermDeathInsurancesInsuranceCode200Response {
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'application_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'is_online_entry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'point_title_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'point_detail_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'point_title_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'point_detail2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'number_of_payment'?: string;
    /**
     * 申込方法
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'entry_method'?: string;
    /**
     * 
     * @type {BasicInfo}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'basic_info'?: BasicInfo;
    /**
     * 
     * @type {ProductInfo3}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'product_info'?: ProductInfo3;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'guard_text1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'guard_text2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'guard_text3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'guard_wording_3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'approval_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'thumbnail_url'?: string;
        /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'file_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'memo3'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetApiTermDeathInsurancesInsuranceCode200Response
     */
    'fee_memos'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetApiWholeDeathInsurancesInsuranceCode200Response
 */
export interface GetApiWholeDeathInsurancesInsuranceCode200Response {
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'application_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'is_online_entry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'point_title_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'point_detail_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'point_title_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'point_detail2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'number_of_payment'?: string;
    /**
     * 申込方法
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'entry_method'?: string;
    /**
     * 
     * @type {BasicInfo}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'basic_info'?: BasicInfo;
    /**
     * 
     * @type {ProductInfo2}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'product_info'?: ProductInfo2;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'guard_text1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'guard_text2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'guard_text3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'guard_wording_3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'approval_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'thumbnail_url'?: string;
        /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'file_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'memo1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'memo2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'memo3'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetApiWholeDeathInsurancesInsuranceCode200Response
     */
    'fee_memos'?: Array<string>;
}
/**
 * 入院保障データ群
 * @export
 * @interface HospitalizationGuarantee
 */
export interface HospitalizationGuarantee {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof HospitalizationGuarantee
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof HospitalizationGuarantee
     */
    'guarantee_amount_text'?: string;
    /**
     * 日数
     * @type {number}
     * @memberof HospitalizationGuarantee
     */
    'days'?: number;
    /**
     * 保険料
     * @type {number}
     * @memberof HospitalizationGuarantee
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof HospitalizationGuarantee
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 入院一時金データ群
 * @export
 * @interface HospitalizationOnce
 */
export interface HospitalizationOnce {
    /**
     * 
     * @type {boolean}
     * @memberof HospitalizationOnce
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HospitalizationOnce
     */
    'guarantee_amount_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof HospitalizationOnce
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof HospitalizationOnce
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 医療保険検索用
 * @export
 * @interface Insurance
 */
export interface Insurance {
    /**
     * 保険会社名
     * @type {string}
     * @memberof Insurance
     */
    'company_name'?: string;
    /**
     * 保険商品名
     * @type {string}
     * @memberof Insurance
     */
    'insurance_name'?: string;
    /**
     * 保険商品ID
     * @type {string}
     * @memberof Insurance
     */
    'insurance_id'?: string;
    /**
     * ねっともうしこみ
     * @type {boolean}
     * @memberof Insurance
     */
    'is_online_entry'?: boolean;
    /**
     * ネット申し込みor資料請求URL
     * @type {string}
     * @memberof Insurance
     */
    'entry_url'?: string;
    /**
     * 商品サムネイルURL
     * @type {string}
     * @memberof Insurance
     */
    'thumbnail_url'?: string;
    /**
     * 合計保険料（表上に表示する）
     * @type {number}
     * @memberof Insurance
     */
    'total_insurance_fee'?: number;
    /**
     * 保証金額
     * @type {string}
     * @memberof Insurance
     */
    'guarantee_amount'?: string;
    /**
     * 保証期間
     * @type {string}
     * @memberof Insurance
     */
    'insurance_period'?: string;
    
    /**
     * 保険商品ID(2)
     * @type {string}
     * @memberof Insurance
     */
    'insurance_code'?: string;
    /**
     * 商品詳細リンク
     * @type {string}
     * @memberof Insurance
     */
    'insurance_detail_link'?: string;
    /**
     * 商品種別名
     * @type {string}
     * @memberof Insurance
     */
    'insurance_category'?: string;
    /**
     * 合計保険料（表上に表示する）
     * @type {string}
     * @memberof Insurance
     */
    'insurance_fee'?: number;
    
    /**
     * 
     * @type {InsuranceHospitalizationGuarantee}
     * @memberof Insurance
     */
    'hospitalization_guarantee'?: InsuranceHospitalizationGuarantee;
    /**
     * 
     * @type {InsuranceHospitalizationOnce}
     * @memberof Insurance
     */
    'hospitalization_once'?: InsuranceHospitalizationOnce;
    /**
     * 
     * @type {InsuranceSurgeryGuarantee}
     * @memberof Insurance
     */
    'surgery_guarantee'?: InsuranceSurgeryGuarantee;
    /**
     * 
     * @type {InsuranceCommutingDaily}
     * @memberof Insurance
     */
    'commuting_daily'?: InsuranceCommutingDaily;
    /**
     * 
     * @type {InsuranceCommutingOnce}
     * @memberof Insurance
     */
    'commuting_once'?: InsuranceCommutingOnce;
    /**
     * 
     * @type {InsuranceAdvancedMedical}
     * @memberof Insurance
     */
    'advanced_medical'?: InsuranceAdvancedMedical;
    /**
     * 
     * @type {InsuranceCancerSpecialContract}
     * @memberof Insurance
     */
    'cancer_special_contract'?: InsuranceCancerSpecialContract;
    /**
     * 
     * @type {InsuranceSpecifiedDisease}
     * @memberof Insurance
     */
    'specified_disease'?: InsuranceSpecifiedDisease;
    /**
     * 
     * @type {InsuranceDeathSpecialContract}
     * @memberof Insurance
     */
    'death_special_contract'?: InsuranceDeathSpecialContract;
    /**
     * 
     * @type {InsuranceCongratulatoryMoney}
     * @memberof Insurance
     */
    'congratulatory_money'?: InsuranceCongratulatoryMoney;
    /**
     * 保険料払込免除有無
     * @type {boolean}
     * @memberof Insurance
     */
    'is_insurance_payment_exemption'?: boolean;
    /**
     * 算出条件
     * @type {string}
     * @memberof Insurance
     */
    'condition_wording'?: string;
    /**
     * 承認番号
     * @type {string}
     * @memberof Insurance
     */
    'approval_number'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof Insurance
     */
    'attention_text'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Insurance
     */
    'insurance_fee_codes'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Insurance
     */
    'insurance_sub_fee_codes'?: Array<string>;
    /**
     * 備考
     * @type {string}
     * @memberof Insurance
     */
    'memo1'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof Insurance
     */
    'memo2'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof Insurance
     */
    'memo3'?: string;
    /**
     * 備考
     * @type {Array<string>}
     * @memberof Insurance
     */
    'fee_memos'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InsuranceAdvancedMedical
 */
export interface InsuranceAdvancedMedical {
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceAdvancedMedical
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InsuranceAdvancedMedical
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceAdvancedMedical
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface InsuranceCancerSpecialContract
 */
export interface InsuranceCancerSpecialContract {
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceCancerSpecialContract
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCancerSpecialContract
     */
    'guarantee_amount_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceCancerSpecialContract
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCancerSpecialContract
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface InsuranceCommutingDaily
 */
export interface InsuranceCommutingDaily {
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceCommutingDaily
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCommutingDaily
     */
    'guarantee_amount_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceCommutingDaily
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceCommutingDaily
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCommutingDaily
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface InsuranceCommutingOnce
 */
export interface InsuranceCommutingOnce {
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceCommutingOnce
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCommutingOnce
     */
    'guarantee_amount_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceCommutingOnce
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCommutingOnce
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface InsuranceCongratulatoryMoney
 */
export interface InsuranceCongratulatoryMoney {
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceCongratulatoryMoney
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InsuranceCongratulatoryMoney
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCongratulatoryMoney
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface InsuranceDeathSpecialContract
 */
export interface InsuranceDeathSpecialContract {
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceDeathSpecialContract
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsuranceDeathSpecialContract
     */
    'guarantee_amount_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceDeathSpecialContract
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceDeathSpecialContract
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface InsuranceHospitalizationGuarantee
 */
export interface InsuranceHospitalizationGuarantee {
    /**
     * 主契約か
     * @type {boolean}
     * @memberof InsuranceHospitalizationGuarantee
     */
    'is_main_contract'?: boolean;
    /**
     * 保証額
     * @type {string}
     * @memberof InsuranceHospitalizationGuarantee
     */
    'guarantee_amount_text'?: string;
    /**
     * 日数
     * @type {number}
     * @memberof InsuranceHospitalizationGuarantee
     */
    'days'?: number;
    /**
     * 保険料
     * @type {number}
     * @memberof InsuranceHospitalizationGuarantee
     */
    'insurance_fee'?: number;
    /**
     * 料金ユニークコード（見積登録時に使用）
     * @type {string}
     * @memberof InsuranceHospitalizationGuarantee
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface InsuranceHospitalizationOnce
 */
export interface InsuranceHospitalizationOnce {
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceHospitalizationOnce
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsuranceHospitalizationOnce
     */
    'guarantee_amount_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceHospitalizationOnce
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceHospitalizationOnce
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface InsuranceSpecifiedDisease
 */
export interface InsuranceSpecifiedDisease {
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceSpecifiedDisease
     */
    'is_main_contract'?: boolean;
    /**
     * 表内に表示する文言
     * @type {string}
     * @memberof InsuranceSpecifiedDisease
     */
    'disp_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceSpecifiedDisease
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceSpecifiedDisease
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface InsuranceSurgeryGuarantee
 */
export interface InsuranceSurgeryGuarantee {
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceSurgeryGuarantee
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InsuranceSurgeryGuarantee
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceSurgeryGuarantee
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface PostApiContactDocumentRequest200Response
 */
export interface PostApiContactDocumentRequest200Response {
    /**
     * 
     * @type {boolean}
     * @memberof PostApiContactDocumentRequest200Response
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactDocumentRequest200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PostApiContactDocumentRequestRequest
 */
export interface PostApiContactDocumentRequestRequest {
    /**
     * 診断時の問い合わせのみ設定
     * @type {string}
     * @memberof PostApiContactDocumentRequestRequest
     */
    'diagnos_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactDocumentRequestRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactDocumentRequestRequest
     */
    'name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactDocumentRequestRequest
     */
    'tel': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostApiContactDocumentRequestRequest
     */
    'candidate_day_of_week': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostApiContactDocumentRequestRequest
     */
    'candidate_time_zone': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactDocumentRequestRequest
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof PostApiContactDocumentRequestRequest
     */
    'from_comparison': boolean;
    /**
     * 
     * @type {Array<EstimateInsurance2>}
     * @memberof PostApiContactDocumentRequestRequest
     */
    'estimate_insurances'?: Array<EstimateInsurance2>;
}
/**
 * 
 * @export
 * @interface PostApiContactEmail200Response
 */
export interface PostApiContactEmail200Response {
    /**
     * 
     * @type {boolean}
     * @memberof PostApiContactEmail200Response
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactEmail200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PostApiContactEmailRequest
 */
export interface PostApiContactEmailRequest {
    /**
     * 診断時の問い合わせのみ設定
     * @type {string}
     * @memberof PostApiContactEmailRequest
     */
    'diagnos_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactEmailRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactEmailRequest
     */
    'name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactEmailRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactEmailRequest
     */
    'tel': string;
    /**
     * 
     * @type {boolean}
     * @memberof PostApiContactEmailRequest
     */
    'from_comparison': boolean;
    /**
     * 
     * @type {Array<EstimateInsurance2>}
     * @memberof PostApiContactEmailRequest
     */
    'estimate_insurances'?: Array<EstimateInsurance2>;
}
/**
 * 
 * @export
 * @interface PostApiContactFp200Response
 */
export interface PostApiContactFp200Response {
    /**
     * 
     * @type {boolean}
     * @memberof PostApiContactFp200Response
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactFp200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PostApiAfterContactFpRequest
 */
export interface PostApiAfterContactFpRequest {
    /**
     * 診断時または、診断経由の比較から問い合わせ時のみ設定
     * @type {string}
     * @memberof PostApiAfterContactFpRequest
     */
    'diagnos_code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostApiAfterContactFpRequest
     */
    'canchecksdata': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostApiAfterContactFpRequest
     */
    'canradiodatas': Array<string>;
}
/**
 * 
 * @export
 * @interface PostApiContactFpRequest
 */
export interface PostApiContactFpRequest {
    /**
     * 診断時または、診断経由の比較から問い合わせ時のみ設定
     * @type {string}
     * @memberof PostApiContactFpRequest
     */
    'diagnos_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactFpRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactFpRequest
     */
    'name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactFpRequest
     */
    'tel': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostApiContactFpRequest
     */
    'candidate_day_of_week': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostApiContactFpRequest
     */
    'candidate_time_zone': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactFpRequest
     */
    'email': string;
    /**
     * 比較からの呼び出しか？
     * @type {boolean}
     * @memberof PostApiContactFpRequest
     */
    'from_comparison': boolean;
    /**
     * 比較からの呼び出し時のみ必須
     * @type {Array<EstimateInsurance1>}
     * @memberof PostApiContactFpRequest
     */
    'estimate_insurances'?: Array<EstimateInsurance1>;
    /**
     * 備考
     * @type {string}
     * @memberof PostApiContactFpRequest
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface PostApiContactSms200Response
 */
export interface PostApiContactSms200Response {
    /**
     * 
     * @type {boolean}
     * @memberof PostApiContactSms200Response
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactSms200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PostApiContactSmsRequest
 */
export interface PostApiContactSmsRequest {
    /**
     * 
     * @type {string}
     * @memberof PostApiContactSmsRequest
     */
    'diagnos_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactSmsRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactSmsRequest
     */
    'name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactSmsRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostApiContactSmsRequest
     */
    'tel': string;
    /**
     * 
     * @type {boolean}
     * @memberof PostApiContactSmsRequest
     */
    'from_comparison': boolean;
    /**
     * 
     * @type {Array<EstimateInsurance1>}
     * @memberof PostApiContactSmsRequest
     */
    'estimate_insurances'?: Array<EstimateInsurance1>;
}
/**
 * 
 * @export
 * @interface PostApiSimpleDiagnose200Response
 */
export interface PostApiSimpleDiagnose200Response {
    /**
     * 診断コード
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'diagnos_code'?: string;
    /**
     * 表示パターン（1-4）
     * @type {number}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'disp_type'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'age'?: number;
     /**
     * 
     * @type {number}
     * @memberof PostApiSimpleDiagnose200Response
     */
     'sex'?: number;
    /**
     * 年齢ごとの必要保証額
     * @type {Array<CompensationByAge>}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'compensation_by_age'?: Array<CompensationByAge>;
    /**
     * イベントテキスト
     * @type {Array<BigEvent>}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'big_events'?: Array<BigEvent>;
    /**
     * 現在の年間保険料
     * @type {number}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'current_yearly_insurance_fee'?: number;
    /**
     * 見直し後の年間保険料
     * @type {number}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'review_yearly_insurance_fee'?: number;
    /**
     * 節約金額
     * @type {number}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'saved_money'?: number;
    /**
     * 年代における平均保険料
     * @type {number}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'average_insurance_fee_generation'?: number;
    /**
     * 必要保証額
     * @type {number}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'monthly_compensation'?: number;
    /**
     * 
     * @type {Array<EstimateInsurance>}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'recommended_insurance_items'?: Array<EstimateInsurance>;
    /**
     * 対象者　1：本人、2：旦那、3：妻、以外：そのほか
     * @type {number}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'who_assured'?: number;
    /**
     * パートナーコード
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_code'?: string;
    /**
     * パートナーコード（main_color）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_main_color'?: string;
    /**
     * パートナーコード（sub_color_1）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_sub_color_1'?: string;
    /**
     * パートナーコード（sub_color_2）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_sub_color_2'?: string;
    /**
     * パートナーコード（sub_color_3）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_sub_color_3'?: string;
    /**
     * パートナーコード（font_color）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_font_color'?: string;
    /**
     * パートナーコード（accent_color）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_accent_color'?: string;
    /**
     * パートナーコード（background_color）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_background_color'?: string;
    /**
     * パートナーコード（main_button_color）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_main_button_color'?: string;
    /**
     * パートナーコード（sub_button_color_1）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_sub_button_color_1'?: string;
    /**
     * パートナーコード（sub_button_color_2）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_sub_button_color_2'?: string;
    /**
     * パートナーコード（sub_button_color_3）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_sub_button_color_3'?: string;
    /**
     * パートナーコード（save_button_disp）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_save_button_disp'?: boolean;
    /**
     * パートナーコード（fp_button_disp）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_fp_button_disp'?: boolean;
    /**
     * パートナーコード（comparison_button_disp）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'partner_comparison_button_disp'?: boolean;
    /**
     * 承認番号（配列）
     * @type {string}
     * @memberof PostApiSimpleDiagnose200Response
     */
    'approval_numbers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProductInfo
 */
export interface ProductInfo {
    /**
     * 入院日額給付金
     * @type {string}
     * @memberof ProductInfo
     */
    'hospitalization_guarantee'?: string;
    /**
     * 入院日数（1入院あたり）
     * @type {string}
     * @memberof ProductInfo
     */
    'hospitalization_guarantee_days'?: string;
    /**
     * 入院一時金
     * @type {string}
     * @memberof ProductInfo
     */
    'hospitalization_once'?: string;
    /**
     * 手術給付金
     * @type {string}
     * @memberof ProductInfo
     */
    'surgery_guarantee'?: string;
    /**
     * 通院日額給付金
     * @type {string}
     * @memberof ProductInfo
     */
    'commuting_daily_amount'?: string;
    /**
     * 通院日数（1通院あたり）
     * @type {string}
     * @memberof ProductInfo
     */
    'commuting_daily_amount_days'?: string;
    /**
     * 通院一時金
     * @type {string}
     * @memberof ProductInfo
     */
    'commuting_once'?: string;
    /**
     * 先進医療給付金
     * @type {string}
     * @memberof ProductInfo
     */
    'advanced_medical'?: string;
    /**
     * 先進医療一時金
     * @type {string}
     * @memberof ProductInfo
     */
    'advanced_medical_once'?: string;
    /**
     * がん特約
     * @type {string}
     * @memberof ProductInfo
     */
    'cancer_special_contract'?: string;
    /**
     * 死亡特約
     * @type {string}
     * @memberof ProductInfo
     */
    'death_special_contract'?: string;
    /**
     * 女性疾病
     * @type {string}
     * @memberof ProductInfo
     */
    'female_disease'?: string;
    /**
     * 契約者サービス
     * @type {string}
     * @memberof ProductInfo
     */
    'contractor_service'?: string;
    /**
     * その他
     * @type {string}
     * @memberof ProductInfo
     */
    'other_topic'?: string;
}
/**
 * 
 * @export
 * @interface ProductInfo1
 */
export interface ProductInfo1 {
    /**
     * 悪性新生物診断給付金（一時金）
     * @type {string}
     * @memberof ProductInfo1
     */
    'malignant_diagnostic_once'?: string;
    /**
     * 悪性新生物診断給付金（治療給付金）
     * @type {string}
     * @memberof ProductInfo1
     */
    'malignant_diagnostic_treatment'?: string;
    /**
     * 上皮内新生物診断給付金（一時金）
     * @type {string}
     * @memberof ProductInfo1
     */
    'intraepithelial_diagnostic_once'?: string;
    /**
     * 上皮内新生物診断給付金（治療給付金）
     * @type {string}
     * @memberof ProductInfo1
     */
    'intraepithelial_diagnostic_treatment'?: string;
    /**
     * 入院
     * @type {string}
     * @memberof ProductInfo1
     */
    'hospitalization'?: string;
    /**
     * 手術
     * @type {string}
     * @memberof ProductInfo1
     */
    'surgery'?: string;
    /**
     * 放射線治療
     * @type {string}
     * @memberof ProductInfo1
     */
    'radiotherapy'?: string;
    /**
     * 通院日額
     * @type {string}
     * @memberof ProductInfo1
     */
    'commuting_daily_amount'?: string;
    /**
     * 通院一時金
     * @type {string}
     * @memberof ProductInfo1
     */
    'commuting_once'?: string;
    /**
     * 先進医療給付金
     * @type {string}
     * @memberof ProductInfo1
     */
    'advanced_medical'?: string;
    /**
     * 先進医療一時金
     * @type {string}
     * @memberof ProductInfo1
     */
    'advanced_medical_once'?: string;
    /**
     * 抗がん剤治療
     * @type {string}
     * @memberof ProductInfo1
     */
    'anticancer_drug_treatment'?: string;
    /**
     * 治療
     * @type {string}
     * @memberof ProductInfo1
     */
    'palliative_treatment'?: string;
    /**
     * 女性特有
     * @type {string}
     * @memberof ProductInfo1
     */
    'female_disease'?: string;
    /**
     * 契約者サービス
     * @type {string}
     * @memberof ProductInfo1
     */
    'contractor_service'?: string;
    /**
     * その他
     * @type {string}
     * @memberof ProductInfo1
     */
    'other_topic'?: string;
}
/**
 * 死亡終身保険
 * @export
 * @interface ProductInfo2
 */
export interface ProductInfo2 {
    /**
     * 死亡保障
     * @type {string}
     * @memberof ProductInfo2
     */
    'death_benefit'?: string;
    /**
     * 解約返戻金
     * @type {string}
     * @memberof ProductInfo2
     */
    'cancellation_refund'?: string;
    /**
     * リビングニーズ
     * @type {string}
     * @memberof ProductInfo2
     */
    'living_needs'?: string;
    /**
     * 保険料払込免除
     * @type {string}
     * @memberof ProductInfo2
     */
    'payment_exemption'?: string;
    /**
     * 健康体割引
     * @type {string}
     * @memberof ProductInfo2
     */
    'health_discount'?: string;
    /**
     * 非喫煙者割引
     * @type {string}
     * @memberof ProductInfo2
     */
    'non_smoking_discount'?: string;
    /**
     * 健康優良
     * @type {string}
     * @memberof ProductInfo2
     */
    'good_health_discount'?: string;
    /**
     * 契約者サービス
     * @type {string}
     * @memberof ProductInfo2
     */
    'contractor_service'?: string;
    /**
     * その他
     * @type {string}
     * @memberof ProductInfo2
     */
    'other_topic'?: string;
}
/**
 * 死亡定期保険
 * @export
 * @interface ProductInfo3
 */
export interface ProductInfo3 {
    /**
     * 死亡保障
     * @type {string}
     * @memberof ProductInfo3
     */
    'death_benefit'?: string;
    /**
     * 災害死亡保障※定期の場合のみ設定
     * @type {string}
     * @memberof ProductInfo3
     */
    'accident_death_benefit'?: string;
    /**
     * 解約返戻金
     * @type {string}
     * @memberof ProductInfo3
     */
    'cancellation_refund'?: string;
    /**
     * リビングニーズ
     * @type {string}
     * @memberof ProductInfo3
     */
    'living_needs'?: string;
    /**
     * 保険料払込免除
     * @type {string}
     * @memberof ProductInfo3
     */
    'payment_exemption'?: string;
    /**
     * 健康体割引
     * @type {string}
     * @memberof ProductInfo3
     */
    'health_discount'?: string;
    /**
     * 非喫煙者割引
     * @type {string}
     * @memberof ProductInfo3
     */
    'non_smoking_discount'?: string;
    /**
     * 健康優良
     * @type {string}
     * @memberof ProductInfo3
     */
    'good_health_discount'?: string;
    /**
     * 契約者サービス
     * @type {string}
     * @memberof ProductInfo3
     */
    'contractor_service'?: string;
    /**
     * その他
     * @type {string}
     * @memberof ProductInfo3
     */
    'other_topic'?: string;
}
/**
 * 収入保障保険
 * @export
 * @interface ProductInfo4
 */
export interface ProductInfo4 {
    /**
     * 収入保障種別※収入保障の場合のみ
     * @type {string}
     * @memberof ProductInfo4
     */
    'income_protection_kind'?: string;
    /**
     * 月額給付金※収入保障のみ
     * @type {string}
     * @memberof ProductInfo4
     */
    'monthly_benefit'?: string;
    /**
     * 解約返戻金
     * @type {string}
     * @memberof ProductInfo4
     */
    'cancellation_refund'?: string;
    /**
     * リビングニーズ
     * @type {string}
     * @memberof ProductInfo4
     */
    'living_needs'?: string;
    /**
     * 保険料払込免除
     * @type {string}
     * @memberof ProductInfo4
     */
    'payment_exemption'?: string;
    /**
     * 健康体割引
     * @type {string}
     * @memberof ProductInfo4
     */
    'health_discount'?: string;
    /**
     * 非喫煙者割引
     * @type {string}
     * @memberof ProductInfo4
     */
    'non_smoking_discount'?: string;
    /**
     * 契約者サービス
     * @type {string}
     * @memberof ProductInfo4
     */
    'contractor_service'?: string;
    /**
     * その他
     * @type {string}
     * @memberof ProductInfo4
     */
    'other_topic'?: string;
}
/**
 * 特定疾病データ群
 * @export
 * @interface SpecifiedDisease
 */
export interface SpecifiedDisease {
    /**
     * 
     * @type {boolean}
     * @memberof SpecifiedDisease
     */
    'is_main_contract'?: boolean;
    /**
     * 表内に表示する文言
     * @type {string}
     * @memberof SpecifiedDisease
     */
    'disp_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof SpecifiedDisease
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpecifiedDisease
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 手術保証データ群
 * @export
 * @interface SurgeryGuarantee
 */
export interface SurgeryGuarantee {
    /**
     * 
     * @type {boolean}
     * @memberof SurgeryGuarantee
     */
    'is_main_contract'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SurgeryGuarantee
     */
    'insurance_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof SurgeryGuarantee
     */
    'insurance_fee_unique_code'?: string;
}
/**
 * 
 * @export
 * @interface TEST
 */
export interface TEST {
    /**
     * 
     * @type {string}
     * @memberof TEST
     */
    'id'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（がん保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCancerInsurancesInsuranceCode: async (insuranceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceCode' is not null or undefined
            assertParamExists('getApiCancerInsurancesInsuranceCode', 'insuranceCode', insuranceCode)
            const localVarPath = `/api/cancer_insurances/{insurance_code}`
                .replace(`{${"insurance_code"}}`, encodeURIComponent(String(insuranceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * がん保険料比較ランキング検索API
         * @summary 保険ランキング取得API（がん保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {number} [malignantDiagnosticOnce] 診断給付金 （悪性新生物）
         * @param {number} [intraepithelialDiagnosticOnce] 診断給付金 （上皮内新生物）
         * @param {number} [malignantDiagnosticTreatment] 治療給付金 （悪性新生物）
         * @param {number} [intraepithelialDiagnosticTreatment] 治療給付金 （上皮内新生物）
         * @param {number} [hospitalizationDailyAmount] がん入院日額
         * @param {number} [hospitalizationDailyAmountDays] がん入院日数
         * @param {number} [surgery] がん手術
         * @param {number} [leavingHospitalOnce] がん退院一時金
         * @param {number} [cancerHospitalDailyFee] がん通院日額
         * @param {number} [cancerHospitalDailyFeeDays] がん通院日数
         * @param {number} [commutingOnce] 通院一時金
         * @param {boolean} [advancedMedical] がん先進医療
         * @param {number} [radiotherapy] 放射線治療
         * @param {boolean} [anticancerDrugTreatment] 抗がん剤治療
         * @param {boolean} [femaleDiseases] 女性特約
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCancerInsurancesRank: async (order: number, age: number, sex: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, malignantDiagnosticOnce?: number, intraepithelialDiagnosticOnce?: number, malignantDiagnosticTreatment?: number, intraepithelialDiagnosticTreatment?: number, hospitalizationDailyAmount?: number, hospitalizationDailyAmountDays?: number, surgery?: number, leavingHospitalOnce?: number, cancerHospitalDailyFee?: number, cancerHospitalDailyFeeDays?: number, commutingOnce?: number, advancedMedical?: boolean, radiotherapy?: number, anticancerDrugTreatment?: boolean, femaleDiseases?: boolean, diagnoseCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'order' is not null or undefined
            assertParamExists('getApiCancerInsurancesRank', 'order', order)
            // verify required parameter 'age' is not null or undefined
            assertParamExists('getApiCancerInsurancesRank', 'age', age)
            // verify required parameter 'sex' is not null or undefined
            assertParamExists('getApiCancerInsurancesRank', 'sex', sex)
            // verify required parameter 'paymentExemption' is not null or undefined
            assertParamExists('getApiCancerInsurancesRank', 'paymentExemption', paymentExemption)
            // verify required parameter 'underwritingEasing' is not null or undefined
            assertParamExists('getApiCancerInsurancesRank', 'underwritingEasing', underwritingEasing)
            // verify required parameter 'intentionRecommendInsurance' is not null or undefined
            assertParamExists('getApiCancerInsurancesRank', 'intentionRecommendInsurance', intentionRecommendInsurance)
            const localVarPath = `/api/cancer_insurances/rank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (maxBudget !== undefined) {
                localVarQueryParameter['max_budget'] = maxBudget;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (malignantDiagnosticOnce !== undefined) {
                localVarQueryParameter['malignant_diagnostic_once'] = malignantDiagnosticOnce;
            }

            if (intraepithelialDiagnosticOnce !== undefined) {
                localVarQueryParameter['intraepithelial_diagnostic_once'] = intraepithelialDiagnosticOnce;
            }

            if (malignantDiagnosticTreatment !== undefined) {
                localVarQueryParameter['malignant_diagnostic_treatment'] = malignantDiagnosticTreatment;
            }

            if (intraepithelialDiagnosticTreatment !== undefined) {
                localVarQueryParameter['intraepithelial_diagnostic_treatment'] = intraepithelialDiagnosticTreatment;
            }

            if (hospitalizationDailyAmount !== undefined) {
                localVarQueryParameter['hospitalization_daily_amount'] = hospitalizationDailyAmount;
            }

            if (hospitalizationDailyAmountDays !== undefined) {
                localVarQueryParameter['hospitalization_daily_amount_days'] = hospitalizationDailyAmountDays;
            }

            if (surgery !== undefined) {
                localVarQueryParameter['surgery'] = surgery;
            }

            if (leavingHospitalOnce !== undefined) {
                localVarQueryParameter['leaving_hospital_once'] = leavingHospitalOnce;
            }

            if (cancerHospitalDailyFee !== undefined) {
                localVarQueryParameter['cancer_hospital_daily_fee'] = cancerHospitalDailyFee;
            }

            if (cancerHospitalDailyFeeDays !== undefined) {
                localVarQueryParameter['cancer_hospital_daily_fee_days'] = cancerHospitalDailyFeeDays;
            }

            if (commutingOnce !== undefined) {
                localVarQueryParameter['commuting_once'] = commutingOnce;
            }

            if (advancedMedical !== undefined) {
                localVarQueryParameter['advanced_medical'] = advancedMedical;
            }

            if (radiotherapy !== undefined) {
                localVarQueryParameter['radiotherapy'] = radiotherapy;
            }

            if (anticancerDrugTreatment !== undefined) {
                localVarQueryParameter['anticancer_drug_treatment'] = anticancerDrugTreatment;
            }

            if (femaleDiseases !== undefined) {
                localVarQueryParameter['female_diseases'] = femaleDiseases;
            }

            if (paymentExemption !== undefined) {
                localVarQueryParameter['payment_exemption'] = paymentExemption;
            }

            if (underwritingEasing !== undefined) {
                localVarQueryParameter['underwriting_easing'] = underwritingEasing;
            }

            if (intentionRecommendInsurance !== undefined) {
                localVarQueryParameter['intention_recommend_insurance'] = intentionRecommendInsurance;
            }

            if (diagnoseCode !== undefined) {
                localVarQueryParameter['diagnose_code'] = diagnoseCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 診断結果取得
         * @summary 診断結果取得API
         * @param {string} diagnosCode 
         * @param {number} version バージョン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDiagnoseDiagnoseId: async (diagnosCode: string, version: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'diagnosCode' is not null or undefined
            assertParamExists('getApiDiagnoseDiagnoseId', 'diagnosCode', diagnosCode)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getApiDiagnoseDiagnoseId', 'version', version)
            const localVarPath = `/api/diagnose/{diagnos_code}`
                .replace(`{${"diagnos_code"}}`, encodeURIComponent(String(diagnosCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（収入保障保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiIncomeProtectionInsurancesInsuranceCode: async (insuranceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceCode' is not null or undefined
            assertParamExists('getApiIncomeProtectionInsurancesInsuranceCode', 'insuranceCode', insuranceCode)
            const localVarPath = `/api/income_protection_insurances/{insurance_code}`
                .replace(`{${"insurance_code"}}`, encodeURIComponent(String(insuranceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 収入保障保険料比較ランキング検索API
         * @summary 保険ランキング取得API（収入保障保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} monthlyBenefit 月額保障
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
         * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
         * @param {number} [paymentMinGuaranteeTerm] 支払い最低保証期間（任意項目、1～5）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiIncomeProtectionInsurancesRank: async (order: number, age: number, sex: number, monthlyBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, paymentMinGuaranteeTerm?: number, diagnoseCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'order' is not null or undefined
            assertParamExists('getApiIncomeProtectionInsurancesRank', 'order', order)
            // verify required parameter 'age' is not null or undefined
            assertParamExists('getApiIncomeProtectionInsurancesRank', 'age', age)
            // verify required parameter 'sex' is not null or undefined
            assertParamExists('getApiIncomeProtectionInsurancesRank', 'sex', sex)
            // verify required parameter 'monthlyBenefit' is not null or undefined
            assertParamExists('getApiIncomeProtectionInsurancesRank', 'monthlyBenefit', monthlyBenefit)
            // verify required parameter 'paymentExemption' is not null or undefined
            assertParamExists('getApiIncomeProtectionInsurancesRank', 'paymentExemption', paymentExemption)
            // verify required parameter 'underwritingEasing' is not null or undefined
            assertParamExists('getApiIncomeProtectionInsurancesRank', 'underwritingEasing', underwritingEasing)
            // verify required parameter 'intentionRecommendInsurance' is not null or undefined
            assertParamExists('getApiIncomeProtectionInsurancesRank', 'intentionRecommendInsurance', intentionRecommendInsurance)
            const localVarPath = `/api/income_protection_insurances/rank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (maxBudget !== undefined) {
                localVarQueryParameter['max_budget'] = maxBudget;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (monthlyBenefit !== undefined) {
                localVarQueryParameter['monthly_benefit'] = monthlyBenefit;
            }

            if (cancelRepayment !== undefined) {
                localVarQueryParameter['cancel_repayment'] = cancelRepayment;
            }

            if (paymentExemption !== undefined) {
                localVarQueryParameter['payment_exemption'] = paymentExemption;
            }

            if (underwritingEasing !== undefined) {
                localVarQueryParameter['underwriting_easing'] = underwritingEasing;
            }

            if (intentionRecommendInsurance !== undefined) {
                localVarQueryParameter['intention_recommend_insurance'] = intentionRecommendInsurance;
            }

            if (livingNeeds !== undefined) {
                localVarQueryParameter['living_needs'] = livingNeeds;
            }

            if (paymentMinGuaranteeTerm !== undefined) {
                localVarQueryParameter['payment_min_guarantee_term'] = paymentMinGuaranteeTerm;
            }

            if (diagnoseCode !== undefined) {
                localVarQueryParameter['diagnose_code'] = diagnoseCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保険会社一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiInsuranceCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insurance_companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（医療保険）
         * @param {string} insuranceCode 保険商品コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiInsurancesInsuranceCode: async (insuranceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceCode' is not null or undefined
            assertParamExists('getApiInsurancesInsuranceCode', 'insuranceCode', insuranceCode)
            const localVarPath = `/api/medical_insurances/{insurance_code}`
                .replace(`{${"insurance_code"}}`, encodeURIComponent(String(insuranceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 医療保険料比較ランキング検索API
         * @summary 保険ランキング取得API（医療保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} hospitalizationGuarantee 入院日額
         * @param {number} hospitalizationGuaranteeDays 入院日数
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {number} [hospitalizationOnce] 入院一時金
         * @param {boolean} [surgeryGuarantee] 手術保証
         * @param {number} [commutingDailyAmount] 通院日額
         * @param {number} [commutingDailyAmountDays] 通院日数
         * @param {number} [commutingOnce] 通院一時金
         * @param {boolean} [advancedMedical] 先進医療
         * @param {number} [cancerSpecialContractAmount] がん特約金額
         * @param {boolean} [specifiedDisease] 特定疾病
         * @param {number} [deathSpecialContractAmount] 死亡特約
         * @param {boolean} [congratulatoryMoney] 祝い金（健康ボーナス）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMedicalInsuranceRank: async (order: number, age: number, sex: number, hospitalizationGuarantee: number, hospitalizationGuaranteeDays: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, hospitalizationOnce?: number, surgeryGuarantee?: boolean, commutingDailyAmount?: number, commutingDailyAmountDays?: number, commutingOnce?: number, advancedMedical?: boolean, cancerSpecialContractAmount?: number, specifiedDisease?: boolean, deathSpecialContractAmount?: number, congratulatoryMoney?: boolean, diagnoseCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'order' is not null or undefined
            assertParamExists('getApiMedicalInsuranceRank', 'order', order)
            // verify required parameter 'age' is not null or undefined
            assertParamExists('getApiMedicalInsuranceRank', 'age', age)
            // verify required parameter 'sex' is not null or undefined
            assertParamExists('getApiMedicalInsuranceRank', 'sex', sex)
            // verify required parameter 'hospitalizationGuarantee' is not null or undefined
            assertParamExists('getApiMedicalInsuranceRank', 'hospitalizationGuarantee', hospitalizationGuarantee)
            // verify required parameter 'hospitalizationGuaranteeDays' is not null or undefined
            assertParamExists('getApiMedicalInsuranceRank', 'hospitalizationGuaranteeDays', hospitalizationGuaranteeDays)
            // verify required parameter 'paymentExemption' is not null or undefined
            assertParamExists('getApiMedicalInsuranceRank', 'paymentExemption', paymentExemption)
            // verify required parameter 'underwritingEasing' is not null or undefined
            assertParamExists('getApiMedicalInsuranceRank', 'underwritingEasing', underwritingEasing)
            // verify required parameter 'intentionRecommendInsurance' is not null or undefined
            assertParamExists('getApiMedicalInsuranceRank', 'intentionRecommendInsurance', intentionRecommendInsurance)
            const localVarPath = `/api/medical_insurances/rank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (maxBudget !== undefined) {
                localVarQueryParameter['max_budget'] = maxBudget;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (hospitalizationGuarantee !== undefined) {
                localVarQueryParameter['hospitalization_guarantee'] = hospitalizationGuarantee;
            }

            if (hospitalizationGuaranteeDays !== undefined) {
                localVarQueryParameter['hospitalization_guarantee_days'] = hospitalizationGuaranteeDays;
            }

            if (hospitalizationOnce !== undefined) {
                localVarQueryParameter['hospitalization_once'] = hospitalizationOnce;
            }

            if (surgeryGuarantee !== undefined) {
                localVarQueryParameter['surgery_guarantee'] = surgeryGuarantee;
            }

            if (commutingDailyAmount !== undefined) {
                localVarQueryParameter['commuting_daily_amount'] = commutingDailyAmount;
            }

            if (commutingDailyAmountDays !== undefined) {
                localVarQueryParameter['commuting_daily_amount_days'] = commutingDailyAmountDays;
            }

            if (commutingOnce !== undefined) {
                localVarQueryParameter['commuting_once'] = commutingOnce;
            }

            if (advancedMedical !== undefined) {
                localVarQueryParameter['advanced_medical'] = advancedMedical;
            }

            if (cancerSpecialContractAmount !== undefined) {
                localVarQueryParameter['cancer_special_contract_amount'] = cancerSpecialContractAmount;
            }

            if (specifiedDisease !== undefined) {
                localVarQueryParameter['specified_disease'] = specifiedDisease;
            }

            if (deathSpecialContractAmount !== undefined) {
                localVarQueryParameter['death_special_contract_amount'] = deathSpecialContractAmount;
            }

            if (congratulatoryMoney !== undefined) {
                localVarQueryParameter['congratulatory_money'] = congratulatoryMoney;
            }

            if (paymentExemption !== undefined) {
                localVarQueryParameter['payment_exemption'] = paymentExemption;
            }

            if (underwritingEasing !== undefined) {
                localVarQueryParameter['underwriting_easing'] = underwritingEasing;
            }

            if (intentionRecommendInsurance !== undefined) {
                localVarQueryParameter['intention_recommend_insurance'] = intentionRecommendInsurance;
            }

            if (diagnoseCode !== undefined) {
                localVarQueryParameter['diagnose_code'] = diagnoseCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * URLクエリパラメータから取得した、partner_codeをパスパラメータとして設定
         * @summary 提携先情報取得API
         * @param {string} partnerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiPartnersPartnerCode: async (partnerCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerCode' is not null or undefined
            assertParamExists('getApiPartnersPartnerCode', 'partnerCode', partnerCode)
            const localVarPath = `/api/partners/{partner_code}`
                .replace(`{${"partner_code"}}`, encodeURIComponent(String(partnerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（定期死亡保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTermDeathInsurancesInsuranceCode: async (insuranceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceCode' is not null or undefined
            assertParamExists('getApiTermDeathInsurancesInsuranceCode', 'insuranceCode', insuranceCode)
            const localVarPath = `/api/term_death_insurances/{insurance_code}`
                .replace(`{${"insurance_code"}}`, encodeURIComponent(String(insuranceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 死亡保険（終身）料比較ランキング検索API
         * @summary 保険ランキング取得API（定期死亡保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} deathBenefit 死亡保障
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
         * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTermDeathInsurancesRank: async (order: number, age: number, sex: number, deathBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, diagnoseCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'order' is not null or undefined
            assertParamExists('getApiTermDeathInsurancesRank', 'order', order)
            // verify required parameter 'age' is not null or undefined
            assertParamExists('getApiTermDeathInsurancesRank', 'age', age)
            // verify required parameter 'sex' is not null or undefined
            assertParamExists('getApiTermDeathInsurancesRank', 'sex', sex)
            // verify required parameter 'deathBenefit' is not null or undefined
            assertParamExists('getApiTermDeathInsurancesRank', 'deathBenefit', deathBenefit)
            // verify required parameter 'paymentExemption' is not null or undefined
            assertParamExists('getApiTermDeathInsurancesRank', 'paymentExemption', paymentExemption)
            // verify required parameter 'underwritingEasing' is not null or undefined
            assertParamExists('getApiTermDeathInsurancesRank', 'underwritingEasing', underwritingEasing)
            // verify required parameter 'intentionRecommendInsurance' is not null or undefined
            assertParamExists('getApiTermDeathInsurancesRank', 'intentionRecommendInsurance', intentionRecommendInsurance)
            const localVarPath = `/api/term_death_insurances/rank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (maxBudget !== undefined) {
                localVarQueryParameter['max_budget'] = maxBudget;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (deathBenefit !== undefined) {
                localVarQueryParameter['death_benefit'] = deathBenefit;
            }

            if (cancelRepayment !== undefined) {
                localVarQueryParameter['cancel_repayment'] = cancelRepayment;
            }

            if (paymentExemption !== undefined) {
                localVarQueryParameter['payment_exemption'] = paymentExemption;
            }

            if (underwritingEasing !== undefined) {
                localVarQueryParameter['underwriting_easing'] = underwritingEasing;
            }

            if (intentionRecommendInsurance !== undefined) {
                localVarQueryParameter['intention_recommend_insurance'] = intentionRecommendInsurance;
            }

            if (livingNeeds !== undefined) {
                localVarQueryParameter['living_needs'] = livingNeeds;
            }

            if (diagnoseCode !== undefined) {
                localVarQueryParameter['diagnose_code'] = diagnoseCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（終身死亡保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiWholeDeathInsurancesInsuranceCode: async (insuranceCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceCode' is not null or undefined
            assertParamExists('getApiWholeDeathInsurancesInsuranceCode', 'insuranceCode', insuranceCode)
            const localVarPath = `/api/whole_death_insurances/{insurance_code}`
                .replace(`{${"insurance_code"}}`, encodeURIComponent(String(insuranceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 死亡保険（終身）料比較ランキング検索API
         * @summary 保険ランキング取得API（終身死亡保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} deathBenefit 死亡保障
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
         * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiWholeDeathInsurancesRank: async (order: number, age: number, sex: number, deathBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, diagnoseCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'order' is not null or undefined
            assertParamExists('getApiWholeDeathInsurancesRank', 'order', order)
            // verify required parameter 'age' is not null or undefined
            assertParamExists('getApiWholeDeathInsurancesRank', 'age', age)
            // verify required parameter 'sex' is not null or undefined
            assertParamExists('getApiWholeDeathInsurancesRank', 'sex', sex)
            // verify required parameter 'deathBenefit' is not null or undefined
            assertParamExists('getApiWholeDeathInsurancesRank', 'deathBenefit', deathBenefit)
            // verify required parameter 'paymentExemption' is not null or undefined
            assertParamExists('getApiWholeDeathInsurancesRank', 'paymentExemption', paymentExemption)
            // verify required parameter 'underwritingEasing' is not null or undefined
            assertParamExists('getApiWholeDeathInsurancesRank', 'underwritingEasing', underwritingEasing)
            // verify required parameter 'intentionRecommendInsurance' is not null or undefined
            assertParamExists('getApiWholeDeathInsurancesRank', 'intentionRecommendInsurance', intentionRecommendInsurance)
            const localVarPath = `/api/whole_death_insurances/rank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (maxBudget !== undefined) {
                localVarQueryParameter['max_budget'] = maxBudget;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (deathBenefit !== undefined) {
                localVarQueryParameter['death_benefit'] = deathBenefit;
            }

            if (cancelRepayment !== undefined) {
                localVarQueryParameter['cancel_repayment'] = cancelRepayment;
            }

            if (paymentExemption !== undefined) {
                localVarQueryParameter['payment_exemption'] = paymentExemption;
            }

            if (underwritingEasing !== undefined) {
                localVarQueryParameter['underwriting_easing'] = underwritingEasing;
            }

            if (intentionRecommendInsurance !== undefined) {
                localVarQueryParameter['intention_recommend_insurance'] = intentionRecommendInsurance;
            }

            if (livingNeeds !== undefined) {
                localVarQueryParameter['living_needs'] = livingNeeds;
            }

            if (diagnoseCode !== undefined) {
                localVarQueryParameter['diagnose_code'] = diagnoseCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資料請求（廃止）
         * @summary 資料請求問い合わせAPI（廃止）
         * @param {PostApiContactDocumentRequestRequest} [postApiContactDocumentRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiContactDocumentRequest: async (postApiContactDocumentRequestRequest?: PostApiContactDocumentRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contact/document-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postApiContactDocumentRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Eメール問い合わせ
         * @summary Email問い合わせAPI
         * @param {PostApiContactEmailRequest} [postApiContactEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiContactEmail: async (postApiContactEmailRequest?: PostApiContactEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contact/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postApiContactEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * FP問い合わせ
         * @summary FP問い合わせAPI
         * @param {PostApiContactFpRequest} [postApiContactFpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiContactFp: async (postApiContactFpRequest?: PostApiContactFpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contact/fp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postApiContactFpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * FP問い合わせご
         * @summary FP問い合わせAPI
         * @param {PostApiContactFpRequest} [postApiContactFpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiAfterContactFp: async (postApiAfterContactFpRequest?: PostApiAfterContactFpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contact/af`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postApiAfterContactFpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SMS問い合わせ
         * @summary SMS問い合わせAPI
         * @param {PostApiContactSmsRequest} [postApiContactSmsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiContactSms: async (postApiContactSmsRequest?: PostApiContactSmsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contact/sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postApiContactSmsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 詳しく診断の回答内容及び、診断結果の保存 診断結果の返却
         * @summary 詳しく診断結果保存API
         * @param {ApiDetailDiagnoseRequest} [apiDetailDiagnoseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDetailDiagnose: async (apiDetailDiagnoseRequest?: ApiDetailDiagnoseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/detail-diagnose`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiDetailDiagnoseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * かんたん診断の回答内容及び、診断結果の保存 診断結果の返却 
         * @summary かんたん診断結果保存API
         * @param {ApiSimpleDiagnoseRequest} [apiSimpleDiagnoseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSimpleDiagnose: async (apiSimpleDiagnoseRequest?: ApiSimpleDiagnoseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/simple-diagnose`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiSimpleDiagnoseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（がん保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCancerInsurancesInsuranceCode(insuranceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApiCancerInsurancesInsuranceCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCancerInsurancesInsuranceCode(insuranceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * がん保険料比較ランキング検索API
         * @summary 保険ランキング取得API（がん保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {number} [malignantDiagnosticOnce] 診断給付金 （悪性新生物）
         * @param {number} [intraepithelialDiagnosticOnce] 診断給付金 （上皮内新生物）
         * @param {number} [malignantDiagnosticTreatment] 治療給付金 （悪性新生物）
         * @param {number} [intraepithelialDiagnosticTreatment] 治療給付金 （上皮内新生物）
         * @param {number} [hospitalizationDailyAmount] がん入院日額
         * @param {number} [hospitalizationDailyAmountDays] がん入院日数
         * @param {number} [surgery] がん手術
         * @param {number} [leavingHospitalOnce] がん退院一時金
         * @param {number} [cancerHospitalDailyFee] がん通院日額
         * @param {number} [cancerHospitalDailyFeeDays] がん通院日数
         * @param {number} [commutingOnce] 通院一時金
         * @param {boolean} [advancedMedical] がん先進医療
         * @param {number} [radiotherapy] 放射線治療
         * @param {boolean} [anticancerDrugTreatment] 抗がん剤治療
         * @param {boolean} [femaleDiseases] 女性特約
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCancerInsurancesRank(order: number, age: number, sex: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, malignantDiagnosticOnce?: number, intraepithelialDiagnosticOnce?: number, malignantDiagnosticTreatment?: number, intraepithelialDiagnosticTreatment?: number, hospitalizationDailyAmount?: number, hospitalizationDailyAmountDays?: number, surgery?: number, leavingHospitalOnce?: number, cancerHospitalDailyFee?: number, cancerHospitalDailyFeeDays?: number, commutingOnce?: number, advancedMedical?: boolean, radiotherapy?: number, anticancerDrugTreatment?: boolean, femaleDiseases?: boolean, diagnoseCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCancerInsurancesRankResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCancerInsurancesRank(order, age, sex, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, malignantDiagnosticOnce, intraepithelialDiagnosticOnce, malignantDiagnosticTreatment, intraepithelialDiagnosticTreatment, hospitalizationDailyAmount, hospitalizationDailyAmountDays, surgery, leavingHospitalOnce, cancerHospitalDailyFee, cancerHospitalDailyFeeDays, commutingOnce, advancedMedical, radiotherapy, anticancerDrugTreatment, femaleDiseases, diagnoseCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 診断結果取得
         * @summary 診断結果取得API
         * @param {string} diagnosCode 
         * @param {number} version バージョン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDiagnoseDiagnoseId(diagnosCode: string, version: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostApiSimpleDiagnose200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDiagnoseDiagnoseId(diagnosCode, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（収入保障保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiIncomeProtectionInsurancesInsuranceCode(insuranceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApiIncomeProtectionInsurancesInsuranceCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiIncomeProtectionInsurancesInsuranceCode(insuranceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 収入保障保険料比較ランキング検索API
         * @summary 保険ランキング取得API（収入保障保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} monthlyBenefit 月額保障
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
         * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
         * @param {number} [paymentMinGuaranteeTerm] 支払い最低保証期間（任意項目、1～5）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiIncomeProtectionInsurancesRank(order: number, age: number, sex: number, monthlyBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, paymentMinGuaranteeTerm?: number, diagnoseCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiIncomeProtectionInsurancesRankResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiIncomeProtectionInsurancesRank(order, age, sex, monthlyBenefit, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, cancelRepayment, livingNeeds, paymentMinGuaranteeTerm, diagnoseCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保険会社一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiInsuranceCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetApiInsuranceCompanies200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiInsuranceCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（医療保険）
         * @param {string} insuranceCode 保険商品コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiInsurancesInsuranceCode(insuranceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApiInsurancesInsuranceCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiInsurancesInsuranceCode(insuranceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 医療保険料比較ランキング検索API
         * @summary 保険ランキング取得API（医療保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} hospitalizationGuarantee 入院日額
         * @param {number} hospitalizationGuaranteeDays 入院日数
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {number} [hospitalizationOnce] 入院一時金
         * @param {boolean} [surgeryGuarantee] 手術保証
         * @param {number} [commutingDailyAmount] 通院日額
         * @param {number} [commutingDailyAmountDays] 通院日数
         * @param {number} [commutingOnce] 通院一時金
         * @param {boolean} [advancedMedical] 先進医療
         * @param {number} [cancerSpecialContractAmount] がん特約金額
         * @param {boolean} [specifiedDisease] 特定疾病
         * @param {number} [deathSpecialContractAmount] 死亡特約
         * @param {boolean} [congratulatoryMoney] 祝い金（健康ボーナス）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMedicalInsuranceRank(order: number, age: number, sex: number, hospitalizationGuarantee: number, hospitalizationGuaranteeDays: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, hospitalizationOnce?: number, surgeryGuarantee?: boolean, commutingDailyAmount?: number, commutingDailyAmountDays?: number, commutingOnce?: number, advancedMedical?: boolean, cancerSpecialContractAmount?: number, specifiedDisease?: boolean, deathSpecialContractAmount?: number, congratulatoryMoney?: boolean, diagnoseCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApiMedicalInsuranceRank200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMedicalInsuranceRank(order, age, sex, hospitalizationGuarantee, hospitalizationGuaranteeDays, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, hospitalizationOnce, surgeryGuarantee, commutingDailyAmount, commutingDailyAmountDays, commutingOnce, advancedMedical, cancerSpecialContractAmount, specifiedDisease, deathSpecialContractAmount, congratulatoryMoney, diagnoseCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * URLクエリパラメータから取得した、partner_codeをパスパラメータとして設定
         * @summary 提携先情報取得API
         * @param {string} partnerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiPartnersPartnerCode(partnerCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApiPartnersPartnerCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiPartnersPartnerCode(partnerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（定期死亡保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiTermDeathInsurancesInsuranceCode(insuranceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApiTermDeathInsurancesInsuranceCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTermDeathInsurancesInsuranceCode(insuranceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 死亡保険（終身）料比較ランキング検索API
         * @summary 保険ランキング取得API（定期死亡保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} deathBenefit 死亡保障
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
         * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiTermDeathInsurancesRank(order: number, age: number, sex: number, deathBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, diagnoseCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTermDeathInsurancesRankResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTermDeathInsurancesRank(order, age, sex, deathBenefit, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, cancelRepayment, livingNeeds, diagnoseCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（終身死亡保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiWholeDeathInsurancesInsuranceCode(insuranceCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApiWholeDeathInsurancesInsuranceCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiWholeDeathInsurancesInsuranceCode(insuranceCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 死亡保険（終身）料比較ランキング検索API
         * @summary 保険ランキング取得API（終身死亡保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} deathBenefit 死亡保障
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
         * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiWholeDeathInsurancesRank(order: number, age: number, sex: number, deathBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, diagnoseCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiWholeDeathInsurancesRankResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiWholeDeathInsurancesRank(order, age, sex, deathBenefit, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, cancelRepayment, livingNeeds, diagnoseCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資料請求（廃止）
         * @summary 資料請求問い合わせAPI（廃止）
         * @param {PostApiContactDocumentRequestRequest} [postApiContactDocumentRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiContactDocumentRequest(postApiContactDocumentRequestRequest?: PostApiContactDocumentRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostApiContactDocumentRequest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiContactDocumentRequest(postApiContactDocumentRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Eメール問い合わせ
         * @summary Email問い合わせAPI
         * @param {PostApiContactEmailRequest} [postApiContactEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiContactEmail(postApiContactEmailRequest?: PostApiContactEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostApiContactEmail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiContactEmail(postApiContactEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * FP問い合わせ
         * @summary FP問い合わせAPI
         * @param {PostApiContactFpRequest} [postApiContactFpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiContactFp(postApiContactFpRequest?: PostApiContactFpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostApiContactFp200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiContactFp(postApiContactFpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * FP問い合わせご
         * @summary FP問い合わせAPI
         * @param {PostApiContactFpRequest} [postApiContactFpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiAfterContactFp(postApiAfterContactFpRequest?: PostApiAfterContactFpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostApiContactFp200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiAfterContactFp(postApiAfterContactFpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SMS問い合わせ
         * @summary SMS問い合わせAPI
         * @param {PostApiContactSmsRequest} [postApiContactSmsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiContactSms(postApiContactSmsRequest?: PostApiContactSmsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostApiContactSms200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiContactSms(postApiContactSmsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 詳しく診断の回答内容及び、診断結果の保存 診断結果の返却
         * @summary 詳しく診断結果保存API
         * @param {ApiDetailDiagnoseRequest} [apiDetailDiagnoseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDetailDiagnose(apiDetailDiagnoseRequest?: ApiDetailDiagnoseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostApiSimpleDiagnose200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDetailDiagnose(apiDetailDiagnoseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * かんたん診断の回答内容及び、診断結果の保存 診断結果の返却 
         * @summary かんたん診断結果保存API
         * @param {ApiSimpleDiagnoseRequest} [apiSimpleDiagnoseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiSimpleDiagnose(apiSimpleDiagnoseRequest?: ApiSimpleDiagnoseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostApiSimpleDiagnose200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiSimpleDiagnose(apiSimpleDiagnoseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（がん保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCancerInsurancesInsuranceCode(insuranceCode: string, options?: any): AxiosPromise<GetApiCancerInsurancesInsuranceCode200Response> {
            return localVarFp.getApiCancerInsurancesInsuranceCode(insuranceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * がん保険料比較ランキング検索API
         * @summary 保険ランキング取得API（がん保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {number} [malignantDiagnosticOnce] 診断給付金 （悪性新生物）
         * @param {number} [intraepithelialDiagnosticOnce] 診断給付金 （上皮内新生物）
         * @param {number} [malignantDiagnosticTreatment] 治療給付金 （悪性新生物）
         * @param {number} [intraepithelialDiagnosticTreatment] 治療給付金 （上皮内新生物）
         * @param {number} [hospitalizationDailyAmount] がん入院日額
         * @param {number} [hospitalizationDailyAmountDays] がん入院日数
         * @param {number} [surgery] がん手術
         * @param {number} [leavingHospitalOnce] がん退院一時金
         * @param {number} [cancerHospitalDailyFee] がん通院日額
         * @param {number} [cancerHospitalDailyFeeDays] がん通院日数
         * @param {number} [commutingOnce] 通院一時金
         * @param {boolean} [advancedMedical] がん先進医療
         * @param {number} [radiotherapy] 放射線治療
         * @param {boolean} [anticancerDrugTreatment] 抗がん剤治療
         * @param {boolean} [femaleDiseases] 女性特約
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCancerInsurancesRank(order: number, age: number, sex: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, malignantDiagnosticOnce?: number, intraepithelialDiagnosticOnce?: number, malignantDiagnosticTreatment?: number, intraepithelialDiagnosticTreatment?: number, hospitalizationDailyAmount?: number, hospitalizationDailyAmountDays?: number, surgery?: number, leavingHospitalOnce?: number, cancerHospitalDailyFee?: number, cancerHospitalDailyFeeDays?: number, commutingOnce?: number, advancedMedical?: boolean, radiotherapy?: number, anticancerDrugTreatment?: boolean, femaleDiseases?: boolean, diagnoseCode?: string, options?: any): AxiosPromise<ApiCancerInsurancesRankResponse> {
            return localVarFp.getApiCancerInsurancesRank(order, age, sex, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, malignantDiagnosticOnce, intraepithelialDiagnosticOnce, malignantDiagnosticTreatment, intraepithelialDiagnosticTreatment, hospitalizationDailyAmount, hospitalizationDailyAmountDays, surgery, leavingHospitalOnce, cancerHospitalDailyFee, cancerHospitalDailyFeeDays, commutingOnce, advancedMedical, radiotherapy, anticancerDrugTreatment, femaleDiseases, diagnoseCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 診断結果取得
         * @summary 診断結果取得API
         * @param {string} diagnosCode 
         * @param {number} version バージョン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDiagnoseDiagnoseId(diagnosCode: string, version: number, options?: any): AxiosPromise<PostApiSimpleDiagnose200Response> {
            return localVarFp.getApiDiagnoseDiagnoseId(diagnosCode, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（収入保障保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiIncomeProtectionInsurancesInsuranceCode(insuranceCode: string, options?: any): AxiosPromise<GetApiIncomeProtectionInsurancesInsuranceCode200Response> {
            return localVarFp.getApiIncomeProtectionInsurancesInsuranceCode(insuranceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 収入保障保険料比較ランキング検索API
         * @summary 保険ランキング取得API（収入保障保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} monthlyBenefit 月額保障
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
         * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
         * @param {number} [paymentMinGuaranteeTerm] 支払い最低保証期間（任意項目、1～5）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiIncomeProtectionInsurancesRank(order: number, age: number, sex: number, monthlyBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, paymentMinGuaranteeTerm?: number, diagnoseCode?: string, options?: any): AxiosPromise<ApiIncomeProtectionInsurancesRankResponse> {
            return localVarFp.getApiIncomeProtectionInsurancesRank(order, age, sex, monthlyBenefit, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, cancelRepayment, livingNeeds, paymentMinGuaranteeTerm, diagnoseCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保険会社一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiInsuranceCompanies(options?: any): AxiosPromise<Array<GetApiInsuranceCompanies200ResponseInner>> {
            return localVarFp.getApiInsuranceCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（医療保険）
         * @param {string} insuranceCode 保険商品コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiInsurancesInsuranceCode(insuranceCode: string, options?: any): AxiosPromise<GetApiInsurancesInsuranceCode200Response> {
            return localVarFp.getApiInsurancesInsuranceCode(insuranceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 医療保険料比較ランキング検索API
         * @summary 保険ランキング取得API（医療保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} hospitalizationGuarantee 入院日額
         * @param {number} hospitalizationGuaranteeDays 入院日数
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {number} [hospitalizationOnce] 入院一時金
         * @param {boolean} [surgeryGuarantee] 手術保証
         * @param {number} [commutingDailyAmount] 通院日額
         * @param {number} [commutingDailyAmountDays] 通院日数
         * @param {number} [commutingOnce] 通院一時金
         * @param {boolean} [advancedMedical] 先進医療
         * @param {number} [cancerSpecialContractAmount] がん特約金額
         * @param {boolean} [specifiedDisease] 特定疾病
         * @param {number} [deathSpecialContractAmount] 死亡特約
         * @param {boolean} [congratulatoryMoney] 祝い金（健康ボーナス）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMedicalInsuranceRank(order: number, age: number, sex: number, hospitalizationGuarantee: number, hospitalizationGuaranteeDays: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, hospitalizationOnce?: number, surgeryGuarantee?: boolean, commutingDailyAmount?: number, commutingDailyAmountDays?: number, commutingOnce?: number, advancedMedical?: boolean, cancerSpecialContractAmount?: number, specifiedDisease?: boolean, deathSpecialContractAmount?: number, congratulatoryMoney?: boolean, diagnoseCode?: string, options?: any): AxiosPromise<GetApiMedicalInsuranceRank200Response> {
            return localVarFp.getApiMedicalInsuranceRank(order, age, sex, hospitalizationGuarantee, hospitalizationGuaranteeDays, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, hospitalizationOnce, surgeryGuarantee, commutingDailyAmount, commutingDailyAmountDays, commutingOnce, advancedMedical, cancerSpecialContractAmount, specifiedDisease, deathSpecialContractAmount, congratulatoryMoney, diagnoseCode, options).then((request) => request(axios, basePath));
        },
        /**
         * URLクエリパラメータから取得した、partner_codeをパスパラメータとして設定
         * @summary 提携先情報取得API
         * @param {string} partnerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiPartnersPartnerCode(partnerCode: string, options?: any): AxiosPromise<GetApiPartnersPartnerCode200Response> {
            return localVarFp.getApiPartnersPartnerCode(partnerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（定期死亡保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTermDeathInsurancesInsuranceCode(insuranceCode: string, options?: any): AxiosPromise<GetApiTermDeathInsurancesInsuranceCode200Response> {
            return localVarFp.getApiTermDeathInsurancesInsuranceCode(insuranceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 死亡保険（終身）料比較ランキング検索API
         * @summary 保険ランキング取得API（定期死亡保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} deathBenefit 死亡保障
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
         * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTermDeathInsurancesRank(order: number, age: number, sex: number, deathBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, diagnoseCode?: string, options?: any): AxiosPromise<ApiTermDeathInsurancesRankResponse> {
            return localVarFp.getApiTermDeathInsurancesRank(order, age, sex, deathBenefit, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, cancelRepayment, livingNeeds, diagnoseCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 保険商品コードをもとに保険商品を検索。保険情報を返却
         * @summary 保険商品詳細取得API（終身死亡保険）
         * @param {string} insuranceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiWholeDeathInsurancesInsuranceCode(insuranceCode: string, options?: any): AxiosPromise<GetApiWholeDeathInsurancesInsuranceCode200Response> {
            return localVarFp.getApiWholeDeathInsurancesInsuranceCode(insuranceCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 死亡保険（終身）料比較ランキング検索API
         * @summary 保険ランキング取得API（終身死亡保険）
         * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
         * @param {number} age 年齢
         * @param {number} sex 性別（1：男,2：女）
         * @param {number} deathBenefit 死亡保障
         * @param {boolean} paymentExemption 保険料払込免除
         * @param {boolean} underwritingEasing 引受基準緩和型
         * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
         * @param {number} [maxBudget] 予算上限(なしは1）
         * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
         * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
         * @param {string} [diagnoseCode] 診断コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiWholeDeathInsurancesRank(order: number, age: number, sex: number, deathBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, diagnoseCode?: string, options?: any): AxiosPromise<ApiWholeDeathInsurancesRankResponse> {
            return localVarFp.getApiWholeDeathInsurancesRank(order, age, sex, deathBenefit, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, cancelRepayment, livingNeeds, diagnoseCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 資料請求（廃止）
         * @summary 資料請求問い合わせAPI（廃止）
         * @param {PostApiContactDocumentRequestRequest} [postApiContactDocumentRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiContactDocumentRequest(postApiContactDocumentRequestRequest?: PostApiContactDocumentRequestRequest, options?: any): AxiosPromise<PostApiContactDocumentRequest200Response> {
            return localVarFp.postApiContactDocumentRequest(postApiContactDocumentRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Eメール問い合わせ
         * @summary Email問い合わせAPI
         * @param {PostApiContactEmailRequest} [postApiContactEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiContactEmail(postApiContactEmailRequest?: PostApiContactEmailRequest, options?: any): AxiosPromise<PostApiContactEmail200Response> {
            return localVarFp.postApiContactEmail(postApiContactEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * FP問い合わせ
         * @summary FP問い合わせAPI
         * @param {PostApiContactFpRequest} [postApiContactFpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiContactFp(postApiContactFpRequest?: PostApiContactFpRequest, options?: any): AxiosPromise<PostApiContactFp200Response> {
            return localVarFp.postApiContactFp(postApiContactFpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * FP問い合わせ後アンケート
         * @summary FP問い合わせAPI
         * @param {PostApiafterContactFpRequest} [PostApiafterContactFpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiAfterContactFp(PostApiafterContactFpRequest?: PostApiAfterContactFpRequest, options?: any): AxiosPromise<PostApiContactFp200Response> {
            return localVarFp.postApiAfterContactFp(PostApiafterContactFpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * SMS問い合わせ
         * @summary SMS問い合わせAPI
         * @param {PostApiContactSmsRequest} [postApiContactSmsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiContactSms(postApiContactSmsRequest?: PostApiContactSmsRequest, options?: any): AxiosPromise<PostApiContactSms200Response> {
            return localVarFp.postApiContactSms(postApiContactSmsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 詳しく診断の回答内容及び、診断結果の保存 診断結果の返却
         * @summary 詳しく診断結果保存API
         * @param {ApiDetailDiagnoseRequest} [apiDetailDiagnoseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDetailDiagnose(apiDetailDiagnoseRequest?: ApiDetailDiagnoseRequest, options?: any): AxiosPromise<PostApiSimpleDiagnose200Response> {
            return localVarFp.postApiDetailDiagnose(apiDetailDiagnoseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * かんたん診断の回答内容及び、診断結果の保存 診断結果の返却 
         * @summary かんたん診断結果保存API
         * @param {ApiSimpleDiagnoseRequest} [apiSimpleDiagnoseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSimpleDiagnose(apiSimpleDiagnoseRequest?: ApiSimpleDiagnoseRequest, options?: any): AxiosPromise<PostApiSimpleDiagnose200Response> {
            return localVarFp.postApiSimpleDiagnose(apiSimpleDiagnoseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 保険商品コードをもとに保険商品を検索。保険情報を返却
     * @summary 保険商品詳細取得API（がん保険）
     * @param {string} insuranceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiCancerInsurancesInsuranceCode(insuranceCode: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiCancerInsurancesInsuranceCode(insuranceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * がん保険料比較ランキング検索API
     * @summary 保険ランキング取得API（がん保険）
     * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
     * @param {number} age 年齢
     * @param {number} sex 性別（1：男,2：女）
     * @param {boolean} paymentExemption 保険料払込免除
     * @param {boolean} underwritingEasing 引受基準緩和型
     * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
     * @param {number} [maxBudget] 予算上限(なしは1）
     * @param {number} [malignantDiagnosticOnce] 診断給付金 （悪性新生物）
     * @param {number} [intraepithelialDiagnosticOnce] 診断給付金 （上皮内新生物）
     * @param {number} [malignantDiagnosticTreatment] 治療給付金 （悪性新生物）
     * @param {number} [intraepithelialDiagnosticTreatment] 治療給付金 （上皮内新生物）
     * @param {number} [hospitalizationDailyAmount] がん入院日額
     * @param {number} [hospitalizationDailyAmountDays] がん入院日数
     * @param {number} [surgery] がん手術
     * @param {number} [leavingHospitalOnce] がん退院一時金
     * @param {number} [cancerHospitalDailyFee] がん通院日額
     * @param {number} [cancerHospitalDailyFeeDays] がん通院日数
     * @param {number} [commutingOnce] 通院一時金
     * @param {boolean} [advancedMedical] がん先進医療
     * @param {number} [radiotherapy] 放射線治療
     * @param {boolean} [anticancerDrugTreatment] 抗がん剤治療
     * @param {boolean} [femaleDiseases] 女性特約
     * @param {string} [diagnoseCode] 診断コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiCancerInsurancesRank(order: number, age: number, sex: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, malignantDiagnosticOnce?: number, intraepithelialDiagnosticOnce?: number, malignantDiagnosticTreatment?: number, intraepithelialDiagnosticTreatment?: number, hospitalizationDailyAmount?: number, hospitalizationDailyAmountDays?: number, surgery?: number, leavingHospitalOnce?: number, cancerHospitalDailyFee?: number, cancerHospitalDailyFeeDays?: number, commutingOnce?: number, advancedMedical?: boolean, radiotherapy?: number, anticancerDrugTreatment?: boolean, femaleDiseases?: boolean, diagnoseCode?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiCancerInsurancesRank(order, age, sex, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, malignantDiagnosticOnce, intraepithelialDiagnosticOnce, malignantDiagnosticTreatment, intraepithelialDiagnosticTreatment, hospitalizationDailyAmount, hospitalizationDailyAmountDays, surgery, leavingHospitalOnce, cancerHospitalDailyFee, cancerHospitalDailyFeeDays, commutingOnce, advancedMedical, radiotherapy, anticancerDrugTreatment, femaleDiseases, diagnoseCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 診断結果取得
     * @summary 診断結果取得API
     * @param {string} diagnosCode 
     * @param {number} version バージョン情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiDiagnoseDiagnoseId(diagnosCode: string, version: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiDiagnoseDiagnoseId(diagnosCode, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保険商品コードをもとに保険商品を検索。保険情報を返却
     * @summary 保険商品詳細取得API（収入保障保険）
     * @param {string} insuranceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiIncomeProtectionInsurancesInsuranceCode(insuranceCode: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiIncomeProtectionInsurancesInsuranceCode(insuranceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 収入保障保険料比較ランキング検索API
     * @summary 保険ランキング取得API（収入保障保険）
     * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
     * @param {number} age 年齢
     * @param {number} sex 性別（1：男,2：女）
     * @param {number} monthlyBenefit 月額保障
     * @param {boolean} paymentExemption 保険料払込免除
     * @param {boolean} underwritingEasing 引受基準緩和型
     * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
     * @param {number} [maxBudget] 予算上限(なしは1）
     * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
     * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
     * @param {number} [paymentMinGuaranteeTerm] 支払い最低保証期間（任意項目、1～5）
     * @param {string} [diagnoseCode] 診断コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiIncomeProtectionInsurancesRank(order: number, age: number, sex: number, monthlyBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, paymentMinGuaranteeTerm?: number, diagnoseCode?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiIncomeProtectionInsurancesRank(order, age, sex, monthlyBenefit, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, cancelRepayment, livingNeeds, paymentMinGuaranteeTerm, diagnoseCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保険会社一覧取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiInsuranceCompanies(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiInsuranceCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保険商品コードをもとに保険商品を検索。保険情報を返却
     * @summary 保険商品詳細取得API（医療保険）
     * @param {string} insuranceCode 保険商品コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiInsurancesInsuranceCode(insuranceCode: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiInsurancesInsuranceCode(insuranceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 医療保険料比較ランキング検索API
     * @summary 保険ランキング取得API（医療保険）
     * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
     * @param {number} age 年齢
     * @param {number} sex 性別（1：男,2：女）
     * @param {number} hospitalizationGuarantee 入院日額
     * @param {number} hospitalizationGuaranteeDays 入院日数
     * @param {boolean} paymentExemption 保険料払込免除
     * @param {boolean} underwritingEasing 引受基準緩和型
     * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
     * @param {number} [maxBudget] 予算上限(なしは1）
     * @param {number} [hospitalizationOnce] 入院一時金
     * @param {boolean} [surgeryGuarantee] 手術保証
     * @param {number} [commutingDailyAmount] 通院日額
     * @param {number} [commutingDailyAmountDays] 通院日数
     * @param {number} [commutingOnce] 通院一時金
     * @param {boolean} [advancedMedical] 先進医療
     * @param {number} [cancerSpecialContractAmount] がん特約金額
     * @param {boolean} [specifiedDisease] 特定疾病
     * @param {number} [deathSpecialContractAmount] 死亡特約
     * @param {boolean} [congratulatoryMoney] 祝い金（健康ボーナス）
     * @param {string} [diagnoseCode] 診断コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiMedicalInsuranceRank(order: number, age: number, sex: number, hospitalizationGuarantee: number, hospitalizationGuaranteeDays: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, hospitalizationOnce?: number, surgeryGuarantee?: boolean, commutingDailyAmount?: number, commutingDailyAmountDays?: number, commutingOnce?: number, advancedMedical?: boolean, cancerSpecialContractAmount?: number, specifiedDisease?: boolean, deathSpecialContractAmount?: number, congratulatoryMoney?: boolean, diagnoseCode?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiMedicalInsuranceRank(order, age, sex, hospitalizationGuarantee, hospitalizationGuaranteeDays, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, hospitalizationOnce, surgeryGuarantee, commutingDailyAmount, commutingDailyAmountDays, commutingOnce, advancedMedical, cancerSpecialContractAmount, specifiedDisease, deathSpecialContractAmount, congratulatoryMoney, diagnoseCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * URLクエリパラメータから取得した、partner_codeをパスパラメータとして設定
     * @summary 提携先情報取得API
     * @param {string} partnerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiPartnersPartnerCode(partnerCode: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiPartnersPartnerCode(partnerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保険商品コードをもとに保険商品を検索。保険情報を返却
     * @summary 保険商品詳細取得API（定期死亡保険）
     * @param {string} insuranceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiTermDeathInsurancesInsuranceCode(insuranceCode: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiTermDeathInsurancesInsuranceCode(insuranceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 死亡保険（終身）料比較ランキング検索API
     * @summary 保険ランキング取得API（定期死亡保険）
     * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
     * @param {number} age 年齢
     * @param {number} sex 性別（1：男,2：女）
     * @param {number} deathBenefit 死亡保障
     * @param {boolean} paymentExemption 保険料払込免除
     * @param {boolean} underwritingEasing 引受基準緩和型
     * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
     * @param {number} [maxBudget] 予算上限(なしは1）
     * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
     * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
     * @param {string} [diagnoseCode] 診断コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiTermDeathInsurancesRank(order: number, age: number, sex: number, deathBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, diagnoseCode?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiTermDeathInsurancesRank(order, age, sex, deathBenefit, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, cancelRepayment, livingNeeds, diagnoseCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保険商品コードをもとに保険商品を検索。保険情報を返却
     * @summary 保険商品詳細取得API（終身死亡保険）
     * @param {string} insuranceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiWholeDeathInsurancesInsuranceCode(insuranceCode: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiWholeDeathInsurancesInsuranceCode(insuranceCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 死亡保険（終身）料比較ランキング検索API
     * @summary 保険ランキング取得API（終身死亡保険）
     * @param {number} order 並び順（1：保険料安い順、2：保険料高い順）
     * @param {number} age 年齢
     * @param {number} sex 性別（1：男,2：女）
     * @param {number} deathBenefit 死亡保障
     * @param {boolean} paymentExemption 保険料払込免除
     * @param {boolean} underwritingEasing 引受基準緩和型
     * @param {boolean} intentionRecommendInsurance 推奨保険会社のみ
     * @param {number} [maxBudget] 予算上限(なしは1）
     * @param {boolean} [cancelRepayment] 解約返戻金（任意項目）
     * @param {boolean} [livingNeeds] リビングニーズ特約（任意項目）
     * @param {string} [diagnoseCode] 診断コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiWholeDeathInsurancesRank(order: number, age: number, sex: number, deathBenefit: number, paymentExemption: boolean, underwritingEasing: boolean, intentionRecommendInsurance: boolean, maxBudget?: number, cancelRepayment?: boolean, livingNeeds?: boolean, diagnoseCode?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiWholeDeathInsurancesRank(order, age, sex, deathBenefit, paymentExemption, underwritingEasing, intentionRecommendInsurance, maxBudget, cancelRepayment, livingNeeds, diagnoseCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資料請求（廃止）
     * @summary 資料請求問い合わせAPI（廃止）
     * @param {PostApiContactDocumentRequestRequest} [postApiContactDocumentRequestRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiContactDocumentRequest(postApiContactDocumentRequestRequest?: PostApiContactDocumentRequestRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiContactDocumentRequest(postApiContactDocumentRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Eメール問い合わせ
     * @summary Email問い合わせAPI
     * @param {PostApiContactEmailRequest} [postApiContactEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiContactEmail(postApiContactEmailRequest?: PostApiContactEmailRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiContactEmail(postApiContactEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * FP問い合わせ
     * @summary FP問い合わせAPI
     * @param {PostApiContactFpRequest} [postApiContactFpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiContactFp(postApiContactFpRequest?: PostApiContactFpRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiContactFp(postApiContactFpRequest, options).then((request) => request(this.axios, this.basePath));
    }

        /**
     * FP問い合わせ後アンケート
     * @summary FP問い合わせAPI
     * @param {PostApiAfterContactFpRequest} [PostApiAfterContactFpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
        public postAfterApiContactFp(PostApiAfterContactFpRequest?: PostApiAfterContactFpRequest, options?: AxiosRequestConfig) {
            return DefaultApiFp(this.configuration).postApiAfterContactFp(PostApiAfterContactFpRequest, options).then((request) => request(this.axios, this.basePath));
        }

    /**
     * SMS問い合わせ
     * @summary SMS問い合わせAPI
     * @param {PostApiContactSmsRequest} [postApiContactSmsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiContactSms(postApiContactSmsRequest?: PostApiContactSmsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiContactSms(postApiContactSmsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 詳しく診断の回答内容及び、診断結果の保存 診断結果の返却
     * @summary 詳しく診断結果保存API
     * @param {ApiDetailDiagnoseRequest} [apiDetailDiagnoseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiDetailDiagnose(apiDetailDiagnoseRequest?: ApiDetailDiagnoseRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiDetailDiagnose(apiDetailDiagnoseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * かんたん診断の回答内容及び、診断結果の保存 診断結果の返却 
     * @summary かんたん診断結果保存API
     * @param {ApiSimpleDiagnoseRequest} [apiSimpleDiagnoseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiSimpleDiagnose(apiSimpleDiagnoseRequest?: ApiSimpleDiagnoseRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiSimpleDiagnose(apiSimpleDiagnoseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



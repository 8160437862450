/**  @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { ApiCancerInsurancesRankResponseInsurancesInner } from "../../../../openApi/api";
import { ContentsColumnCommon } from "./ContentsColumnCommon";
import {
  CANCER_COMMUTING_DAILY_AMOUNT_OPTIONS,
  CANCER_HOSPITALIZATION_GUARANTEE_DAYS_OPTIONS,
  CANCER_HOSPITALIZATION_GUARANTEE_OPTIONS,
  DIAGNOSTIC_ONCE_OPTIONS,
  DIAGNOSTIC_TREATMENT_OPTIONS,
  RADIOTHERAPY_OPTIONS,
  LEAVING_HOSPITAL_ONCE_OPTIONS,
  SURGERY_OPTIONS
} from "./DropdownItem";
import { formatForDisplayAmountText, formatForDisplayDaysText, formatTextWithLineBreaks, formatForDisplaySubText } from "./utils";

interface Props {
  typeOfInsurance: number;
  insurance: ApiCancerInsurancesRankResponseInsurancesInner;
  lowPriceRank: number;
  age: number;
  sex: number;
  malignantDiagnosticOnce: number;
  setIsPopupOpen: (isPopupOpen: boolean) => void;
  setFocusedIndex: (focusedIndex: number) => void;
  insuranceListInCart: ApiCancerInsurancesRankResponseInsurancesInner[];
  setInsuranceListInCart: (
    prevCart: ApiCancerInsurancesRankResponseInsurancesInner[]
  ) => void;
  insuranceIdListInCart: string[];
  setInsuranceIdListInCart: (insuranceIdListInCart: string[]) => void;
  intraepithelialDiagnosticOnce: number;
  malignantDiagnosticTreatment: number;
  intraepithelialDiagnosticTreatment: number;
  hospitalizationDailyAmount: number;
  hospitalizationDailyAmountDays: number;
  surgery: number;
  leavingHospitalOnce: number;
  cancerHospitalDailyFee: number;
  cancerHospitalDailyFeeDays: number;
  commutingOnce: number;
  advancedMedical: boolean;
  radiotherapy: number;
  anticancerDrugTreatment: boolean;
  femaleDiseases: boolean;
  paymentExemption: boolean;
  checkedRankList: string[];
  setCheckedRankList: (checkedRankList: string[]) => void;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
  numberOfInsurances: number | undefined;
}

export const ContentsColumnCancer = ({
  typeOfInsurance,
  insurance,
  lowPriceRank,
  age,
  sex,
  malignantDiagnosticOnce,
  setIsPopupOpen,
  setFocusedIndex,
  insuranceListInCart,
  setInsuranceListInCart,
  insuranceIdListInCart,
  setInsuranceIdListInCart,
  intraepithelialDiagnosticOnce,
  malignantDiagnosticTreatment,
  intraepithelialDiagnosticTreatment,
  hospitalizationDailyAmount,
  hospitalizationDailyAmountDays,
  surgery,
  leavingHospitalOnce,
  cancerHospitalDailyFee,
  cancerHospitalDailyFeeDays,
  commutingOnce,
  advancedMedical,
  radiotherapy,
  anticancerDrugTreatment,
  femaleDiseases,
  paymentExemption,

  checkedRankList,
  setCheckedRankList,
  setIsContentsColumnRendered,
  numberOfInsurances,
}: Props) => {
  useEffect(() => {
    if (lowPriceRank === numberOfInsurances) setIsContentsColumnRendered(true);
  }, [lowPriceRank, setIsContentsColumnRendered, numberOfInsurances]);
  return (
    <div className="plan">
      <ContentsColumnCommon
        typeOfInsurance={typeOfInsurance}
        insurance={insurance}
        lowPriceRank={lowPriceRank}
        age={age}
        sex={sex}
        guaranteeAmount={malignantDiagnosticOnce}
        setIsPopupOpen={setIsPopupOpen}
        setFocusedIndex={setFocusedIndex}
        insuranceListInCart={insuranceListInCart}
        setInsuranceListInCart={setInsuranceListInCart}
        insuranceIdListInCart={insuranceIdListInCart}
        setInsuranceIdListInCart={setInsuranceIdListInCart}
        intraepithelialDiagnosticOnce={intraepithelialDiagnosticOnce}
        malignantDiagnosticTreatment={malignantDiagnosticTreatment}
        intraepithelialDiagnosticTreatment={intraepithelialDiagnosticTreatment}
        hospitalizationDailyAmount={hospitalizationDailyAmount}
        hospitalizationDailyAmountDays={hospitalizationDailyAmountDays}
        surgery={surgery}
        leavingHospitalOnce={leavingHospitalOnce}
        cancerHospitalDailyFee={cancerHospitalDailyFee}
        cancerHospitalDailyFeeDays={cancerHospitalDailyFeeDays}
        commutingOnce={commutingOnce}
        advancedMedical={advancedMedical}
        radiotherapy={radiotherapy}
        anticancerDrugTreatment={anticancerDrugTreatment}
        femaleDiseases={femaleDiseases}
        paymentExemption={paymentExemption}
        checkedRankList={checkedRankList}
        setCheckedRankList={setCheckedRankList}
      />
      <div className="malignantDiagnosticOnce listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.malignant_diagnostic_once,
            DIAGNOSTIC_ONCE_OPTIONS,
            malignantDiagnosticOnce
          )}
        </span>
      </div>
      <div className="intraepithelialDiagnosticOnce listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.intraepithelial_diagnostic_once,
            DIAGNOSTIC_ONCE_OPTIONS,
            intraepithelialDiagnosticOnce
          )}
        </span>
      </div>
      <div className="malignantDiagnosticTreatment listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.malignant_diagnostic_treatment,
            DIAGNOSTIC_TREATMENT_OPTIONS,
            malignantDiagnosticTreatment
          )}
        </span>
      </div>
      <div className="intraepithelialDiagnosticTreatment listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.intraepithelial_diagnostic_treatment,
            DIAGNOSTIC_TREATMENT_OPTIONS,
            intraepithelialDiagnosticTreatment
          )}
        </span>
      </div>
      <div className="hospitalizationDailyAmount listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.hospitalization_daily_amount,
            CANCER_HOSPITALIZATION_GUARANTEE_OPTIONS,
            hospitalizationDailyAmount
          )}
        </span>
      </div>

      <div className="hospitalizationDailyAmountDays listBody mh100">
        <span>
          {formatForDisplayDaysText(
            insurance.hospitalization_daily_amount,
            CANCER_HOSPITALIZATION_GUARANTEE_DAYS_OPTIONS,
            hospitalizationDailyAmountDays
          )}
        </span>
      </div>
      <div className="surgery listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.surgery,
            SURGERY_OPTIONS,
            surgery
          )}
        </span>
      </div>
      <div className="leavingHospitalOnce listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.leaving_hospital_once,
            LEAVING_HOSPITAL_ONCE_OPTIONS,
            leavingHospitalOnce
          )}
        </span>
      </div>
      <div className="cancerHospitalDailyFee listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.cancer_hospital_daily_fee,
            CANCER_COMMUTING_DAILY_AMOUNT_OPTIONS,
            cancerHospitalDailyFee
          )}
        </span>
      </div>
      <div className="advancedMedical listBody mh100">
        <span>{formatForDisplaySubText(insurance.advanced_medical)}</span>
      </div>
      <div className="radiotherapy listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.radiotherapy,
            RADIOTHERAPY_OPTIONS,
            radiotherapy
          )}
        </span>
      </div>
      <div className="anticancerDrugTreatment listBody mh100">
        <span>{formatForDisplaySubText(insurance.anticancer_drug_treatment)}</span>
      </div>
      <div className="femaleDiseases listBody mh100">
        <span>{formatForDisplaySubText(insurance.female_diseases)}</span>
      </div>
      <div className="paymentExemption listBody mh100">
        <span>{insurance.is_insurance_payment_exemption ? "あり" : "なし"}</span>
      </div>
      <div className="attention listBody mh50 miniText">{formatTextWithLineBreaks(insurance.attention_text)}</div>
      <div className="condition listBody mh50 miniText">{formatTextWithLineBreaks(insurance.condition_wording)}</div>
      <div className="approvalNumber listBody mh100 approvalText">{insurance.approval_number}</div>
    </div>
  );
};

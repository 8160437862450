/**  @jsxImportSource @emotion/react */
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as txt from "../../../../components/atoms/Typografy";
import { Insurance } from "../../../../openApi/api";
import {
  cancerPDStorage,
  cartStorage,
  commonPDStorage,
  deathPDStorage,
  focusedInsuranceStorage,
  incomeProtectionPDStorage,
  medicalPDStorage,
  searchConditionStorage,
} from "../../../../utils/storage";
import { jpNumFormatter } from "../../../../utils/formatter";
import SalesPolicy from "../../../../components/molecules/SalesPolicy";

export interface CommonPDProps {
  paymentExemption: boolean;
}

interface Props {
  typeOfInsurance: number;
  insurance: Insurance;
  lowPriceRank: number;
  age: number;
  sex: number;
  guaranteeAmount: number;
  paymentExemption: boolean;
  setIsPopupOpen: (isPopupOpen: boolean) => void;
  setFocusedIndex: (focusedIndex: number) => void;
  insuranceListInCart: Insurance[];
  setInsuranceListInCart: (prevCart: Insurance[]) => void;
  insuranceIdListInCart: string[];
  setInsuranceIdListInCart: (insuranceIdListInCart: string[]) => void;
  isMobile?: boolean;

  //医療保険
  hospitalizationGuaranteeDays?: number;
  hospitalizationOnce?: number;
  surgeryGuarantee?: boolean;
  commutingDailyAmount?: number;
  commutingDailyAmountDays?: number;
  commutingOnce?: number;
  advancedMedical?: boolean;
  cancerSpecialContractAmount?: number;
  specifiedDisease?: boolean;
  deathSpecialContractAmount?: number;
  congratulatoryMoney?: boolean;

  //がん保険
  intraepithelialDiagnosticOnce?: number;
  malignantDiagnosticTreatment?: number;
  intraepithelialDiagnosticTreatment?: number;
  hospitalizationDailyAmount?: number;
  hospitalizationDailyAmountDays?: number;
  surgery?: number;
  leavingHospitalOnce?: number;
  cancerHospitalDailyFee?: number;
  cancerHospitalDailyFeeDays?: number;
  // commutingOnce?: number;
  // advancedMedical?: boolean;
  radiotherapy?: number;
  anticancerDrugTreatment?: boolean;
  femaleDiseases?: boolean;

  //死亡保険
  cancelRepayment?: boolean;
  livingNeeds?: boolean;

  //収入保障保険
  paymentMinGuaranteeTerm?: number;
  
  checkedRankList: string[];
  setCheckedRankList: (checkedRankList: string[]) => void;
}

export const ContentsColumnCommon = ({
  typeOfInsurance,
  insurance,
  lowPriceRank,
  age,
  sex,
  guaranteeAmount,
  setIsPopupOpen,
  setFocusedIndex,
  insuranceListInCart,
  setInsuranceListInCart,
  insuranceIdListInCart,
  setInsuranceIdListInCart,
  isMobile,
  paymentExemption,
  hospitalizationGuaranteeDays,
  hospitalizationOnce,
  surgeryGuarantee,
  commutingDailyAmount,
  commutingDailyAmountDays,
  commutingOnce,
  advancedMedical,
  cancerSpecialContractAmount,
  specifiedDisease,
  deathSpecialContractAmount,
  congratulatoryMoney,
  intraepithelialDiagnosticOnce,
  malignantDiagnosticTreatment,
  intraepithelialDiagnosticTreatment,
  hospitalizationDailyAmount,
  hospitalizationDailyAmountDays,
  surgery,
  leavingHospitalOnce,
  radiotherapy,
  anticancerDrugTreatment,
  femaleDiseases,
  cancelRepayment,
  livingNeeds,
  checkedRankList,
  setCheckedRankList,
  paymentMinGuaranteeTerm,
}: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const setParamsToStorage = () => {
    commonPDStorage.set({ paymentExemption });
    focusedInsuranceStorage.remove();
    focusedInsuranceStorage.set(insurance);
    switch (typeOfInsurance) {
      case 1:
        medicalPDStorage.set({
          totalInsuranceFee: insurance.total_insurance_fee,
          age,
          sex,
          hospitalizationGuarantee: insurance.hospitalization_guarantee,
          hospitalizationGuaranteeDays,
          hospitalizationOnce,
          surgeryGuarantee,
          commutingDailyAmount,
          commutingDailyAmountDays,
          commutingOnce,
          advancedMedical,
          cancerSpecialContractAmount,
          specifiedDisease,
          deathSpecialContractAmount,
          congratulatoryMoney,
          memo1: insurance.memo1,
          memo2: insurance.memo2,
          memo3: insurance.memo3,
          fee_memos: insurance.fee_memos,
          product_name: insurance.insurance_name,
          insurance_fee_codes: insurance.insurance_fee_codes
        });
        break;
      case 2:
        cancerPDStorage.set({
          totalInsuranceFee: insurance.total_insurance_fee,
          age,
          sex,
          malignantDiagnosticOnce: guaranteeAmount,
          intraepithelialDiagnosticOnce,
          malignantDiagnosticTreatment,
          intraepithelialDiagnosticTreatment,
          hospitalizationDailyAmount,
          hospitalizationDailyAmountDays,
          surgery,
          leavingHospitalOnce,
          commutingDailyAmount,
          commutingDailyAmountDays,
          commutingOnce,
          advancedMedical,
          radiotherapy,
          anticancerDrugTreatment,
          femaleDiseases,
          memo1: insurance.memo1,
          memo2: insurance.memo2,
          memo3: insurance.memo3,
          fee_memos: insurance.fee_memos,
          product_name: insurance.insurance_name,
          insurance_fee_codes: insurance.insurance_fee_codes
        });
        break;
      case 3:
        deathPDStorage.set({
          totalInsuranceFee: insurance.total_insurance_fee,
          age,
          sex,
          deathBenefit: guaranteeAmount,
          cancelRepayment,
          livingNeeds,
          memo1: insurance.memo1,
          memo2: insurance.memo2,
          memo3: insurance.memo3,
          fee_memos: insurance.fee_memos,
          product_name: insurance.insurance_name,
          insurance_fee_codes: insurance.insurance_fee_codes
        });
        break;
      case 4:
        deathPDStorage.set({
          totalInsuranceFee: insurance.total_insurance_fee,
          age,
          sex,
          deathBenefit: guaranteeAmount,
          cancelRepayment,
          livingNeeds,
          memo1: insurance.memo1,
          memo2: insurance.memo2,
          memo3: insurance.memo3,
          fee_memos: insurance.fee_memos,
          product_name: insurance.insurance_name,
          insurance_fee_codes: insurance.insurance_fee_codes
        });
        break;
      case 5:
        incomeProtectionPDStorage.set({
          totalInsuranceFee: insurance.total_insurance_fee,
          age,
          sex,
          monthlyBenefit: guaranteeAmount,
          paymentMinGuaranteeTerm,
          cancelRepayment,
          livingNeeds,
          memo1: insurance.memo1,
          memo2: insurance.memo2,
          memo3: insurance.memo3,
          fee_memos: insurance.fee_memos,
          product_name: insurance.insurance_name,
          insurance_fee_codes: insurance.insurance_fee_codes
        });
        break;
      default:
        break;
    }
  };
  const handleClickAddButton = () => {
    setFocusedIndex(lowPriceRank - 1);
    setIsPopupOpen(true);
    
    insurance.insurance_fee = insurance?.total_insurance_fee;
    insurance.insurance_code = insurance?.insurance_id;
    insurance.insurance_category = insurance.insurance_id?.includes('medical') ? '医療保険' : insurance.insurance_id?.includes('cancer') ? 'がん保険' : insurance.insurance_id?.includes('lifetime') ? '死亡保険（終身）' : insurance.insurance_id?.includes('regular') ? '死亡保険（定期）' : insurance.insurance_id?.includes('income') ? '収入保障保険' : 'その他保険';
    
    if (insurance.insurance_id?.includes('medical')){
      insurance.insurance_detail_link = '/medical_insurances/';
    } else if (insurance.insurance_id?.includes('cancer')){
      insurance.insurance_detail_link = '/cancer_insurances/';
    } else if (insurance.insurance_id?.includes('lifetime')){
      insurance.insurance_detail_link = '/whole_death_insurances/';
    } else if (insurance.insurance_id?.includes('regular')){
      insurance.insurance_detail_link = '/term_death_insurances/';
    } else if (insurance.insurance_id?.includes('income')){
      insurance.insurance_detail_link = '/income_protection_insurances/';
    }
    
    if (insurance.insurance_detail_link){
      insurance.insurance_detail_link += insurance?.insurance_id + '?insurance_fee=' + insurance?.total_insurance_fee + '&age=' + age + '&sex=' + sex;
    }
    
    cartStorage.set({
      insuranceIdList: [
        ...(insuranceIdListInCart ? insuranceIdListInCart : []),
        insurance?.insurance_id || "",
      ],
      insuranceList: [...insuranceListInCart, insurance],
    });
    
    const updatedIdListInCart = cartStorage.get().insuranceIdList;
    setInsuranceIdListInCart(updatedIdListInCart);
    const updatedListInCart = cartStorage.get().insuranceList;
    setInsuranceListInCart(updatedListInCart);
  };

  //保険商品のご案内にあたって
  const searchCondition = searchConditionStorage.get();
  const [isSalesPolicyOpen, setIsSalesPolicyOpen] = useState<boolean>(false);
  
  const setSearchConditionNoticeCheckStorage = () => {
    searchConditionStorage.set({
      ...searchConditionStorage.get(),
      isOpenNotes: 1
    });
  };

  return (
    <div className="columnCommon">
      <div className="radioBtnContainer radioButton">
        <Form.Check
          type="checkbox"
          onChange={() => {
            setIsChecked(!isChecked);
            
            if (setCheckedRankList && checkedRankList && insurance.insurance_id){
              (!isChecked) ? setCheckedRankList([...checkedRankList, insurance.insurance_id]) : setCheckedRankList(checkedRankList.filter((item) => item !== insurance.insurance_id));
            }
          }}
          checked={(!insurance.insurance_id) ? false: checkedRankList.includes(insurance.insurance_id) ? true: false}
        ></Form.Check>
      </div>
      <div className="lowPriceRankCon listBody h50">
        <span css={[txt.typographyBlock]}>{lowPriceRank}位</span>
      </div>
      <div
        className={`companyNameCon companyNameAndFeeCon listBody ${
          isMobile ? "" : "h102"
        }`}
      >
        <div className="companyName">{insurance.company_name}</div>
        <Link
          to={(() => {
            switch (typeOfInsurance) {
              case 1:
                return `/medical_insurances/${insurance.insurance_id}?fromComparison=true`;
              case 2:
                return `/cancer_insurances/${insurance.insurance_id}?fromComparison=true`;
              case 3:
                return `/whole_death_insurances/${insurance.insurance_id}?fromComparison=true`;
              case 4:
                return `/term_death_insurances/${insurance.insurance_id}?fromComparison=true`;
              case 5:
                return `/income_protection_insurances/${insurance.insurance_id}?fromComparison=true`;
              default:
                return "/";
            }
          })()}
          onClick={setParamsToStorage}
        >
          <img
            src={insurance.thumbnail_url}
            alt={insurance.insurance_name}
            width={118}
            height={46}
          />
        </Link>
        {isMobile && (
          <div className="fee">¥{insurance.total_insurance_fee}</div>
        )}
      </div>
      {!isMobile && (
        <div className="feeCon listBody h50">
          <span>
            ¥
            {insurance.total_insurance_fee &&
              jpNumFormatter(insurance.total_insurance_fee)}
          </span>
        </div>
      )}
      <div className="entryCon listBody h75">
        {insurance.is_online_entry && insurance.entry_url
        ? (
          <>
            {searchCondition?.isOpenNotes === 1 ? (
              <Button
                href={insurance.entry_url}
                target="_blank"
                variant="orange"
                className="netBtn"
              >
                {insurance.is_online_entry
                  ? "ネット申し込み"
                  : "資料請求リンク"}
              </Button>
            ): (
              <Button
                onClick={() => {
                  setIsSalesPolicyOpen(true);
                  setSearchConditionNoticeCheckStorage();
                }}
                variant="orange"
                className="netBtn"
              >
                {insurance.is_online_entry
                  ? "ネット申し込み"
                  : "資料請求リンク"}
              </Button>
            )}
          </>
        ) : ''}
      </div>
      <div className={`saveCon listBody ${isMobile ? "h75" : "h60"}`}>
        {insuranceIdListInCart.includes(insurance.insurance_id ?? "") ? (
          <></>
        ) : (
          <>
            {isMobile && <div className="saveText">Myプランに保存</div>}
            <div className="addButton" onClick={handleClickAddButton}>
              ＋
            </div>
          </>
        )}
      </div>
      {isSalesPolicyOpen && (
        <div className="popUpOpen">
          <div className="wrap">
            <div className="mainCont mainContPlusButton">
              <SalesPolicy
                hasLinkButton={true}
                linkTitle={insurance.is_online_entry ? "ネット申し込み" : "資料請求リンク"}
                linkUrl={insurance.entry_url ?? ''}
                setIsSalesPolicyOpen={setIsSalesPolicyOpen}
              />
            </div>
            <Button
              className="closePopup"
              onClick={() => {
                setIsSalesPolicyOpen(false);
              }}
            >
              閉じる
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
